import { CalendarService } from "./../../plus/calendar/calendar.service";
import { PrintService } from "./../../../layout/vertical/layout-1/print.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import * as moment from "moment";

@Component({
    selector: "app-planning-popup",
    templateUrl: "./planning-popup.component.html",
    styleUrls: ["./planning-popup.component.scss"],
})
export class PlanningPopupComponent implements OnInit {
    error = false;
    today = moment();
    period = {
        begin: this.today.day(0).startOf("day").toDate(),
        end: this.today.day(6).startOf("day").toDate(),
    };
    type = {
        types: [
            {
                name: "seance code",
                value: "codeLess",
            },
            {
                name: "seance conduite",
                value: "conduiteLess",
            },
            {
                name: "examen code",
                value: "codeEx",
            },
            {
                name: "examen conduite",
                value: "conduiteEx",
            },
        ],
        selected: [],
    };

    moniteur = {
        moniteurs: [],
        selected: undefined,
    };

    candidat = {
        candidats: [],
        selected: undefined,
    };
    constructor(
        public dialogRef: MatDialogRef<PlanningPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _printService: PrintService,
        private _calService: CalendarService
    ) {
    }

    ngOnInit() {
        this.moniteur.moniteurs = this.data.moniteurs;
        this.candidat.candidats = this.data.candidats;
    }

    onNoClick() {
        this.dialogRef.close();
    }
    onCancel() {
        this.dialogRef.close();
    }

    onYesClick() {
        this.error = false;
        this._calService
            .getFiltredEvent(
                this.period,
                this.candidat.selected ? this.candidat.selected.id : "",
                this.type.selected.length !== 0 ? this.type.selected : "",
                this.moniteur.selected ? this.moniteur.selected.id : ""
            )
            .subscribe((data) => {
                this._printService.printPlanning({
                    events: data,
                    types: this.type.selected,
                    period: this.period,
                    cand: this.candidat.selected
                        ? this.candidat.selected.prenom +
                          " " +
                          this.candidat.selected.nom
                        : "",
                    mon: this.moniteur.selected
                        ? this.moniteur.selected.username
                        : "",
                });
                this.dialogRef.close();
            });
    }
}
