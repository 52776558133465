<div fxLayout="column" class="w-100-p">

    <div fxLayoutAlign="center start" class="pt-16">

        <img src="assets/images/logos/Group.png" />

        <span ngClass="logoTitle  pt21 pl10">auto</span>
        <span ngClass="logoTitle  pt21">ecole</span>
        <span ngClass="logoTitle  pt21">plus</span>
        <span ngClass="logoTitle  pt21">.tn</span>
    </div>
    <div id="error-404" fxLayout="column" fxLayoutAlign="center center" class="pt-100">
        <div class="content" fxLayout="column" fxLayoutAlign="center center">
            <div class="error-code"><img style="width:35%" src="assets/images/avatars/nowifi.png"></div>

            <div class="message">Pas de connection internet</div>
            <span class="back-link" (click)="recharge()"  style="cursor: pointer;color:#039be5">Recharger la page</span>
        </div>
    </div>
</div>