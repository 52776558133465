import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventChoiceComponent } from "./event-choice.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [MatDialogModule, MatButtonModule, CommonModule,MatMenuModule,MatIconModule],
    declarations: [EventChoiceComponent],
})
export class EventChoiceModule {}
