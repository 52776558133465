import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-video",
    templateUrl: "./video.component.html",
    styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit {
    url: any;
    title;
    constructor(
        public _matDialogRef: MatDialogRef<VideoComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
            this._data.url
        );
        this.title = "title vedio"
    }

    close() {
        this._matDialogRef.close();
    }
}
