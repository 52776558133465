import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[decimalPlaces]'
})
export class DecimalPlacesDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/);
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ];

  firstRun = true

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
    // Allow certain special keys
    if (this.specialKeys.includes(event.key)) {
      return;
    }

    // Check if the entered value is a valid number
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('focusout')
  onFocusOut() {
    let current: string = this.el.nativeElement.value;

    // Check if the input value has decimal places
    if (current.includes('.')) {
      // Round the value to three decimal places
      let rounded = parseFloat(current).toFixed(3);

      // Update the input value
      this.el.nativeElement.value = rounded;
    }
  }
  ngAfterViewInit(){
    if (this.firstRun && this.el.nativeElement.value) {
      const arr = this.el.nativeElement.value.split('.');
      if(!arr[1]) return
        let x = arr[0] + '.' + (arr[1]+'000').slice(0,3);
        this.el.nativeElement.value = x;//this.numberMaskPipe.transform(this.initialValue);
        this.firstRun = false;
    }
  } 
}


/* @Input() decimalPlaces:string

// Allow decimal numbers and negative values
private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
// Allow key codes for special events. Reflect :
// Backspace, tab, end, home
private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
private firstRun = true;


constructor(private el: ElementRef) {
 if(el.nativeElement.value.includes('.')) {
   const arr = el.nativeElement.value.split('.');
   let x = arr[0] + '.' + (arr[1]+'000').slice(0,3);
  }// .includes('.')
}
@HostListener('keydown', ['$event'])
onKeyDown(event: KeyboardEvent) {
  // Allow Backspace, tab, end, and home keys
  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  let current: string = this.el.nativeElement.value;
  const position = this.el.nativeElement.selectionStart;
  const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
  if (next && !String(next).match(this.regex)) {
    event.preventDefault();
  }
}

ngOnInit(){
  console.log('params :',this.decimalPlaces)
}

ngAfterViewInit(){
  if (this.firstRun && this.el.nativeElement.value) {
    const arr = this.el.nativeElement.value.split('.');
    if(!arr[1]) return
      let x = arr[0] + '.' + (arr[1]+'000').slice(0,3);
      this.el.nativeElement.value = x;//this.numberMaskPipe.transform(this.initialValue);
      this.firstRun = false;
  }
} 


}*/
