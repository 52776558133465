import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})


export class NotificationService {
  uri = environment.uriG
  
  constructor(private http: HttpClient) {}

  //* Send notification preferences to the server to be stocked in the database
  sendPreferences(data: any): Observable<any> {
    data.notification_preference =  this.reverseTransformNotificationPreferences(data.notification_preference)
    return this.http.put(`${this.uri}/api/notifications/notification-preferences`,data);
  }

  //* Get notification preferences from database
  getPreferences(): Observable<any> {
    return this.http.get<any>(`${this.uri}/api/notifications/notification-preferences`);
  }

  // Transform the data to adaptable format for the database.
  reverseTransformNotificationPreferences(structuredPreferences: any): any {
    const result = [];

    const categoryMapReverse: { [key: string]: string } = {
      'Événement': 'event',
      'Dépense': 'depense',
      'Paiement': 'paiement',
      'Question': 'follow-up',
      'Reservation': 'reservation',
      'Listes des Examen': 'exam_list',
    };

    const childMapReverse: { [key: string]: string } = {
      'Ajout': 'event',
      'Modification': 'edit',
      'Suppression': 'delete',
      'Confirmation': 'confirm',
      'Suppression du résultat': 'deleteResult',
      "Résultat d'examen": 'examen',
      'Nouvelle liste': 'add',
      'Message': 'message',
      'Acceptation': 'accept',
      'Refuse': 'decline',
    };
  
    for (const preference in structuredPreferences) {
  
      if (structuredPreferences[preference]?.["Child"]){
  
        for (const action in structuredPreferences[preference]["Child"]) {
          
          result.push({
            category: null,
            contentTable: categoryMapReverse[preference] || preference,
            action: childMapReverse[action] || action,
            inapp_on: structuredPreferences[preference]["Child"][action].InApp,
            push_on: structuredPreferences[preference]["Child"][action].Push
          })
        }
      }
    }
  
    return result;
  }

  renameFields(data: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(data).reduce((acc, [category, value]) => {
      const renamedCategory = this.renameCategory(category);
      acc[renamedCategory] = {
        InApp: value.InApp,
        Push: value.Push,
        Child: value.Child
          ? Object.entries(value.Child).reduce((childAcc, [child, childValue]) => {
              childAcc[this.renameChild(child)] = childValue;
              return childAcc;
            }, {})
          : {},
      };
      return acc;
    }, {});
  }

  private categoryMap: { [key: string]: string } = {
    event: 'Événement',
    depense: 'Dépense',
    paiement: 'Paiement',
    'follow-up': 'Question',
    reservation: 'Reservation',
    exam_list: 'Listes des Examen',
  };

  private childMap: { [key: string]: string } = {
    event: 'Ajout',
    edit: 'Modification',
    delete: 'Suppression',
    confirm: 'Confirmation',
    deleteResult: 'Suppression du résultat',
    examen: "Résultat d'examen",
    add: 'Nouvelle liste',
    message: 'Message',
    accept: 'Acceptation',
    decline: 'Refuse',
  };

  private renameCategory(category: string): string {
    return this.categoryMap[category] || category;
  }

  private renameChild(child: string): string {
    return this.childMap[child] || child;
  }
  
}

