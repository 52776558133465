import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from './notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccesSnakBarComponent } from 'app/main/components/succes-snak-bar/succes-snak-bar.component';

// Defines the structure for notification preferences
interface NotificationPreference {
  InApp: boolean;
  Push: boolean;
  Child?: { [key: string]: NotificationPreference };
}

@Component({
  selector: 'app-notification-settings-dialog',
  templateUrl: './notification-settings-dialog.component.html',
  styleUrls: ['./notification-settings-dialog.component.scss']
})
export class NotificationSettingsDialogComponent implements OnInit {
  preferenceForm: FormGroup;
  notificationSettingsForm : FormGroup;
  notificationPreferenceData: { [key: string]: NotificationPreference }; // Main data object for all notification preferences
  gestionNotificationPreferenceData: { [key: string]: NotificationPreference }; 
  candidateNotificationPreferenceData: { [key: string]: NotificationPreference }; 
  examsNotificationPreferenceData: { [key: string]: NotificationPreference }; 
  expandedCategories: Set<string> = new Set();
  indeterminateStates: { [key: string]: boolean } = {};
  preferenceData: any;
  isFormReady = false;
  loading = false;
  notificationSettingsInitData : any[];
  notificationPreferenceInitData : any[];
  settingPushIsOff = false;
  settingsIsOn = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationSettingsDialogComponent>,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    
  ) {

    // Initialize notification preference data structures
    this.examsNotificationPreferenceData = {
      "Listes des Examen": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Nouvelle liste": { "InApp": false, "Push": false },
        }
      },
    }
    this.gestionNotificationPreferenceData = {
      "Événement": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
          "Confirmation": { "InApp": false, "Push": false },
          "Résultat d'examen": { "InApp": false, "Push": false },
          "Suppression du résultat": { "InApp": false, "Push": false },
        }
      },
      "Paiement": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
        }
      },
      "Dépense": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
        }
      },
    };

    this.candidateNotificationPreferenceData = {
      "Question": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Message": {
            "InApp": false,
            "Push": false
          }
        }
      },
      "Reservation": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Acceptation": { "InApp": false, "Push": false },
          "Refuse": { "InApp": false, "Push": false },
        }
      },
    };
    // Combine all preference data into one object
    this.notificationPreferenceData = {
      ...this.gestionNotificationPreferenceData,
      ...this.candidateNotificationPreferenceData,
      ...this.examsNotificationPreferenceData
    };
  }

  ngOnInit(): void {
    this.loadPreferences();
  }

  // Initialize the form structure based on notification preference data
  initForm() {

    const group: any = {};
    // this.indeterminateStates
    Object.keys(this.notificationPreferenceData).forEach(category => {
      group[`${category}_main`] = [false];
      group[`${category}_inApp`] = [false];
      group[`${category}_push`] = [false];
      this.indeterminateStates[`${category}_main`] = false;
      this.indeterminateStates[`${category}_inApp`] = false;
      this.indeterminateStates[`${category}_push`] = false;

      if (this.notificationPreferenceData[category].Child) {
        Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
          group[`${category}_${child}_main`] = [false];
          group[`${category}_${child}_inApp`] = [false];
          group[`${category}_${child}_push`] = [false];
          this.indeterminateStates[`${category}_${child}_main`] = false;
        });
      }
    });

    this.preferenceForm = this.fb.group(group);
        // Initialize the notification settings
      console.log('this.notificationSettingsInitData[0].push_on' , this.notificationSettingsInitData[0].push_on)
      console.log('this.notificationSettingsInitData[0].inapp_on' , this.notificationSettingsInitData[0].inapp_on)
      this.notificationSettingsForm = this.fb.group({
        push: [this.notificationSettingsInitData[0].push_on],
        in_app: [this.notificationSettingsInitData[0].inapp_on],
      })

        // Listen to 'push' checkbox value changes
    this.notificationSettingsForm.get('push')?.valueChanges.subscribe((pushValue: boolean) => {
      this.loading = true;
      if (pushValue) {
        // If 'push' is true, set 'in_app' to true automatically
        this.notificationSettingsForm.patchValue({ in_app: true }, { emitEvent: false });
      }
      this.generalSettingsChangesHandling()
    });

    // Listen to 'in_app' checkbox value changes
    this.notificationSettingsForm.get('in_app')?.valueChanges.subscribe((inAppValue: boolean) => {
      this.loading = true;
      if (!inAppValue) {
        // If 'in_app' is false, set 'push' to false automatically
        this.notificationSettingsForm.patchValue({ push: false }, { emitEvent: false });
      }
      this.generalSettingsChangesHandling()

    });

    this.isFormReady = true;
  }

  generalSettingsChangesHandling(){
    const inAppValue = this.notificationSettingsForm.get(`in_app`)!.value
    const pushValue = this.notificationSettingsForm.get(`push`)!.value
    if(!pushValue) {
      this.settingPushIsOff = true;
    }else {
      this.settingPushIsOff = false;
    }

    if(!inAppValue) {
        this.settingsIsOn = false;
    }else {
        this.settingsIsOn = true;
    }
    
  }
  // Set initial form values based on provided data
  setFormValues(data: any) {
    Object.keys(data).forEach(category => {
      this.preferenceForm.patchValue({
        [`${category}_main`]: data[category].InApp && data[category].Push,
        [`${category}_inApp`]: data[category].InApp,
        [`${category}_push`]: data[category].Push
      });
      this.indeterminateStates[`${category}_main`] = data[category].InApp !== data[category].Push;

      if (data[category].Child) {
        Object.keys(data[category].Child).forEach(child => {
          this.preferenceForm.patchValue({
            [`${category}_${child}_main`]: data[category].Child![child].InApp && data[category].Child![child].Push,
            [`${category}_${child}_inApp`]: data[category].Child![child].InApp,
            [`${category}_${child}_push`]: data[category].Child![child].Push
          });
          this.indeterminateStates[`${category}_${child}_main`] = data[category].Child![child].InApp !== data[category].Child![child].Push;
        });
      }

      this.updateMainStateFromChildState(category);

      this.notificationSettingsForm.patchValue({
        push: this.notificationSettingsInitData[0].push_on,
        in_app: this.notificationSettingsInitData[0].inapp_on,
      });
    });
  }

  // Handles changes to main category checkboxes
  onChangeMain(category: string, checked: boolean) {
    this.updateMainState(category, checked);
  }

  // Updates main category state and propagates changes to child categories
  updateMainState(category: string, checked: boolean) {
    this.preferenceForm.patchValue({
      [`${category}_inApp`]: checked,
      [`${category}_push`]: checked
    });

    this.indeterminateStates[`${category}_inApp`] = false;
    this.indeterminateStates[`${category}_push`] = false;

    // Update child categories if any exist
    if (this.notificationPreferenceData[category].Child) {
      Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
        this.updateChildStateFromMainState(category, child, 'inApp', checked);
        this.updateChildStateFromMainState(category, child, 'push', checked);
      });
    }

    this.updateMain(category); // Update the overall state of the main checkbox
  }

/**
   * Updates the main checkbox state for a category
   * Maintains consistency with linked InApp and Push states
   */
  updateMain(category: string) {
    this.updateNotificationPreferenceData();
    
    // Check if form state has changed from initial state
    if (!this.areObjectsEqual(this.notificationPreferenceInitData, this.notificationPreferenceData) || 
        !this.areObjectsEqual(this.notificationSettingsInitData, this.notificationPreferenceData)) {
      this.loading = true;
    } else {
      this.loading = false;
    }

    const inAppChecked = this.preferenceForm.get(`${category}_inApp`)!.value;
    const pushChecked = this.preferenceForm.get(`${category}_push`)!.value;
    const inAppIndeterminate = this.indeterminateStates[`${category}_inApp`];
    const pushIndeterminate = this.indeterminateStates[`${category}_push`];

    // Update main checkbox state based on InApp and Push states
    if (inAppChecked && pushChecked && !inAppIndeterminate && !pushIndeterminate) {
      this.preferenceForm.patchValue({ [`${category}_main`]: true });
      this.indeterminateStates[`${category}_main`] = false;
    } else if (!inAppChecked && !pushChecked && !inAppIndeterminate && !pushIndeterminate) {
      this.preferenceForm.patchValue({ [`${category}_main`]: false });
      this.indeterminateStates[`${category}_main`] = false;
    } else {
      this.preferenceForm.patchValue({ [`${category}_main`]: false });
      this.indeterminateStates[`${category}_main`] = true;
    }
  }

  // Handles changes to child category checkboxes 
  onChangeChild(category: string, child: string, checked: boolean) {
    this.updateChildState(category, child, checked);
  }

  // Updates child category state and reflects changes in the form
  updateChildState(category: string, child: string, checked: boolean) {
    this.preferenceForm.patchValue({
      [`${category}_${child}_inApp`]: checked,
      [`${category}_${child}_push`]: checked
    });

    this.updateChild(category, child); // Update individual child checkbox
    this.updateMainStateFromChildState(category); // Update the main checkbox based on child states
  }

  updateChild(category: string, child: string) {
    const inAppChecked = this.preferenceForm.get(`${category}_${child}_inApp`)!.value;
    const pushChecked = this.preferenceForm.get(`${category}_${child}_push`)!.value;

    // Check conditions and update form values based on the category and child type
    if (category === 'Listes des Examen') {
      
      // If "Ajout" is unchecked, disable "Nouvelle liste" for in-app notifications
      if (child === 'Ajout' && !inAppChecked) {
        this.preferenceForm.patchValue({
          // Set "Nouvelle liste_inApp" to false when "Ajout_inApp" is turned off
          [`${category}_Nouvelle liste_inApp`]: false
        });
      }

      // If "Ajout" is unchecked, disable "Nouvelle liste" for push notifications
      if (child === 'Ajout' && !pushChecked) {
        this.preferenceForm.patchValue({
          // Set "Nouvelle liste_push" to false when "Ajout_push" is turned off
          [`${category}_Nouvelle liste_push`]: false
        });
      }

      // If "Nouvelle liste" is checked for in-app notifications, enable "Ajout"
      if (child === 'Nouvelle liste' && inAppChecked) {
        this.preferenceForm.patchValue({
          // Set "Ajout_inApp" to true when "Nouvelle liste_inApp" is turned on
          [`${category}_Ajout_inApp`]: true
        });
      }

      // If "Nouvelle liste" is checked for push notifications, enable "Ajout"
      if (child === 'Nouvelle liste' && pushChecked) {
        this.preferenceForm.patchValue({
          // Set "Ajout_push" to true when "Nouvelle liste_push" is turned on
          [`${category}_Ajout_push`]: true
        });
      }
    }


    if (inAppChecked && pushChecked) {
      this.preferenceForm.patchValue({ [`${category}_${child}_main`]: true });
      this.indeterminateStates[`${category}_${child}_main`] = false;
    } else if (!inAppChecked && !pushChecked) {
      this.preferenceForm.patchValue({ [`${category}_${child}_main`]: false });
      this.indeterminateStates[`${category}_${child}_main`] = false;
    } else {
      this.preferenceForm.patchValue({ [`${category}_${child}_main`]: false });
      this.indeterminateStates[`${category}_${child}_main`] = true;
    }
  }

  /**
   * Handles changes to InApp or Push checkboxes at the main category level
   * Implements the linked behavior between InApp and Push states
   * @param category The category being updated
   * @param state The type of notification being changed ('inApp' or 'push')
   * @param checked The new checkbox state
   */
  onChangeMainState(category: string, state: 'inApp' | 'push', checked: boolean) {
    if (state === 'inApp' && !checked) {
      // When InApp is turned off, also turn off Push
      this.preferenceForm.patchValue({
        [`${category}_inApp`]: false,
        [`${category}_push`]: false
      });

    
    } else if (state === 'push' && checked) {
      // When Push is turned on, also turn on InApp
      this.preferenceForm.patchValue({
        [`${category}_inApp`]: true,
        [`${category}_push`]: true
      });
    } else {
      // Normal single checkbox update
      this.preferenceForm.patchValue({
        [`${category}_${state}`]: checked
      });
    }

    this.indeterminateStates[`${category}_${state}`] = false;

    // Update child states if they exist
    if (this.notificationPreferenceData[category].Child) {
      Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
        this.updateChildStateFromMainState(category, child, state, checked);
      });
    }

    this.updateMainStateFromChildState(category);
  }


  /**
   * Updates child state based on parent category state changes
   * Maintains linked behavior between InApp and Push states
   */
  updateChildStateFromMainState(category: string, child: string, state: 'inApp' | 'push', checked: boolean) {
    if (state === 'inApp' && !checked) {
      // When parent InApp is turned off, turn off both InApp and Push for child
      this.preferenceForm.patchValue({
        [`${category}_${child}_inApp`]: false,
        [`${category}_${child}_push`]: false
      });
    } else if (state === 'push' && checked) {
      // When parent Push is turned on, turn on both InApp and Push for child
      this.preferenceForm.patchValue({
        [`${category}_${child}_inApp`]: true,
        [`${category}_${child}_push`]: true
      });
    } else {
      // Normal single checkbox update
      this.preferenceForm.patchValue({
        [`${category}_${child}_${state}`]: checked
      });
    }
    
    this.indeterminateStates[`${category}_${child}_${state}`] = false;
    this.updateChild(category, child);
  }


 /**
   * Handles changes to InApp or Push checkboxes at the child category level
   * Implements the linked behavior between InApp and Push states
   * @param category Parent category name
   * @param child Child category name
   * @param state The type of notification being changed ('inApp' or 'push')
   * @param checked The new checkbox state
   */
  onChangeChildState(category: string, child: string, state: 'inApp' | 'push', checked: boolean) {
    if (state === 'inApp' && !checked) {
      // When InApp is turned off, also turn off Push
      this.preferenceForm.patchValue({
        [`${category}_${child}_inApp`]: false,
        [`${category}_${child}_push`]: false
      });
    } else if (state === 'push' && checked) {
      // When Push is turned on, also turn on InApp
      this.preferenceForm.patchValue({
        [`${category}_${child}_inApp`]: true,
        [`${category}_${child}_push`]: true
      });
    } else {
      // Normal single checkbox update
      this.preferenceForm.patchValue({
        [`${category}_${child}_${state}`]: checked
      });
    }

    this.updateChild(category, child);
    this.updateMainStateFromChildState(category);
  }

   /**
   * Updates main category state based on child states
   * Ensures parent state correctly reflects children's linked states
   */
  updateMainStateFromChildState(category: string) {
    const children = Object.keys(this.notificationPreferenceData[category].Child!);
    
    // Get all child states
    const inAppStates = children.map(child => 
      this.preferenceForm.get(`${category}_${child}_inApp`)!.value
    );
    const pushStates = children.map(child => 
      this.preferenceForm.get(`${category}_${child}_push`)!.value
    );

    // Calculate aggregated states
    const allInAppChecked = inAppStates.every(state => state);
    const allPushChecked = pushStates.every(state => state);
    const anyInAppChecked = inAppStates.some(state => state);
    const anyPushChecked = pushStates.some(state => state);

    // Update main category states
    this.preferenceForm.patchValue({
      [`${category}_inApp`]: allInAppChecked,
      [`${category}_push`]: allPushChecked
    });

    // Update indeterminate states
    this.indeterminateStates[`${category}_inApp`] = !allInAppChecked && anyInAppChecked;
    this.indeterminateStates[`${category}_push`] = !allPushChecked && anyPushChecked;

    // If InApp is off, ensure Push is also off
    if (!anyInAppChecked) {
      this.preferenceForm.patchValue({
        [`${category}_push`]: false
      });
    }

    // If Push is on, ensure InApp is also on
    if (allPushChecked) {
      this.preferenceForm.patchValue({
        [`${category}_inApp`]: true
      });
    }

    this.updateMain(category);
  }


  toggleIcon(category: string) {
    if (this.expandedCategories.has(category)) {
      this.expandedCategories.delete(category);
    } else {
      this.expandedCategories.clear();
      this.expandedCategories.add(category);
    }
  }

  toggleIconn(category: string) {
      this.expandedCategories.clear();
      this.expandedCategories.add(category);
  }

  isExpanded(category: string): boolean {
    return this.expandedCategories.has(category);
  }

  updateNotificationPreferenceData() {
    Object.keys(this.notificationPreferenceData).forEach(category => {
      this.notificationPreferenceData[category].InApp = this.preferenceForm.get(`${category}_inApp`)!.value;
      this.notificationPreferenceData[category].Push = this.preferenceForm.get(`${category}_push`)!.value;

      if (this.notificationPreferenceData[category].Child) {
        Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
          this.notificationPreferenceData[category].Child![child].InApp = this.preferenceForm.get(`${category}_${child}_inApp`)!.value;
          this.notificationPreferenceData[category].Child![child].Push = this.preferenceForm.get(`${category}_${child}_push`)!.value;
        });
      }
    });
  }

  onSubmit() {
    // Update the object data. 
    this.updateNotificationPreferenceData();
    console.log('Updated notification preference data:', this.notificationPreferenceData);
    this.sendPreferencesToServer();
  }

  //Send saved data to server
  sendPreferencesToServer() {
    this.loading = false;
    const notification_setting_obj = {
      "notification_settings" : this.notificationSettingsForm.getRawValue(),
      "notification_preference" : this.notificationPreferenceData,
    } 
    
    console.log("this.notification_setting_obj", notification_setting_obj)
    this.notificationService.sendPreferences(notification_setting_obj)
      .subscribe({
        next: (response) => {
        this.snackBar.openFromComponent(SuccesSnakBarComponent, {
            duration: 3000,
        });
          console.log('Preferences sent successfully:', response);
          this.closeDialog();
        },
        error: (error) => {
          console.error('Error sending preferences:', error);
        }
      })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  areObjectsEqual(obj1, obj2) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
      if (!this.areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  } 

  loadPreferences() {
    this.notificationService.getPreferences().subscribe({
      next: (data) => {
        this.data = data;
        console.log('Preferences loaded:', this.data );
        this.data.notificationPreferences = this.notificationService.renameFields(this.data.notificationPreferences);
        console.log('renamedData  loaded:', this.data );
        if (this.data) {
          console.log('Using passed data:', this.data);
          this.notificationSettingsInitData = JSON.parse(JSON.stringify(this.data["notificationSettings"]))
          this.notificationPreferenceInitData = JSON.parse(JSON.stringify(this.data.notificationPreferences))
          this.notificationPreferenceData = JSON.parse(JSON.stringify(this.notificationPreferenceInitData));
          console.log('notificationPreferenceInitData' , this.notificationPreferenceInitData);
          this.initForm();
          this.setFormValues(this.notificationPreferenceInitData);
          this.loading = false;
        } else {
          console.warn('No data provided.');
        }

      },
      error: (error) => {
        console.error('Error fetching preferences:', error);
      },
    });
  }
}