import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ReloadDialogService {
    _candidatsChange: Subject<any>;
    _calendarChange: Subject<any>;
    _paymentChange: Subject<any>;
    _depenseChange: Subject<any>;
    _revenueChange: Subject<any>;
    _cashoutChange: Subject<any>;

    constructor(private router: Router) {
        this._calendarChange = new Subject();
        this._paymentChange = new Subject();
        this._depenseChange = new Subject();
        this._candidatsChange = new Subject();
        this._revenueChange = new Subject();
        this._cashoutChange = new Subject();
    }
    getRouterLink() {
        // return this.router.routerState.snapshot.url;
        const url = this.router.routerState.snapshot.url;
        return url.split('?')[0]; // Remove the query parameters by splitting at '?'
    }

    get calendarChange() {
        return this._calendarChange.asObservable();
    }
    get paymentChange() {
        return this._paymentChange.asObservable();
    }

    get depenseChange() {
        return this._depenseChange.asObservable();
    }
    get candidatsChange() {
        return this._candidatsChange.asObservable();
    }

    get revenueChange() {
        return this._revenueChange.asObservable();
    }
    get cashoutChange() {
        return this._revenueChange.asObservable();
    }

    pushChanges(change) {
        console.log(change);
        console.log(this.getRouterLink());
        switch (this.getRouterLink()) {
            case "/candidats":
                if (change == "calendar" || change == "payment")
                    this._candidatsChange.next(1);
                break;
            //  case '/calendar':
            //   if(change == 'calendar') this._calendarChange.next(1)
            //   break;
            case "/finance/recettes":
                if (change == "payment") this._paymentChange.next(1);
                break;
            case "/finance/depenses/historique":
                if (change == "depense") this._depenseChange.next(1);
                break;
            case "/finance/revenus":
                if (change == "payment" || change == "depense")
                    this._revenueChange.next(1);
                break;
            case "/dashboards/analytics":
                this._calendarChange.next(change);
                break;

            case "/administration/contract":
                if (change == "cashout")
                    this._revenueChange.next(1);
                break;
            default:
                console.log("no url matched");
                break;
        }
    }
}
