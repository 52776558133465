<div class="explanation-container" fxLayout="column" dir="rtl">
  <h1 mat-dialog-title>الجواب</h1>
  <div mat-dialog-content fxFlex>
    <div fxLayout="column" fxLayoutAlign="stretch start">
      <p>
        {{ data.explanation }}
      </p>
      <div
        *ngIf="data.isCorrect"
        fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="start center"
        dir="rtl"
      >
        <span>إجابة صحيحة</span>
        <mat-icon>mood</mat-icon>
      </div>
      <div
        *ngIf="!data.isCorrect"
        fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="start center"
        dir="rtl"
      >
        <span>إجابة خاطئة</span>
        <mat-icon>mood_bad</mat-icon>
      </div>
    </div>
    <div fxFlex></div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button mat-button (click)="close()" class="close-btn">غلق</button>
  </div>
</div>
