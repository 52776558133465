import { map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root"
})
export class PaymentCDialogService {
    uri = environment.uriG+"/api/finances/paiements"; 

    constructor(private http: HttpClient) {}

    getContrat(cin) {
        return this.http.get(`${this.uri}/${cin}`);
    }

    setPayment(data) {
        return this.http.post<any>(`${this.uri}`, data);
    }
    editPayment(data) {
        return this.http.put<any>(`${this.uri}/${data.id}`, data);
    }
    deleteRecette(id) {
        return this.http.delete<any>(
            `${this.uri}/delete/${id}`
        );
    }
}
