import { AuthentificationService } from './../main/authentification/authentification.service';
import { GlobalService } from "./../global.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable, of, Subject } from "rxjs";
import { environment } from 'environments/environment';

@Injectable({
    providedIn: "root",
})
export class LayoutService {
    uri = environment.uriG
    scrollTo$ : Subject<any> = new Subject();
    quickPanelToggleOpen$ : Subject<any> = new Subject();
    scrollToTopSource = new Subject<void>();
    scrollToTop$ = this.scrollToTopSource.asObservable();
    closeNotif : boolean = false

    constructor(
        private http: HttpClient,
        private _globalService: GlobalService,
        private deviceService: DeviceDetectorService,
    ) {}


    /**
     * Fetches unopened exam center notifications for the current user.
     * 
     * @function fetchUnopenedExamCenterNotifications
     * @returns {Observable<any>} An observable that emits the list of unopened exam center notifications.
     */
    fetchUnopenedExamCenterNotifications(): Observable<any> {
        return this.http.get<any>(`${this.uri}/api/notifications/exams-center-notification`);
    }
    
    /**
     * Fetches the count of all undelivered or unread notifications for the logged-in user.
     * 
     * @function fetchUndeliveredAndUnreadNotificationCount
     * @returns {Observable<any>} An observable that emits the count of undelivered or unread notifications.
     */
    fetchUndeliveredAndUnreadNotificationCount(): Observable<any> {
        return this.http.get<any>(`${this.uri}/api/notifications/count`);
    }
    
    getNotifications(type: string,lastId?: string) {
        console.log("lastId:",lastId)
        return this.http.get<any>(`${this.uri}/api/notifications`,{
            params: {type, ...(lastId?{lastId}:{})}
        });
    }

    getCandidateIdForEventNotification(contentId: number,contentTable?: string): Observable<any> {
        return this.http.get<any>(`${this.uri}/api/notifications/candidate`, {
          params: { contentId: contentId.toString(),contentTable: contentTable }
        });
    }

    get scrollTo(){
        return this.scrollTo$.asObservable()
    }
    
    set scrollTo(offset:any){
        this.scrollTo$.next(offset)
    }


    get quickPanelToggleOpen(){
        return this.quickPanelToggleOpen$.asObservable()
    }

    set quickPanelToggleOpen(show:any){
        this.quickPanelToggleOpen$.next(show)
    }

    
    MarkQuestionsAsReaded() {
        return this.http.put<any>(
            `${this.uri}/api/notifications/questions`,
            {}
        );
    }

    notificationDelivered() {
        if (this._globalService.getUser().role === "superadmin") {
            return of([]);
        } else {
            return this.http.put<any>(
                `${this.uri}/api/notifications/delivered`,
                {}
            );
        }
    }

    notificationOpenedByType(type){
        return this.http.put<any>(
            `${this.uri}/api/notifications/opened/by-type/${type}`,
            {}
        )
        
    }

    notificationOpened(id) {
        return this.http.put<any>(
            `${this.uri}/api/notifications/opened`,
            {ids:id}
        )
    }

    getCurrent() {
        if (this._globalService.getUser().role === "superadmin") {
            return of(this._globalService.getUser());
        } else {
            return this.http.get<any>(`${this.uri}/api/employees/current`);
        }
    }
    getTutorial() {
        return this.http.get<any>(`${this.uri}/api/agences/tutorialState`);
    }
    updateTutorial(video) {
        return this.http.put<any>(
            `${this.uri}/api/agences/tutorialDone/${video}`,
            {}
        );
    }
    supprimerTutorial() {
        return this.http.delete<any>(
            `${this.uri}/api/agences/supprimerTutorial`
        );
    }

    detectdevice() {
        return this.deviceService.getDeviceInfo().os;
    }

    triggerScrollToTop() {
        this.scrollToTopSource.next();
    }

    closeNotificationBar(){
        this.quickPanelToggleOpen$.next([true]);
        return true
    }
    openNotificationBar(){
        this.quickPanelToggleOpen$.next([false]);
        return true
    }
}
