import * as moment from 'moment';


function roundToThree(num){
    return +(Math.round((Number(num + "e+3")))  + "e-3");
}


function getTotalNetHt(invoice){
    return roundToThree(invoice.total_ht - invoice.total_discount - invoice.total_asgcredit_used)
  }

 function getTVA(invoice){
    return roundToThree(getTotalNetHt(invoice) * 0.19)
  }

 function getTimbre(invoice){
  if (!invoice.finalized_at) return 0;
  if (getTotalNetHt(invoice) > 0) return roundToThree(invoice.stamp_fee);
  else return 0;
}

 function getTotalTTC(invoice){
    return roundToThree(getTimbre(invoice) + getTVA(invoice) + getTotalNetHt(invoice))
  }

 function getRest(invoice){
     return roundToThree(getTotalTTC(invoice) - invoice.total_paid)
}

function momentToPostgresTime(time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ssZ')
}

function momentToPostgresTimeAtEight(time) {
  return moment(time).add(7,'hour').format('YYYY-MM-DD HH:mm:ssZ')
}

export {getRest,getTotalTTC,getTotalNetHt, roundToThree,getTimbre,momentToPostgresTimeAtEight,momentToPostgresTime,getTVA}