<div id="event-choice" class="container" >
    <div class="close-container"> 
        
          <p style="text-align: center; font-size: 16px">
            <strong> 
                Choisir le type d'évenement 
            </strong>
          </p>
          <button (click)="matDialogRef.close()"  mat-mini-fab color="white" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
          </button>
    </div>
    <div mat-dialog-content fxLayout="row" fxLayoutAlign="center space-between">
    </div>

    <div *ngIf="role != 'moniteur'" style='margin-bottom: 5px; width: 100%'>

        <button mat-button mat-raised-button style='background-color: black;width: 60%;margin: 0px auto;display: block; color:white'
            (click)="matDialogRef.close('busy')">
            <mat-icon style="color: white;margin-bottom: 2px;">event_busy
            </mat-icon>
            Occupé
        </button>
       
    </div>

    <div *ngIf="role != 'moniteur'" fxLayout="row" style='margin-bottom: 5px; width: 100%'>

        <button mat-button mat-raised-button style='background-color: #00AA81;width: 50%; color:white'
            (click)="matDialogRef.close('codeLess')">
            Séance Code
        </button>
        <button mat-button style='background-color: #e04668;width: 50%; color:white' mat-raised-button color="succes"
            (click)="matDialogRef.close('codeEx')">
            Examen Code
        </button>
    </div>
    <div fxLayout="row" style='margin-bottom: 5px; width: 100%'>

        <button mat-button mat-raised-button style='background-color: #6939B2;width: 50%; color:white' cdkFocusInitial
        (click)="matDialogRef.close('conduiteLess')"
        >
            Séance Conduite
        </button>
        <button mat-button mat-raised-button style='background-color: #1F6378; width: 50%; color:white'
        (click)="matDialogRef.close('conduiteRecyclage')"
        >
            Séance Recyclage
        </button>
    </div>
    <div style="display: flex;">
        
        <button mat-button mat-raised-button style='background-color: #000F89; width: 50%; color:white'
        (click)="matDialogRef.close('conduiteEx')"
        >
            Examen Conduite
        </button>

        <button mat-button mat-raised-button style='background-color: #2D9CDB;width: 50%; color:white'
        (click)="matDialogRef.close('conduiteExParc')"
        >
            Examen Manoeuvre
        </button>

    </div>
    
    <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLessCircuit')"
        >Circuit</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLessParc')"
        >Parc</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLess')"
        >Non détérminé</button>
      </mat-menu>
      <mat-menu #menu1="matMenu">
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteExCircuit')"
        >Circuit</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteExParc')"
        >Parc</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteEx')"
        >Non détérminé</button>
      </mat-menu> -->

</div>