import { ErreurService } from "./../../../authentification/erreur.service";
import { CandidatService } from "./../../candidats/candidat/candidat.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { SuccesSnakBarComponent } from "../../../components/succes-snak-bar/succes-snak-bar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
@Component({
    selector: "app-exam-result",
    templateUrl: "./exam-result.component.html",
    styleUrls: ["./exam-result.component.scss"],
})
export class ExamResultComponent implements OnInit {
    note = 25;
    candidatId: any;
    //?booleans
    reussite = true;
    typeDePermis = null;
    nouvelleTentative = true;
    tentativeConduite = true;
    options = [
        { option: "Réussite", value: true },
        { option: "Echec", value: false },
    ];

    constructor(
        private errService: ErreurService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public _matDialogRef: MatDialogRef<ExamResultComponent>,
        public candService: CandidatService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.candidatId = _data.candidatId;
    }
    ngOnInit() {
        //?push fake state to navigate to when button back is clicked
        const modalState = {
            modal : true,
            desc : 'fake state for our modal'
        };
        history.pushState(modalState, null);

        this.typeDePermis = this._data.typeDePermis;
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            history.go(-1);
        }
    }
    register() {
        if (!this.reussite) {
            //&& this.nouvelleTentative) {
            this.candService.echecCoNouv(this.candidatId, this.note).subscribe({
                next:(res) => {
                    if (res !== null) {
                        this._matDialogRef.close({
                            note: this.note,
                            success: this.reussite,
                        });
                        this.snackBar.openFromComponent(
                            SuccesSnakBarComponent,
                            {
                                duration: 3000,
                            }
                        );
                    }
                    //
                },
                error:(err) => {
                    this.errService.popError(
                        err,
                        "Erreur s'est produit lors d'ajout. \n Essayez plus tard"
                    );
                }
            }
            );
        } else {
            this.candService.succCoNouv(this.candidatId, this.note).subscribe({
                next:(res) => {
                    if (res !== null) {
                        this._matDialogRef.close({
                            note: this.note,
                            success: this.reussite,
                        }); //
                        this.snackBar.openFromComponent(
                            SuccesSnakBarComponent,
                            {
                                duration: 3000,
                            }
                        );
                    }
                },
                error:(err) => {
                    this.errService.popError(
                        err,
                        "Erreur s'est produit lors d'ajout. \n Essayez plus tard"
                    );
                }
            });
        }
    }
    onNote(event) {
        "(typeDePermis == 'Type C+E' || typeDePermis == 'Type D') ? 35 : ((typeDePermis == 'Type A') ? 25 : 30)"

        if (this.note) {
            switch (this.typeDePermis) {
                case "Type C+E":
                case "Type D":
                    if(this.note < 30) this.reussite = false;
                    else this.reussite = true;
                    break;
                case "Type A":
                    if(this.note < 24) this.reussite = false;
                    else this.reussite = true;
                    break;
            
                default:
                    if(this.note < 24) this.reussite = false;
                    else this.reussite = true;
                    break;
            }
        }
    }
}
