import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { LayoutService } from 'app/layout/layout.service';
import * as moment from 'moment';
import { notificationStrcture } from 'app/models/notification';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent implements OnInit {
  emptyNotif: boolean = false;
  @Input() notification: any;
  @Input() selectedTab: any;
  buttonsToShow: any[] = [];
  tagsToShow: any[] = [];
  inputValue: string = '';
  emptyNotificationMessage: string = '';
  body: any;
  candidateId: any = null;
/**
 * @constant {Object} notificationStrcture
 * @description Represents the structure of notifications used throughout the application.
 * 
 * The structure is organized into several categories, each containing subcategories
 * that define the notification types and their attributes.
 * 
 * @property {Object} event - Notifications related to events.
 * @property {Object} reservation - Notifications related to reservations.
 * @property {Object} paiement - Notifications related to payments.
 * @property {Object} depense - Notifications related to expenses.
 * @property {Object} Candidate - Notifications related to candidates.
 * @property {Object} follow-up - Notifications related to follow-ups.
 * 
 * Each category includes:
 * @property {Object} <action> - Represents different actions (e.g., add, edit, delete).
 * @property {Array<Object>} <action>.toShow - Array of buttons to be displayed.
 * @property {Array<Object>} <action>.tags - Array of tag objects providing additional information.
 * 
 * Each tag object contains:
 * @property {string} id - Unique identifier for the tag.
 * @property {string} label - Display label for the tag.
 * @property {string} type - Type of the tag.
 * @property {string} tagClass - CSS class for styling the tag.
 * @property {string} matTooltip - Tooltip text for the tag.
 */


  notificationStructureCopy = JSON.parse(JSON.stringify(notificationStrcture));
  

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private _fuseSidebarService: FuseSidebarService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    console.log("this is notification:",this.notification);
    if (this.notification?.state === "empty") {
      this.setEmptyNotificationMessage();
    } else {
      this.bodyFormat(this.notification.body);
      this.deleteWhatIsAdded();
      this.changeTagContent();
      this.cdr.detectChanges();
    }
  }

  /**
   * Sets the appropriate empty notification message based on the selected tab.
   */
  private setEmptyNotificationMessage(): void {
    switch (this.selectedTab) {
      case 0: this.emptyNotificationMessage = "Pas de notifications"; break;
      case 1: this.emptyNotificationMessage = "Pas de notifications de la part des candidats"; break;
      case 2: this.emptyNotificationMessage = "Pas de notifications de la part de l'équipe"; break;
      case 3: this.emptyNotificationMessage = "Pas de notifications de la part du système"; break;
      case 4: this.emptyNotificationMessage = "Pas de notifications pour les examens"; break;
      default: this.emptyNotificationMessage = "Pas de notifications"; break;
    }
  }

  /**
   * Formats the body of the notification.
   * @param body The body of the notification.
   */
  private bodyFormat(body: any): void {
    this.body = this.sortKeys(body?.[0]);
    console.log("this is the body " , this.body)
    //body is an object it can be a single object that has "single" as key or multiple.
  }

  /**
   * Updates the tags to show based on the notification's content.
   */
  private changeTagContent(): void {
    if (!this.notificationStructureCopy[this.notification.contentTable] || 
        !this.notificationStructureCopy[this.notification.contentTable][this.notification.action]) return;

    this.buttonsToShow = this.notificationStructureCopy[this.notification.contentTable][this.notification.action].toShow;
    this.tagsToShow = this.notificationStructureCopy[this.notification.contentTable][this.notification.action].tags;

    const notifTags = this.notification?.tags || [];
    let type: any[] = [];
    let updatedTags = [...this.tagsToShow];

    for(const notifTag of notifTags) {
      for (let tag of updatedTags) {
        if (notifTag?.hasOwnProperty(tag.type)) {
          if (tag.type === "editedEvent") {
            type = notifTag[tag.type];
          } else {
            this.updateTagLabel(tag, notifTag);
          }
        }
      }
    }


    updatedTags = JSON.parse(JSON.stringify((this.checkEmptyTags(updatedTags))));
    this.addEditedEventTags(updatedTags, type);
    this.tagsToShow = updatedTags;
    this.cdr.detectChanges();
  }

  /**
   * Updates the label of a tag based on the notification's properties.
   * @param tag The tag to update.
   * @param notifTag The notification tags object.
   */
  private updateTagLabel(tag: any, notifTag: any): void {
    if (tag.type === "famille") {
      this.tagsToShow[this.tagsToShow.indexOf(tag)]["label"] = this.notification.action === "delete"
        ? notifTag[tag.type]
        : notifTag[this.checkWhichFamilly(notifTag[tag.type])];
        this.tagsToShow[this.tagsToShow.indexOf(tag)]["label"]
    } else {
      this.tagsToShow[this.tagsToShow.indexOf(tag)]["label"] = notifTag[tag.type]["label"];
      if (this.tagsToShow[this.tagsToShow.indexOf(tag)]["type"] === "candidateFullName") {
        this.candidateId = notifTag[tag.type]["id"];
      }
    }
  }

  /**
   * Determines the family type based on a given string.
   * @param famille The family string.
   * @returns The corresponding family key.
   */
  private checkWhichFamilly(famille: string): string {
    const mapping: { [key: string]: string } = {
      "Véhicules": "vehicule",
      "Candidats": "candiateRespFullName",
      "Personnelles": "agentRespFullName",
      "Locaux": "ressource",
      "Autre": "famille"
    };
    return mapping[famille] || '';
  }

  /**
   * Filters out empty tags from the list.
   * @param tags The array of tags to filter.
   * @returns The filtered array of tags.
   */
  private checkEmptyTags(tags: any[]): any[] {
    return tags.filter(tag => tag.label !== "label");
  }

  /**
   * Deletes the content of added tags.
   */
  private deleteWhatIsAdded(): void {
    this.tagsToShow.forEach(tag => delete tag.tagContent);
  }

  /**
   * Marks the notification as opened and optionally navigates to its page.
   * @param notification The notification to mark.
   * @param redirect Whether to redirect after marking.
   */
  markasOpened(notification: any, redirect: boolean = true): void {
    notification.opened = true;
    this.layoutService.notificationOpened(notification.id).subscribe(() => {
      if (redirect) {
        this.goToPage(notification);
      }
    });
  }

  /**
   * Navigates to the appropriate page based on the notification's content.
   * @param notification The notification for navigation.
   */
  private goToPage(notification: any): void {
    this.notificationOpened(notification);
    const { contentId, contentTable } = notification;

    // if (contentTable == "reservation" || contentTable == "depense") {
    this._fuseSidebarService.getSidebar("quickPanel").toggleOpen(false);
    if(contentId !== undefined ) {
        const navigationOptions = this.navigate(contentId, contentTable);
  
        this.router.navigate(navigationOptions.urls, { queryParams: navigationOptions.params });
      } else {
          
        this.router.navigate(
          this.navigate('', contentTable).urls
          )
      }
    // }else {
    //   this.layoutService.getCandidateIdForEventNotification(notification.contentId,notification.contentTable).subscribe(
    //     data => {
    //       const candidateId = data.candidatId;
    //       console.log('Candidate ID:', data.candidatId);
    //       this._fuseSidebarService.getSidebar("quickPanel").toggleOpen(false);
    //       if(contentId !== undefined || contentTable != undefined) {
    //         const navigationOptions = this.navigate(contentId, contentTable, candidateId);
      
    //         this.router.navigate(navigationOptions.urls, { queryParams: navigationOptions.params });
    //       } else {
              
    //         this.router.navigate(
    //           this.navigate('', contentTable, candidateId).urls
    //         )
    //       }
    //     },
    //     error => {
    //       console.error('Error:', error);
    //     }
    //   )
    // }

    
  }

  /**
   * Prepares navigation options based on content ID and table.
   * @param params The content ID.
   * @param url The content table.
   * @returns The navigation options containing URLs and parameters.
   */
  private navigate(params: any, url: string, candidateId?: string): { urls: string[], params?: {} } {
    console.log("params",params)
    console.log("candidateId====>",candidateId);
    console.log("url",url)
    params = JSON.stringify([params]);
    const listOfURLS: { [key: string]: { urls: string[], params: any } } = {
      reservation: { urls: ['/reservations'], params: { reservation: params } },
      event: { urls: [`/candidats/${this.candidateId}`], params: {historique: 'true'} },
      paiement: { urls: [`/candidats/${this.candidateId}`], params: {historique: 'true'} },
      depense: { urls: ['/finance/depenses/historique'], params: {depense: params} },
      Candidat: { urls: ['/candidats'], params: { candidat: params } },
      "follow-up": { urls: ['/code/questions'], params: { question: JSON.parse(params)[0] } },
      exam_list: { urls: ['/list-exams'], params: { list: params} }
    };
    return listOfURLS[url] || { urls: ['/'] };
  }

  /**
   * Marks the notification as opened if it was not already opened.
   * @param notification The notification to check.
   */
  private notificationOpened(notification: any): void {
    if (!notification.opened) {
      notification.opened = true;
    }
  }

  /**
   * Returns a human-readable date representation.
   * @param item The notification item.
   * @returns A formatted date string.
   */
  getDate(item: any): string {
    const serverDate = new Date(item.createdAt); // 'createdAt' contient le fuseau horaire

    // Soustraire manuellement 1 heure du serverDate
    const adjustedDate = new Date(serverDate.getTime() + (60 * 60 * 1000)); // Soustrait 1 heure (en millisecondes)

    // Utiliser moment pour retourner une chaîne relative
    return moment(adjustedDate).fromNow();
  }

  /**
   * Prevents default action for the mouse event.
   * @param event The mouse event.
   */
  takeAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Handles input change event.
   * @param event The input change event.
   */
  onInputChange(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const input = event.target as HTMLInputElement;
    this.inputValue = input.value;
  }

  /**
   * Prevents default action for the mouse event.
   * @param event The mouse event.
   */
  eventStop(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Adds edited event tags based on the provided types.
   * @param updatedTags The array of updated tags.
   * @param type The array of edited event types.
   */
  private addEditedEventTags(updatedTags: any[], type: any[]): void {
    if (type.length !== 0) {
      const editedEvent = updatedTags.pop();
      for (let i = 0; i < type.length; i++) {
        updatedTags.push({ tagType: "editedEvent", tagClass: "editedEvent-tag", matTooltip: "a été modifié", tagContent: type[i] });
      }
    }
  }

  //Identidy if the notification from user or agent
  identifyProfil(notif) {
    let sender = "agent";
    if ((notif.contentTable === "reservation" && notif.action === "add") || notif.contentTable === "follow-up"){
      sender = "candidate"
    }
    return sender
  }

  // sorting the velue of the body 
  sortKeys(body: any) {
    const prioritizedKeys = ['Horaire', 'durée'];
    const sorted = {};
    body = body || {}  
    // Add prioritized keys first if they exist
    prioritizedKeys.forEach(key => {
      if (body.hasOwnProperty(key)) {
        sorted[key] = body[key];
      }
    });
  
    // Add the remaining keys
    Object.keys(body).forEach(key => {
      if (!prioritizedKeys.includes(key)) {
        sorted[key] = body[key];
      }
    });
  
    return sorted;
  }


  checkIfBodyEmpty(body) {
    return Object.values(body).every(value => value === '');
  }


}
