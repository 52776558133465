import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-planning-print",
    templateUrl: "./planning-print.component.html",
    styleUrls: ["./planning-print.component.scss"],
})
export class PlanningPrintComponent implements OnInit {
    @Input("data") data;

    constructor() {}

    ngOnInit() {}

    getType(value) {
        if (value === "codeLess") return "seance code";
        else if (value === "conduiteLess") return "seance conduite";
        else if (value === "codeEx") return "examen code";
        else return "examen conduite";
    }
}
