import { GlobalService } from "./../../global.service";
import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AdminGuard implements CanActivate {
    constructor(private globalService: GlobalService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let role = this.globalService.getUser().role;
        if (role != "proprietaire" && role != "administrateur") {
            this.router.navigate(["/auth/unauthorized"]);
            return false;
        }
        return true;
    }
}
