import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountDialogService } from './account-dialog.service';
import { SuccesSnakBarComponent } from 'app/main/components/succes-snak-bar/succes-snak-bar.component';
//import * as bcrypt from "bcryptjs";
import { CandidatService } from '../../candidats/candidat/candidat.service';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent implements OnInit {
  AccountFormUser: UntypedFormGroup;
  AccountFormEquipe: UntypedFormGroup;
  password_icon: "visibility" | "visibility_off" = "visibility";
  password_type: "password" | "text" = "password";

  type: any;
  error: any
  constructor(public fb: UntypedFormBuilder,
    public pRef: MatDialogRef<AccountDialogComponent>,
    public ServiceCompte: AccountDialogService,
    private snackBar: MatSnackBar,
    public serviceCandidat: CandidatService,

    @Inject(MAT_DIALOG_DATA) private _data: any


  ) { }

  ngOnInit() {
    this.type = this._data.type;
    if (this.type == "candidat") {
      this.initFormUser();
      this.AccountFormUser.controls["candidatId"].setValue(this._data.idCandidat)
      this.AccountFormUser.controls["username"].setValue(this._data.telephone)

    } else {
      this.initFormEquipe();
      this.AccountFormEquipe.controls["email"].setValue(this._data.telephone)

    }
  }
  initFormUser() {
    this.AccountFormUser = this.fb.group({
      candidatId: [null],
      username: [null],
      password: [""], //!set disabled
    });


  }
  initFormEquipe() {
    this.AccountFormEquipe = this.fb.group({
      email: [null],
      password: [""], //!set disabled
    });


  }
  addAccountMember() {
    this.serviceCandidat.checkEmail(this.AccountFormEquipe.controls["email"].value).subscribe(res => {
      if (res.length > 0) {
        this.error = true
      } else {
        this.pRef.close(this.AccountFormEquipe.getRawValue())
      }
    })
  }
  
  addAccount() {
    this.serviceCandidat.checkUsername(this.AccountFormUser.controls["username"].value).subscribe(res => {
      if (res.length > 0) {
        this.error = true
      } else {
        this.pRef.close(this.AccountFormUser.getRawValue())
      }
    })

  }
  password_icon_click() {
    if (this.password_icon == "visibility") {
      this.password_icon = "visibility_off";
      this.password_type = "text";
    } else {
      this.password_icon = "visibility";
      this.password_type = "password";
    }
  }
  onCancel() {
    this.pRef.close();
  }


}
