<div id="error-dialog" class=container>

    <div fxLayout="row" fxLayout.xs="column" class="mb-12" fxLayoutAlign.xs="center center" fxLayoutAlign="start start"
        fxLayoutGap="12px">
        <div fxHide.md="true" class=" close-container" fxHide.lg="true" fxHide.xl=true fxHide.sm=true fxLayout="row"
            fxLayoutAlign="end end">
            <button fxHide.md="true" fxHide.lg="true" fxHide.xl=true fxHide.sm=true class="close-popup" mat-icon-button
                (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="warning-container">
            <mat-icon class="material-icons-round warning-sign">warning_amber
            </mat-icon>
        </div>

        <div class="main" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center">
            <div class="title">{{balance_limit ? 'Limite solde' : data?.title ?? 'Erreur'}}</div>
            <div mat-dialog-content class="content">
                {{confirmMessage}} 
                <br>
                <span *ngIf="balance_limit && data?.error.balanceSum">Votre Solde est de <strong>{{data.error.balanceSum[0].sum_balance | number: '1.3'}} DT</strong></span>
            </div>

        </div>
        <button fxHide.xs="true" class="close-popup" mat-icon-button (click)="dialogRef.close()"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="action">
        <div mat-dialog-actions dir="rtl" class="buttons">
            <button *ngIf="balance_limit" mat-raised-button class="mat-accent button-no" 
                (click)="facturation()">{{data?.action ?? 'Recharger'}}</button>
            <button class="button-no" mat-button (click)="dialogRef.close(false)">{{data?.action ?? 'OK'}}</button>
        </div>
    </div>
</div>