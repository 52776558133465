import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
import { GlobalService } from 'app/global.service';

@Injectable({
  providedIn: 'root'
})
export class CashoutService {
  private API_BASE_URL = environment.uriG;

  constructor(private http: HttpClient,private _globalService: GlobalService) { }


  getBalanceFromStudents(): Observable<any> {
    return this.http.get(`${this.API_BASE_URL}/api/billing/balance-from-students`);
  }

  getBankInformations(): Observable<any> {
    return this.http.get(`${this.API_BASE_URL}/api/billing/bank-account-information`);
  }


  addBankInforamtion(data:any): Observable<any> {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const requestBody = data;
    
    return this.http.post(`${this.API_BASE_URL}/api/billing/bank-account-information`, requestBody, { 'headers': headers });

  }
  
  updateBankInforamtion(id: any, data:any): Observable<any> {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const requestBody = data;

    return this.http.put(`${this.API_BASE_URL}/api/billing/bank-account-information/${id}`, requestBody, { 'headers': headers });

  }

  deleteBankInforamtion(id: any): Observable<any> {  

    return this.http.delete(`${this.API_BASE_URL}/api/billing/bank-account-information/${id}`);

  }

  transferToBalance(data: any) {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const requestBody = data;
    
    return this.http.post(`${this.API_BASE_URL}/api/billing/cashout-to-balance`, requestBody, { 'headers': headers });
   
  }

  transferToBank(montant: number, bankInformationId: string) {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const requestBody = {
      montant: montant,
      bankInformationId: bankInformationId,
    };
    
    return this.http.post(`${this.API_BASE_URL}/api/billing/cashout-to-bank`, requestBody, { 'headers': headers });
   
  }

}

