import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyInvoicePrintComponent } from './agency-invoice-print.component';



@NgModule({
  declarations: [
    AgencyInvoicePrintComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AgencyInvoicePrintComponent
  ]
})
export class AgencyInvoicePrintModule { }
