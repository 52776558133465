import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root"
})
export class LocationService {
    private API_BASE_URL = environment.uriG;

    constructor(
        private http: HttpClient,

        ) {}

    
    checkIfAgenceHasLocation(): Observable<any> {
        let headers = new HttpHeaders()
        .set('Content-Type', 'application/json');
        return this.http.get(`${this.API_BASE_URL}/api/agency-profile/check-if-agence-has-location`,  { 'headers': headers });
    }

    setAgenceLocation(data): Observable<any>{
            let headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
            const requestBody = {
                address_ar: data.address_ar,
                address_fr: data.address_fr,
                lat: data.lat,
                lng: data.lng,
            };
            
            return this.http.post(`${this.API_BASE_URL}/api/agency-profile/location`, requestBody, { 'headers': headers });
        
    }
}
