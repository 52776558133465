import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'top-notification',
  templateUrl: './top-notification.component.html',
  styleUrls: ['./top-notification.component.scss']
})
export class TopNotificationComponent implements OnInit {
  @Output()close: EventEmitter<any> = new EventEmitter<any>()

  @Input() message : string;
  @Input() action : string;

  constructor(
    private router:Router
  ) { }

  ngOnInit(): void {
  }

  closeNotification(){
    this.close.emit(true)
  }
  exAction(){
    switch (this.action) {
      case 'Facturation':
        this.router.navigate(["/administration/contract"])
        this.closeNotification()
        break;
    
      default:
        break;
    }
  }


}
