import { environment } from 'environments/environment';
import { GlobalService } from "./../../app/global.service";
import { map } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TeamService {
    uri = environment.uriG;
    constructor(
        private http: HttpClient,
        private _globalService: GlobalService
    ) { }

    getVehicules() {
        return this.http.get<any>(`${this.uri}/api/vehicles`);
    }
    getTeam() {
        return this.http.get<any>(`${this.uri}/api/employees/min`);
    }
    getRespAgents() {
        return this.http.get<any>(`${this.uri}/api/employees/respagents`);
    }

    getTeamWithArch() {
        return this.http.get<any>(`${this.uri}/api/employees/minWithArch`);
    }

    getCands() {
        return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
            map((elems) => {
                elems.forEach((elem) => {
                    elem.username = elem.prenom + " " + elem.nom;
                    delete elem.prenom;
                    delete elem.nom;
                });
                return elems;
            })
        );
    }
    getCurrentAccountForAg() {
        if (this._globalService.getUser().role === "superadmin") {
            return of(this._globalService.getUser());
        } else {
            return this.http.get<any>(`${this.uri}/api/employees/current`);
        }
    }

    getEventResp(id) {
        return this.http.get<any>(`${this.uri}/api/calendar/eventresp/${id}`);
    }
}
