<div class="dialog-content-wrapper" *ngIf="type==null">
    <mat-toolbar class="
    m-0">
        <mat-toolbar-row style="background-color:rgb(245, 198, 203) ;" fxLayoutAlign="center center" fxLayout="row">
            <span class="title dialog-title" style="font-size: 55px !important;">تحذير</span>

        </mat-toolbar-row>
    </mat-toolbar>
    <div class="p-28">
        <div mat-dialog-content fxLayout="column" fusePerfectScrollbar style="font-size: 16px;">
            <div class="w-100-p" style="text-align: right;">
                <span style="white-space: nowrap;"> {{cinNotPayed}} الحريف صاحب بطاقة التعريف الوطنية رقم</span>
                <br>
                <span> له وضعية غير مسوات مع مدرسة أخرى</span>
            </div>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                <span> {{nomcandidat}} :اللقب</span>
                <span> {{prenomcan}} :الإسم</span>
            </div>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center " fxLayoutGap="20px">
                <span> {{nomagence}} :المدرسة</span>
                <span> {{adresse}} :العنوان</span>
            </div>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="end center ">
                <span> {{telephone}} :الهاتف</span>
            </div>
        </div>
        <div mat-dialog-actions class="w-100-p" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
            <div class="w-45-p" fxLayout="row" fxLayoutGap="5px">
                <div fxLayout="row" class="w-50-p" fxLayoutAlign="space-between center">
                    <button mat-raised-button class="save-button green-A700-bg  w-100-p" aria-label="SAVE" #edit
                        (click)="_matDialogRef.close()">
                        لا
                    </button>
                </div>
                <div fxLayout="row" class="w-50-p" fxLayoutAlign="space-between center">
                    <button mat-raised-button class="save-button deep-orange-A700-bg 
                    w-100-p" aria-label="SAVE" (click)="closee()">
                        نعم
                    </button>
                </div>
            </div>
            <div class="w-50-p" dir='rtl' fxLayout="column">
                <span> هل تريد متابعة العملية؟؟</span>

            </div>
        </div>
    </div>
</div>

<div class="dialog-content-wrapper" *ngIf="type=='suspendre'">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxLayoutAlign=" center center" fxLayout="row">
            <span class="title dialog-title" style="font-size: 40px !important;color: white;">Attention</span>

        </mat-toolbar-row>
    </mat-toolbar>
    <div>
        <div mat-dialog-content fxLayout="column" class="p-24 m-0" fusePerfectScrollbar style="font-size: 16px;">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center " style="white-space: pre-wrap;">
                Vous devez vérifier si cet agent n'a aucune séance ou examen planifier avant de le suspendre.
            </div>
        </div>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button class="save-button" style="background: #03a9f4;color:white" aria-label="
                        SAVE" #edit (click)="_matDialogRef.close(false)">
                Annuler
            </button>
            <button mat-raised-button class="save-button" style="background:red;color:white" aria-label="
                        SAVE" #edit (click)="_matDialogRef.close(true)">
                Suspendre
            </button>

        </div>
    </div>
</div>

<div class="dialog-content-wrapper" *ngIf="type=='archiver'">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxLayoutAlign=" center center" fxLayout="row">
            <span class="title dialog-title" style="font-size: 40px !important;color: white;">Attention</span>

        </mat-toolbar-row>
    </mat-toolbar>
    <div>
        <div mat-dialog-content fxLayout="column" class="p-24 m-0" fusePerfectScrollbar style="font-size: 16px;">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center " style="white-space: pre-wrap;">
                Vous devez vérifier si cet agent n'a aucune séance ou examen planifier avant de l'archiver
            </div>
        </div>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button class="save-button" style="background: #03a9f4;color:white" aria-label="
                        SAVE" #edit (click)="_matDialogRef.close(false)">
                Annuler
            </button>
            <button mat-raised-button class="save-button" style="background:red;color:white" aria-label="
                        SAVE" #edit (click)="_matDialogRef.close(true)">
                Archiver
            </button>

        </div>
    </div>
</div>
<div class="dialog-content-wrapper" *ngIf="type=='email'">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxLayoutAlign=" center center" fxLayout="row">
            <span class="title dialog-title" style="font-size: 40px !important;color: white;">Attention</span>

        </mat-toolbar-row>
    </mat-toolbar>
    <div>
        <div mat-dialog-content fxLayout="column" class="p-24 m-0" fusePerfectScrollbar style="font-size: 16px;">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center " style="white-space: pre-wrap;">
                Ce telLogin est déja utiliser veuillez le changer
            </div>
        </div>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign=" center center">
            <button mat-raised-button class="save-button   w-100-p" style="background: #03a9f4;color:white" aria-label="
                        SAVE" #edit (click)="_matDialogRef.close()">
                D'accord
            </button>

        </div>
    </div>
</div>