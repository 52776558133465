import { Injectable, Injector } from "@angular/core";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { GlobalService } from "./global.service";

@Injectable({
    providedIn: "root",
})
export class AppService {
    constructor(private injector: Injector) {}
    initializeApp(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.injector
                .get(AuthentificationService)
                .getUser()
                .subscribe((user) => {
                    this.injector.get(GlobalService).setCurrentUser(user);
                    {
                        this.injector
                            .get(GlobalService)
                            .currentUser.toPromise()
                            .then((res) => {
                                resolve(1);
                            });
                    }
                });
        });
    }
}
