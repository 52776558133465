import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class EventActionService {
    private _dashAction: Subject<any>;
    private _calendarAction: Subject<any>;
    private _candAction: Subject<any>;
    constructor() {
        this._dashAction = new Subject();
        this._calendarAction = new Subject();
        this._candAction = new Subject();
    }
    setCandAction(value) {
        this._candAction.next(value);
    }

    get candaction(): Observable<any> {
        return this._candAction.asObservable();
    }

    setCalendarAction(value: "save" | "delete" | "completed") {
        this._calendarAction.next(value);
    }
    addEventsToCalendar() {
        this._calendarAction.next(true);
    }

    get calendaraction(): Observable<any> {
        return this._calendarAction.asObservable();
    }

    get dashaction(): Observable<any> {
        return this._dashAction.asObservable();
    }

    setDashAction(value: "save" | "delete" | "completed") {
        this._dashAction.next(value);
    }
}
