<div class="print">
    <table>
        <tr>
            <td valign="top">
                <table>
                    <tr class="gris">
                        Reçu 122
                    </tr>
                    <tr class="bold">
                        {{
                            getDate()
                        }}
                    </tr>
                </table>
            </td>
            <td>
                <table class="right">
                    <tr>
                        <img
                            src="https://autoecoleplus.tn/assets/Group.png"
                            style="height: 70px;"
                        />
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td>
                <table>
                    <tr class="gris">
                        Candidat
                    </tr>
                    <tr class="bold">
                        {{
                            data.Condidat
                        }}
                    </tr>
                </table>
            </td>
            <td>
                <table class="right">
                    <tr class="bold">
                        {{
                            data.AgenceName
                        }}
                    </tr>
                    <tr class="bold">
                        {{
                            data.AgenceTel
                        }}
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table>
        <tr>
            <th class="left gris">date</th>
            <th class="right gris">montant</th>
        </tr>
        <ng-container *ngFor="let item of data.Paiement">
            <tr>
                <td class="pb-10 bold">{{ item.date | date: "dd/MM/yyyy" }}</td>
                <td class="right bold">{{ item.montant | currency:'TND':'DT'}}</td>
            </tr>
            <tr>
                <td colspan="2" class="pb-10"><mat-divider></mat-divider></td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="2" class="right pt-40">
                <span class="bold">TOTAL : </span>
                <span class="blue"> {{ countTotal() | currency:'TND':'DT'}}</span>
            </td>
        </tr>
    </table>
    <div class="div-btm">
        Merci pour votre fidélité et votre confiance
    </div>
</div>
