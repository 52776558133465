<div id="notification-settings-dialog" class="container">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" ngClass.xs="titlefontxs">Paramètres Notifications</span>
            <button (click)="closeDialog()" mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="content">
        <div class="content-info">
            <p>Cochez les notifications que vous souhaitez recevoir</p>
            <mat-icon class="info">info</mat-icon>
        </div>
    
        <!-- General Notifications Form -->
        <form *ngIf="isFormReady" [formGroup]="notificationSettingsForm" class="notification-form">
            <div class="section">
                <div class="section-title">Général</div>
                <div class="flex-row">
                    <mat-checkbox formControlName="in_app" class="settings-checkbox"><b>App</b></mat-checkbox>
                    <div class="general-content-info">
                        <span class="description">Notifications à l'intérieur de l'app</span>
                        <mat-icon class="info">info</mat-icon>
                    </div>
                </div>
                <div class="flex-row">
                    <mat-checkbox formControlName="push" class="settings-checkbox" [disabled]="!settingsIsOn"><b>Push</b></mat-checkbox>
                    <div class="general-content-info">
                        <span class="description">Notifications en arrière-plan</span>
                        <mat-icon class="info" >info</mat-icon>
                    </div>
                </div>
            </div>
        </form>
    
        <mat-divider></mat-divider>
    
        <!-- Preferences Form -->
        <form *ngIf="isFormReady && settingsIsOn" [formGroup]="preferenceForm" class="preferences-form">
            <!-- info Section -->
            <div class="section sticky">
                <ng-container *ngFor="let category of examsNotificationPreferenceData | keyvalue">
                    <div class="category-section">
                        <div class="category-header">
                            <div></div>
                            <div class="checkboxes title-checkboxes">
                                <span><b>App</b></span>
                                <span><b>Push</b></span>

                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
            <!-- Exams Section -->
            <div class="section">
                <ng-container *ngFor="let category of examsNotificationPreferenceData | keyvalue">
                    <div class="category-section">
                        <div class="category-header">
                            <div class="category-title"><b>{{ category.key }}</b>
                                </div>
                            <div class="checkboxes">
                                <mat-checkbox [formControlName]="category.key + '_inApp'"
                                                [indeterminate]="indeterminateStates[category.key + '_inApp']"
                                                (change)="onChangeMainState(category.key, 'inApp', $event.checked)">
                                </mat-checkbox>
                                <mat-checkbox [formControlName]="category.key + '_push'"
                                                [indeterminate]="indeterminateStates[category.key + '_push']"
                                                (change)="onChangeMainState(category.key, 'push', $event.checked)"
                                                [disabled]="settingPushIsOff"
                                                >
                                </mat-checkbox>
                                <button mat-icon-button (click)="toggleIcon(category.key)">
                                    <mat-icon>{{ isExpanded(category.key) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="isExpanded(category.key) && category.value.Child" class="child-items">
                            <ng-container *ngFor="let childItem of category.value.Child | keyvalue">
                                <div class="child-item flex-row">
                                    <div class="child-title">{{(childItem.key === 'Ajout') ? 'Mes Examens' : childItem.key}}</div>
                                    
                                    <div class="checkboxes">
                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_inApp'"
                                                    (change)="onChangeChildState(category.key, childItem.key, 'inApp', $event.checked)">
                                        </mat-checkbox>
                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_push'"
                                                        (change)="onChangeChildState(category.key, childItem.key, 'push', $event.checked)"
                                                [disabled]="settingPushIsOff"
                                                >
                                        </mat-checkbox>

                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            
            <!-- Gestion Section -->
            <div class="section">
                <div class="section-title"><span>Gestion</span></div>
                <ng-container *ngFor="let category of gestionNotificationPreferenceData | keyvalue">
                    <div class="category-section">
                        <div class="category-header">
                            <div class="category-title"><b>{{category.key}}</b></div>
                            <div class="checkboxes">
                                <mat-checkbox [formControlName]="category.key + '_inApp'"
                                              [indeterminate]="indeterminateStates[category.key + '_inApp']"
                                              (change)="onChangeMainState(category.key, 'inApp', $event.checked)">
                                </mat-checkbox>
                                <mat-checkbox [formControlName]="category.key + '_push'"
                                              [indeterminate]="indeterminateStates[category.key + '_push']"
                                              (change)="onChangeMainState(category.key, 'push', $event.checked)"
                                              [disabled]="settingPushIsOff"
                                                >
                                </mat-checkbox>
                                <button mat-icon-button (click)="toggleIcon(category.key)">
                                    <mat-icon>{{ isExpanded(category.key) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="isExpanded(category.key) && category.value.Child" class="child-items">
                            <ng-container *ngFor="let childItem of category.value.Child | keyvalue">
                                <div class="child-item flex-row">
                                    <div class="child-title">{{childItem.key}}</div>
                                    <div class="checkboxes">

                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_inApp'"
                                                    (change)="onChangeChildState(category.key, childItem.key, 'inApp', $event.checked)">
                                        </mat-checkbox>
                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_push'"
                                        [disabled]="settingPushIsOff"
                                        (change)="onChangeChildState(category.key, childItem.key, 'push', $event.checked)">
                                        </mat-checkbox>
                                        
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
    
            <!-- Candidate Section -->
            <div class="section">
                <div class="section-title"><span>Candidat</span></div>
                <ng-container *ngFor="let category of candidateNotificationPreferenceData | keyvalue">
                    <div class="category-section">
                        <div class="category-header">
                            <div class="category-title"><b>{{category.key}}</b></div>
                            <div class="checkboxes">
                                <mat-checkbox [formControlName]="category.key + '_inApp'"
                                              [indeterminate]="indeterminateStates[category.key + '_inApp']"
                                              (change)="onChangeMainState(category.key, 'inApp', $event.checked)">
                                </mat-checkbox>
                                <mat-checkbox [formControlName]="category.key + '_push'"
                                              [indeterminate]="indeterminateStates[category.key + '_push']"
                                              [disabled]="settingPushIsOff"
                                              (change)="onChangeMainState(category.key, 'push', $event.checked)">
                                </mat-checkbox>
                                <button mat-icon-button (click)="toggleIcon(category.key)">
                                    <mat-icon>{{ isExpanded(category.key) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="isExpanded(category.key) && category.value.Child" class="child-items">
                            <ng-container *ngFor="let childItem of category.value.Child | keyvalue">
                                <div class="child-item flex-row">
                                    <div class="child-title">{{childItem.key}}</div>
                                    <div class="checkboxes">

                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_inApp'"
                                                    (change)="onChangeChildState(category.key, childItem.key, 'inApp', $event.checked)">
                                        </mat-checkbox>
                                        <mat-checkbox [formControlName]="category.key + '_' + childItem.key + '_push'"
                                        [disabled]="settingPushIsOff"
                                                    (change)="onChangeChildState(category.key, childItem.key, 'push', $event.checked)">
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
    

        </form>
        <div class="information" *ngIf="isFormReady &&!settingsIsOn">
            <p>
                Vous ne recevrez aucune notification pour le moment !
            </p>
        </div>
        <div class="spinner" *ngIf="!isFormReady" >
            <mat-spinner class="spinner" [diameter]="60" ></mat-spinner>
        </div>
        
    </div>
    <div class="buttons">
        <button style="background-color: white; color: black;" mat-stroked-button color="primary"
            (click)="closeDialog()">Annuler</button>
        <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="!loading" [ngStyle]="{
        'background-color': loading ? '#039be5' : '#e0e0e0',
        'color': loading ? 'white' : '#848484',
        'border': loading ? '' : '0px'
      }">
            Enregistrer
        </button>
    </div>
</div>