import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DepenceFormComponent } from './depence-form.component';
import { TypesPopupModule } from '../types-popup/types-popup.module';
import { DirectivesModule } from 'app/helper/directives/directives.module';



@NgModule({
  declarations: [DepenceFormComponent],
  imports: [
    DirectivesModule,
    MatDividerModule,
    CommonModule,
    MatMenuModule,
    FuseSharedModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    TypesPopupModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        NgxMatSelectSearchModule,
        MatInputModule,
        MatTabsModule,
        
        MatToolbarModule
  ],
})
export class DepenceFormModule { }
