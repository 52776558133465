import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { getRest, getTimbre, getTotalNetHt, getTotalTTC, getTVA } from 'app/utils';
import * as moment from "moment";

@Component({
  selector: 'agency-invoice-print',
  templateUrl: './agency-invoice-print.component.html',
  styleUrls: ['./agency-invoice-print.component.scss']
})
export class AgencyInvoicePrintComponent implements OnInit {

  @Input("data") data;

  @ViewChild('container') container

  constructor() { }

  ngOnInit(): void {
  }

  getPeriodStart(invoice_line){
    if(invoice_line.label_line.includes("Abonnement système de gestion") && this.data.sub_id) return `(à partir du `+ moment(this.data.start).format('Do/MM/yyyy')+' )'
  }

  getTotalNetHt(invoice){
    return getTotalNetHt(invoice)
  }

  getTVA(invoice){
    return getTVA(invoice)
  }

  getTimbre(invoice){
   return getTimbre(invoice)
  }

  getTotalTTC(invoice){
   // return this.getTimbre(invoice) + this.getTVA(invoice) + this.getTotalNetHt(invoice)
   return getTotalTTC(invoice)
  }

  

  getRest(invoice){
     return getRest(invoice);
  }

}
