import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { toDoTypes} from "./../../../../global";


@Component({
    selector: "app-vehicule",
    templateUrl: "./vehicule.component.html",
    styleUrls: ["./vehicule.component.scss"],
    animations: fuseAnimations,
})
export class VehiculeComponent implements OnInit {
    VehiculeForm: UntypedFormGroup;
    types = toDoTypes;
    check: boolean;
    add: boolean;
    typesNotSelected = true;
    checked: boolean;

    constructor(
        public fb: UntypedFormBuilder,
        public _matDialogRef: MatDialogRef<VehiculeComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {}

    ngOnInit() {
        if (this._data.row == undefined) {
            this.add = true;

            this.initForm();
        } else {
            this.initFormEdit();
            this.add = false;
        }
    }
    initForm() {
        this.VehiculeForm = this.fb.group({
            nom: [null],
            type: [null], //!set disabled
        });
    }
    initFormEdit() {
        this.VehiculeForm = this.fb.group({
            id: [this._data.row.id],
            nom: [this._data.row.nom],
            type: [this._data.row.type], //!set disabled
        });
    }
}
