import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: 'app-csv-download-popup',
  templateUrl: './csv-download-popup.component.html',
  styleUrls: ['./csv-download-popup.component.scss']
})
export class CsvDownloadPopupComponent implements OnInit {
  recette_checkbox = {
    date: true,
    effectue_par: true,
    agent: true,
    candidat: true,
    montant: true,
  };

  constructor(public dialogRef: MatDialogRef<CsvDownloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;

  }
  downloadedData = [];
  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // ! Models of checkbox candidats
  allOptionsCandidat = [
    { name: 'Nom', value: 1 },
    { name: 'Type/Catégorie', value: 1 },
    { name: 'Telephone', value: 1 },
    { name: 'Prochain Exam', value: 1 },
    { name: 'Séance Terminé', value: 1 },
    { name: 'Paye / Total', value: 1 },


  ]
    // mapping  modeles of checkbox from data params candidat

  mappingCandidat = {
    "Nom": "username",
    "Type/Catégorie": "", 
    "Telephone": "tel1",
    "Prochain Exam": "",
    "Séance Terminé": "",
    "Paye / Total": ""
  }

  // ! Models of checkbox depensse
  allOptionsDepenses = [
    { name: 'Date', value: 1 },
    { name: 'Effectué par', value: 1 },
    { name: 'Type', value: 1 },
    { name: 'Passé sur', value: 1 },
    { name: 'Montant', value: 1 },

  ]
  // mapping  modeles of checkbox from data params depense

  mappingDepense = {
    "Date": "dateFormater",
    "Effectué par": "effectParUserName",
    "Type": "typef",
    "Passé sur": "efectue_par",
    "Montant": "montant"
  }
  // ! Models of checkbox reccete
  allOptionsRecette = [
    { name: 'Date', value: 1 },
    { name: 'Effectué par', value: 1 },
    { name: 'Agent', value: 1 },
    { name: 'Candidat', value: 1 },
    { name: 'Montant', value: 1 },
  ];
// mapping  modeles of checkbox from data params recette
  mappingRecette = {
    "Date": "dateFormater",
    "Effectué par": "effectParUserName",
    "Agent": "agentIdUserName",
    "Candidat": "prenCand",
    "Montant": "montant"
  }

  // csv file options 
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Recette',
    useBom: true,
    noDownload: false,
    headers: []
  };



  onYesClick(): void {
    switch (this.data.from) {
      // ! case 1 = data from reccete
      case 1:
        this.downloadCsvRecettes()
        break;
      // ! case 2 = data from Dépense

      case 2:
        this.downloadCsvDepense();
        break;
      case 3:
        // ! case 1 = data from Candidat

        this.downloadCsvCandidat();
        break;

      default:
        break;


    }



  }
  // generate csv file of Reccettes 

  downloadCsvRecettes() {

    this.options.title = "Recette";
    this.allOptionsRecette.forEach(element => {
      if (element.value) this.options.headers.push(element.name)
    });
    this.data.data.forEach(element => {
      let receeteParams = {};
      this.options.headers.forEach(elementName => {
        receeteParams[elementName] = element[this.mappingRecette[elementName]]
        if (receeteParams[elementName] == null)
          receeteParams[elementName] = "-";
      });
      this.downloadedData.push(receeteParams);
    });
    new Angular5Csv(this.downloadedData, 'Recette :'+getToday(), this.options);
    this.dialogRef.close();


  }
  // generate csv file of depense 
  downloadCsvDepense() {

    this.options.title = "Depense";

    this.allOptionsDepenses.forEach(element => {
      if (element.value) this.options.headers.push(element.name)
    });
    this.data.data.forEach(element => {
      let DepenseParams = {};
      this.options.headers.forEach(elementName => {
        DepenseParams[elementName] = element[this.mappingDepense[elementName]]
        if (DepenseParams[elementName] == null)
          DepenseParams[elementName] = "-";
      });

      this.downloadedData.push(DepenseParams);
    });
    new Angular5Csv(this.downloadedData, 'Dépenses :'+getToday(), this.options);
    this.dialogRef.close();



  }

  // generate csv file of candidats 

  downloadCsvCandidat() {
    this.options.title = "Candidats";

    this.allOptionsCandidat.forEach(element => {
      if (element.value) this.options.headers.push(element.name)
    });
    this.data.data.forEach(element => {
      let CandidatParams = {};
      this.options.headers.forEach(elementName => {
        CandidatParams[elementName] = element[this.mappingCandidat[elementName]]

        switch (elementName) {
          case "Paye / Total":
            CandidatParams[elementName] = element.payeCont + " / " + element.totCont + " Dinar"

            break;
          case "Séance Terminé":
            CandidatParams[elementName] = "Code:" + element.HFaiCo + "h | " + "Conduite:" + element.HFaiCon + "h"


            break;
          case "Type/Catégorie":
            CandidatParams[elementName] = element.phase + " / " + element.type

            break;
          default:
            break;
        }

        if (CandidatParams[elementName] == null) {
          CandidatParams[elementName] = "-";
        }

      });

      this.downloadedData.push(CandidatParams);
    });
    new Angular5Csv(this.downloadedData, 'Candidats :'+getToday(), this.options);
    this.dialogRef.close();


  }

  
}
export function getToday(){
  let date = new Date()
  return date.getDay() + "/" + date.getFullYear()
}