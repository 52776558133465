import { Component, Input, OnInit } from '@angular/core';
import { PrintService } from 'app/layout/vertical/layout-1/print.service';

@Component({
  selector: 'recu-agence-payment',
  templateUrl: './recu-agence-payment.component.html',
  styleUrls: ['./recu-agence-payment.component.scss']
})
export class RecuAgencePaymentComponent implements OnInit {
  now = new Date();
  @Input("data") data;

  constructor(
    private _printService:PrintService
  ) {
   }

  ngOnInit(): void {
  }

  print(){
    this._printService.printAgencyRecu(this.data)
    
  }

}
