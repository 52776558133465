import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-paiement-print",
    templateUrl: "./paiement-print.component.html",
    styleUrls: ["./paiement-print.component.scss"],
})
export class PaiementPrintComponent implements OnInit {
    @Input("data") data;

    constructor() {}

    countTotal() {
        let total = 0;
        this.data.Paiement.forEach((element) => {
            total += element.montant;
        });
        return total;
    }

    getDate() {
        let date = new Date();
        return (
            date.getDate() +
            " " +
            month[date.getMonth()] +
            " " +
            date.getFullYear()
        );
    }

    ngOnInit() {}
}

export const month = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
];
