<div id="scheduling-setting-dialog" class="sch-dialog-container">
    <div class="dialog-content-wrapper">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title" ngClass.xs='titlefontxs'>Paramètres de planification</span>
                <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    
        <div class="p-24" style="overflow-y: auto;" fusePerfectScrollbar>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" class="w-100-p">
                <p class="info">
                    Veuillez choisir la durée à bloquer par default dans votre calendrier pour une séance complète.
                    <!-- <br> -->
                    (Remarque : pour deux séance de 50 minutes, le temps total bloqué est de 1h40).
                </p>
                <mat-icon class="mt-12">info</mat-icon>

            </div>
            <form *ngIf="settingsForm" fxLayout="column" [formGroup]="settingsForm" (ngSubmit)="onSubmit()">

                <div fxLayout="row" fxLayoutWrap class="w-100-p" fxLayoutGap="16px">
                    <div fxFlex="50" fxLayout="column">
                        <label for="code_less" class="input-label obligatoire">Séance code</label>
                        <mat-form-field  appearance="outline">
                            <mat-select id="code_less" formControlName="less_code_duration">
                                <mat-option
                                    *ngFor="let duration of lesson_duration_options"
                                    [value]="duration"
                                >
                                    {{ duration + " "}} minutes
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxLayout="column">
                        <label for="cond_less" class="input-label obligatoire">Séance conduite</label>
                        <mat-form-field  appearance="outline">
                            <mat-select id="cond_less" formControlName="less_cond_duration">
                                <mat-option
                                    *ngFor="let duration of lesson_duration_options"
                                    [value]="duration"
                                >
                                    {{ duration + " "}} minutes
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutWrap class="w-100-p">
                    <div fxFlex="50" fxLayout="column" class="pr-8">
                        <label for="code_exam" class="input-label obligatoire">Examen code</label>
                        <mat-form-field appearance="outline">
                            <input id="cond_exam" matInput pattern="^[0-9]{2,2}$" formControlName="exam_code_duration" placeholder="durée examen code" />
                            <mat-hint>entre 30 et 60 mins</mat-hint>
                            <mat-error *ngIf="settingsForm.get('exam_code_duration').hasError('required')">
                                Ce champ est obligatoire
                            </mat-error>
                            <mat-error *ngIf="settingsForm.get('exam_code_duration').hasError('min') || settingsForm.get('exam_cond_duration').hasError('max')">
                                Valeur entre 30 et 60
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxLayout="column" class="pl-8">
                        <label for="cond_exam" class="input-label obligatoire">Examen conduite</label>
                        <mat-form-field appearance="outline">
                            <input id="cond_exam" matInput pattern="^[0-9]{2,2}$" formControlName="exam_cond_duration" placeholder="durée examen code" />
                            <mat-hint>entre 30 et 60 mins</mat-hint>
                            <mat-error *ngIf="settingsForm.get('exam_cond_duration').hasError('required')">
                                Ce champ est obligatoire
                            </mat-error>
                            <mat-error *ngIf="settingsForm.get('exam_cond_duration').hasError('min') || settingsForm.get('exam_cond_duration').hasError('max')">
                                Valeur entre 30 et 60
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <mat-divider></mat-divider>

            <div mat-dialog-actions class="p-16 m-0" fxLayout="row" dir="rtl" fxLayoutAlign="start center">
                <button mat-flat-button
                    type="submit" class="mat-accent"
                    (click)="onSubmit()"
                    [disabled]="!settingsForm || settingsForm.pristine || settingsForm.invalid" aria-label="SAVE">
                    Enregistrer
                </button>
                <button class="mr-4" mat-stroked-button
                    (click)="_matDialogRef.close()" class="mr-8" aria-label="SAVE">
                    Annuler
                </button>
            </div>
    </div>
</div>