import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalPlacesDirective } from './decimal-places.directive';
import { TndCurrencyPipe } from './tnd-currency.pipe';
import { LimitedAccessDirective } from './limited-access.directive';
import { SyncControlDirective } from './sync-control.directive';



@NgModule({
  declarations: [
    DecimalPlacesDirective,
    TndCurrencyPipe,
    LimitedAccessDirective,
    SyncControlDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[
    DecimalPlacesDirective,
    LimitedAccessDirective,
    TndCurrencyPipe,
    SyncControlDirective
  ]
})
export class DirectivesModule { }
