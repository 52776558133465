<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <span class="title dialog-title">Ressource</span>
            <button
                mat-icon-button
                (click)="_matDialogRef.close()"
                aria-label="Close dialog"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form
            name="RessourceForm"
            [formGroup]="RessourceForm"
            autocomplete="off"
            class="event-form w-100-p"
            fxLayout="column"
            fxFlex
        >
            <mat-form-field class="w-100-p">
                <input
                (keyup.enter)="!RessourceForm.invalid &&_matDialogRef.close(RessourceForm.getRawValue())"
                    autocomplete="off"
                    matInput
                    formControlName="label"
                    placeholder="nom"
                    required
                />
            </mat-form-field>
            <mat-checkbox (change)="hi()" [checked]="checked"
                >Sous-Ressource</mat-checkbox
            >
            <div
                class="py-16"
                fxFlex="1 0 auto"
                fxLayout="column"
                fxLayout.gt-xs="row"
                *ngIf="check"
            >
                <mat-form-field
                    class="mr-sm-24"
                    style="width: 100%; margin-right: 0; margin-left: auto;"
                >
                    <mat-label>ressource parent</mat-label>
                    <mat-select formControlName="ressourceid">
                        <mat-option
                            *ngFor="let ressource of ressources"
                            [value]="ressource.id"
                        >
                            {{ ressource.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div
        mat-dialog-actions
        class="m-0 p-16"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngIf="add"
    >
        <button
            mat-raised-button
            class="save-button mat-accent"
            aria-label="SAVE"
            [disabled]="RessourceForm.invalid"
            (click)="_matDialogRef.close(RessourceForm.getRawValue())"
        >
            ajouter
        </button>
    </div>
    <div
        mat-dialog-actions
        class="m-0 p-16"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngIf="!add"
    >
        <button
            mat-raised-button
            class="save-button mat-accent"
            aria-label="SAVE"
            #edit
            [disabled]="RessourceForm.invalid"
            (click)="save()"
        >
            Sauvegarder
        </button>
    </div>
</div>
