import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountDialogService {
  uriClient = environment.uriG + "/api/candidats";
  uriAgent = environment.uriG + "/api/employees";

  constructor(private http: HttpClient,
  ) { }
  ajouterCompteClient(fd) {
    return this.http.post<any>(`${this.uriClient}/addAccount`, fd);
  }
}
