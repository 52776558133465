<div id="exam-notification-dialog" class="container">


    <!-- NO DATA -->
        <mat-toolbar class="toolbar m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="flex-end center">
                <div>
                    <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                
            </mat-toolbar-row>
        </mat-toolbar>
        <div fxLayout="column" fxLayoutAlign="end stretch" style="flex-grow: 1;">

            <div class="content" 
                >

                    <button style="transform: scale(4);" mat-icon-button aria-label="Close dialog">
                        <mat-icon matBadgeColor="accent" class="notification shake">notifications_on</mat-icon>
                    </button>

                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div class="head">
                            <span class="text-center font-size-24 font-weight-900">
                                Activer Les Notifications
                            </span>
                            
                        </div>
                        <span class="text-center font-size-16 mt-16">
                            <div style="line-height: normal;" *ngIf="!data.notificationBlockedFromBrowser" fxLayout="column">
                                <span>Merci d'activer les notifications de votre</span>
                                <span>navigateur pour rester informé.</span>
                            </div>
                            <span *ngIf="data.notificationBlockedFromBrowser" style="line-height: normal;" class="mt-16 mb-16" fxLayout="column">
                                <span>Vous avez bloqué les notifications!</span>
                                <span>Activez-les pour rester informé des dernières actualités.</span>
                            </span>
                        </span>
                        
                    </div>
                    <div class="text" style="overflow-y: auto;">
                        <div class="notifcations-content">
                        </div>
                        
                        <div *ngIf="data.notificationBlockedFromBrowser" fxLayout="column" fxLayoutAlign="center center">
                            <img src="../../../../../assets/gifs/allow_notifications_chrome_pc.gif" alt="">
                        </div>
                        
                        <div *ngIf="data.notificationBlockedFromBrowser" class="explication">
                            <h2>Comment Activer les Notifications</h2>

                            <div *ngIf="browser == 'Chrome'">
                                <ol>
                                    <li>
                                        <p><strong>Ouvrir Chrome</strong> :</p>
                                        <ul>
                                            <li>Lancez le navigateur <strong>Chrome</strong> sur votre appareil.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Accéder à l'Application</strong> :</p>
                                        <ul>
                                            <li>Rendez-vous à l'URL de votre application web.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Autoriser les Notifications</strong> :</p>
                                        <ul>
                                            <li *ngIf="deviceType == 'Desktop'">Cliquez sur le menu à trois points &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Confidentialité et sécurité</strong> &gt; 
                                                <strong>Paramètres des sites</strong> &gt; <strong>Notifications</strong>. Trouvez votre 
                                                application et assurez-vous que les notifications sont autorisées.
                                            </li>
                                            <li *ngIf="deviceType == 'Phone'">Appuyez sur le menu à trois points &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Paramètres des sites</strong> &gt; 
                                                <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            
                            <div *ngIf="browser == 'Firefox'">
                                <ol>
                                    <li>
                                        <p><strong>Ouvrir Firefox</strong> :</p>
                                        <ul>
                                            <li>Lancez le navigateur <strong>Firefox</strong> sur votre appareil.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Accéder à l'Application</strong> :</p>
                                        <ul>
                                            <li>Rendez-vous à l'URL de votre application web.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Autoriser les Notifications</strong> :</p>
                                        <ul>
                                            <li *ngIf="deviceType == 'Desktop'">Cliquez sur les trois lignes horizontales &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Confidentialité et sécurité</strong> &gt; 
                                                <strong>Permissions</strong> &gt; <strong>Notifications</strong>. Assurez-vous que votre 
                                                application est autorisée.
                                            </li>
                                            <li *ngIf="deviceType == 'Phone'">Appuyez sur les trois lignes horizontales &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Autorisations du site</strong> &gt; 
                                                <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            
                            <div *ngIf="browser == 'Edge'">
                                <ol>
                                    <li>
                                        <p><strong>Ouvrir Edge</strong> :</p>
                                        <ul>
                                            <li>Lancez le navigateur <strong>Edge</strong> sur votre appareil.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Accéder à l'Application</strong> :</p>
                                        <ul>
                                            <li>Rendez-vous à l'URL de votre application web.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Autoriser les Notifications</strong> :</p>
                                        <ul>
                                            <li *ngIf="deviceType == 'Desktop'">Cliquez sur le menu à trois points &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Cookies et autorisations de site</strong> &gt; 
                                                <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                            <li *ngIf="deviceType == 'Phone'">Appuyez sur le menu à trois points &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Paramètres du site</strong> &gt; 
                                                <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            
                            <div *ngIf="browser == 'Safari'">
                                <ol>
                                    <li>
                                        <p><strong>Ouvrir Safari</strong> :</p>
                                        <ul>
                                            <li>Lancez le navigateur <strong>Safari</strong> sur votre appareil.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Accéder à l'Application</strong> :</p>
                                        <ul>
                                            <li>Rendez-vous à l'URL de votre application web.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Autoriser les Notifications</strong> :</p>
                                        <ul>
                                            <li *ngIf="deviceType == 'Desktop'">Allez dans <strong>Safari</strong> > 
                                                <strong>Préférences</strong> > <strong>Sites web</strong> > <strong>Notifications</strong>. 
                                                Assurez-vous que votre application est autorisée.
                                            </li>
                                            <li *ngIf="deviceType == 'Phone'">Allez dans <strong>Réglages</strong> > 
                                                <strong>Safari</strong> > <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            
                            
                            <div *ngIf="browser == 'Opera'">
                                <ol>
                                    <li>
                                        <p><strong>Ouvrir Opera</strong> :</p>
                                        <ul>
                                            <li>Lancez le navigateur <strong>Opera</strong> sur votre appareil.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Accéder à l'Application</strong> :</p>
                                        <ul>
                                            <li>Rendez-vous à l'URL de votre application web.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>Autoriser les Notifications</strong> :</p>
                                        <ul>
                                            <li *ngIf="deviceType == 'Desktop'">Cliquez sur le menu à trois lignes &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Avancé</strong> &gt; 
                                                <strong>Confidentialité et sécurité</strong> &gt; <strong>Paramètres des sites</strong> &gt; 
                                                <strong>Notifications</strong>. Trouvez votre application et assurez-vous que les notifications sont autorisées.
                                            </li>
                                            <li *ngIf="deviceType == 'Phone'">Appuyez sur le menu à trois lignes &gt; 
                                                <strong>Paramètres</strong> &gt; <strong>Site web</strong> &gt; 
                                                <strong>Notifications</strong>. Assurez-vous que votre application est autorisée.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            
                            
                        </div>
                        
                    </div>
                    
                
            </div>

            <mat-divider></mat-divider>
            <div class="action-container" dir="rtl" >
    
                
                <button *ngIf="!data.notificationBlockedFromBrowser" mat-flat-button class="mat-accent attt-button exams-center-button" 
                    (click)="allowNotifications()">
                    Activer
                </button>

                <button *ngIf="data.notificationBlockedFromBrowser"  mat-flat-button class="mat-accent attt-button exams-center-button"
                    (click)="onCancel()">
                    OK
                </button>
                
                <button mat-stroked-button 
                    (click)="onCancel()">
                    Pas maintenant
                </button>
                <button mat-stroked-button class="mr-auto jamais-notifier"
                    (click)="neverNotifyAgain()">
                    Jamais me notifier
                </button>
            </div>
        </div>
        
</div>