<div class="recu-container print" fxLayout="column" fxLayoutAlign="center">
    <div class="footer mt-32 no-print" (click)="print()" style="color: rgb(0, 162, 255);cursor: pointer;" fxLayout="row" fxLayoutAlign="center center">
        imprimer
    </div>
    <div class="header" fxLayout="row">
        <img src="assets/icons/icon-96x96.png" alt="logo autoecoleplus.tn">
        <div class="header-text" fxLayout="column" fxLayoutAlign="center">
            <span class="main-text">Reçu de paiement autoecoleplus.tn</span>
            <span class="secondary-text">Merci pour votre paiement</span>
        </div>
    </div>
    <div class="main" fxLayout="column" fxLayoutGap="20px">
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Client</span>
            <!-- <span class="col2">{{user.prenom}} {{user.nom}}</span> -->
            <span class="col2">{{data.fullname}}</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Montant</span>
            <span class="col2">{{data.received_amount}} DT</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Date</span>
            <span class="col2">{{now | date}}</span>
        </div>
        <div class="line" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="col1">Methode</span>
            <span class="col2">Carte Bancaire</span>
        </div>

        <div class="footer mt-32 no-print" fxLayout="row" fxLayoutAlign="center center">
            <a [routerLink]="['/administration/contract']" routerLinkActive="router-link-active"  class="foote">retourner à la page de facturation</a>
        </div>

    </div>
</div>