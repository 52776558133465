<div id="pcik-location-dialog" class="pcik-location-deep">
    <div *ngIf="true" class="dialog-content-wrapper container-event-form">
        <mat-toolbar class="mat-accent m-0">

            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
                <span>
                    Ajouter votre address 
                </span>

                <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon class="fail" style="background: inherit !important">close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <!-- ? CONTENT -->

        <div class="p-24 m-0 content" style="overflow-y: auto; overflow-x: visible" fusePerfectScrollbar>

            <div *ngIf="currentState == 1" class="pb-8 m-0 content step1" style="overflow-y: auto; overflow-x: visible" fusePerfectScrollbar>
                
                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" class="w-100-p">
                    <p class="info">
                        <span class="big-text">
                            Veuillez ajouter votre adresse pour recommander votre auto-école aux candidats dans votre région.
                        </span>

                    </p>

                    <mat-icon class="mt-12">info</mat-icon>

                </div>
            </div>



            <form *ngIf="locationForm" fxLayout="column"  [@componentTransition]="currentState" [formGroup]="locationForm" (ngSubmit)="onSubmit()">
                <div *ngIf="currentState == 1" class="step1">
                    <div fxLayout="row"  fxLayoutWrap class="w-100-p" >
                        <div fxFlex="100">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen">Address en Francais (العنوان باللغة الفرنسية)</label>
                                <mat-form-field appearance="outline">
                                <mat-label *ngIf="isXsScreen">Address en Francais (العنوان باللغة الفرنسية)</mat-label>
                                <input id="title" matInput formControlName="address_fr" placeholder="Enter Address en Francais">
                                <mat-error *ngIf="locationForm.get('address_fr').hasError('required')">
                                    Ce champ est obligatoire
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row"  fxLayoutWrap class="w-100-p step2">
                        <div fxFlex="100">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen">Address en Francais (العنوان باللغة العربية)</label>
                                <mat-form-field appearance="outline">
                                <mat-label *ngIf="isXsScreen">Address en Arabe (العنوان باللغة العربية)</mat-label>
                                <input dir="rtl" id="title" matInput formControlName="address_ar" class="address_ar" placeholder="العنوان باللغة العربية">
                                <mat-error *ngIf="locationForm.get('address_ar').hasError('required')">
                                    Ce champ est obligatoire
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="currentState == 2" fxLayoutWrap class="w-100-p">
                    <agm-map [zoom]="zoom" (mapReady)="mapReadyHandler($event)" [latitude]="latitude"
                        [longitude]="longitude" [disableDefaultUI]="true">
                        <agm-zoom-control></agm-zoom-control>
                        <agm-fullscreen-control></agm-fullscreen-control>
                        <agm-marker (markerClick)="clickedMarker(markers.label)" [latitude]="markers.lat"
                            [longitude]="markers.lng" [label]="markers.label" [markerDraggable]="markers.draggable"
                            (dragEnd)="markerDragEnd(markers, $event)">
                            <agm-info-window>
                                <strong> l'emplacement de votre établissement </strong>
                            </agm-info-window>
                        </agm-marker>
                    </agm-map>

                </div>
            </form>

        </div>

        <!-- ? ACTIONS -->
        <div
        *ngIf="errors"
        style="
            background-color: rgb(211, 107, 211);
            padding: 24px;
            border-radius: 5px;
        "
        class="py-16 mx-16 mb-12 register"
        fxFlex="1 0 auto"
        fxLayout="column"
        fxLayout.gt-xs="row"
    >
        <span class="text">{{ errorMessage }}</span>
    </div>
        <mat-divider class="mb-12"></mat-divider>

        <div class="m-0 pt-4 pb-8 px-20" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button *ngIf="edit" mat-icon-button (click)="_matDialogRef.close(['delete'])" aria-label="SAVE">
                    <mat-icon style="color: red">delete_outline</mat-icon>
                </button>
            </div>
            <div fxLayout="row" *ngIf="currentState == 2" class="w-100-p">
                <button (click)="getLocation()" mat-stroked-button color="primary">
                   Ma localisation 
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="currentState == 1" fxLayoutGap="4px">
                <button (click)="onCancel()" mat-stroked-button>
                    Annuler
                </button>
                <button mat-raised-button class="save-button mat-accent" aria-label="NEXT" [disabled]="locationForm.get('address_ar').hasError('required') || locationForm.get('address_fr').hasError('required')" (click)="goToNext()">
                    Suivant 
                    <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="currentState == 2" fxLayoutGap="4px">
                <button (click)="goToPrevious()" mat-stroked-button>
                    <mat-icon>navigate_before</mat-icon>
                    Précédent
                </button>
                <button mat-raised-button class="save-button mat-accent" aria-label="SAVE" [disabled]="locationForm.invalid || disableSubmitButton" (click)="onSubmit()">
                    Enregistrer
                </button>
            </div>
        </div>
    </div>