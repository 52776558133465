<div *ngIf="this.type === 'filter'" class="container">
     <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">filter</span>
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content fusePerfectScrollbar fxLayout="column" fxFlexAlign="center"
        class="event-form w-100-p p-24" fxLayout="column">
        <mat-form-field class="w-100-p paddingR paddingL" style="padding-top:1%" appearance="outline"
            floatLabel="always">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="typeSelect" placeholder="Type">
                <mat-option *ngFor="let type of types" [value]="type.value">
                    {{type.nom}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="role != 'moniteur'" class="w-100-p paddingR paddingL" style="padding-top:1%"
            fxFlexAlign="center" appearance="outline" floatLabel="always">
            <mat-label>Agent</mat-label>
            <mat-select [(ngModel)]="agentSelect" placeholder="Agent">
                <mat-option *ngFor="let agent of agents" [value]="agent.id">
                    {{agent.username}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100-p paddingR paddingL" style="padding-top:1%" fxFlexAlign="center"
            appearance="outline" floatLabel="always">
            <mat-label>candidats</mat-label>
            <mat-select mat-select [(ngModel)]="candidatSelect" placeholder="candidats">
                <mat-option *ngFor="let cand of cands" [value]="cand.id">
                    {{cand.username}} &nbsp; {{cand.tel1}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100-p paddingR paddingL" style="padding-top:1%" fxFlexAlign="center"
            appearance="outline" floatLabel="always">
            <mat-label>voiture</mat-label>
            <mat-select mat-select [(ngModel)]="voiture" placeholder="voiture">
                <mat-option *ngFor="let voiture of voitures" [value]="voiture.id">
                    {{voiture.nom}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" class="p-12" fxLayoutAlign="end center" fxLayoutGap="4px">
        <div>
            <button mat-stroked-button class="save-button" aria-label="SAVE" #edit
                (click)="_matDialogRef.close()">
                annuler
            </button>
        </div>
        <div>
            <button color="primary" mat-raised-button class="save-button" aria-label="SAVE" #edit
                (click)="_matDialogRef.close(0)">
                réinitialiser
            </button>
        </div>
        <div >
            <button mat-raised-button class="save-button mat-accent" aria-label="SAVE" (click)="closee()">
                chercher
            </button>
        </div>
    </div>
</div>
<div *ngIf="this.type === 'stats'" style="padding : 0 !important">
    <mat-toolbar class="mat-accent">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">stats</span>
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content fxLayoutAlign="center center" fxLayout="column" fxFlexAlign="center" fxLayout="column">
        <div fxLayout="row">
            <div class="w-50-p paddingR paddingL mr25" fxLayoutAlign="start center" fxLayout="row">
                <p style='font-size:100%;' class="whitespacenowrap">Examen Code : </p>
            </div>
            <div class="w-50-p paddingR paddingL ml25" fxLayoutAlign="start center" fxLayout="row">

                <p class="code-color codeEx"></p>
            </div>
        </div>
        <div fxLayout="row" style="padding-top: 5%">
            <div class="w-50-p paddingR paddingL mr25" fxLayoutAlign="start center" fxLayout="row">
                <p style='font-size:100%;' class="whitespacenowrap">Séance Code : </p>
            </div>
            <div class="w-50-p paddingR paddingL ml25" fxLayoutAlign="start center" fxLayout="row">
                <p class="code-color codeLess"> </p>
            </div>
        </div>
        <div fxLayout="row" style="padding-top: 5%">

            <div class="w-50-p paddingR paddingL mr25" fxLayoutAlign="start center" fxLayout="row">
                <p style='font-size:100%;' class="whitespacenowrap">Examen Conduite : </p>
            </div>
            <div class="w-50-p paddingR paddingL ml25" fxLayoutAlign="start center" fxLayout="row">
                <p class="code-color condEx"> </p>

            </div>
        </div>
        <div fxLayout="row" style="padding-top: 5%">

            <div class="w-50-p paddingR paddingL mr25" fxLayoutAlign="start center" fxLayout="row">
                <p style='font-size:100%;' class="whitespacenowrap">Séance Conduite : </p>
            </div>
            <div class="w-50-p paddingR paddingL ml25" fxLayoutAlign="start center" fxLayout="row">
                <p class="code-color condLess"></p>
            </div>
        </div>



    </div>

</div>