import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CashoutService } from '../cashout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tnBanksInfo } from 'app/global';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccesSnakBarComponent } from '../../succes-snak-bar/succes-snak-bar.component';

@Component({
  selector: 'app-cashout-dailog',
  templateUrl: './cashout-dailog.component.html',
  styleUrls: ['./cashout-dailog.component.scss'],
  animations: [
    trigger('componentTransition', [
      state('step1', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('step2', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('step3', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      transition('step1 => step2', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step2 => step1', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step1 => step3', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step3 => step1', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step2 => step3', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step3 => step2', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ])
    ])
  ]
})


export class CashoutDailogComponent implements OnInit {

  /*
  this component contains the cashout methods:
    method 1: transferToBalance // this.transferToBalance = true;
              we have only one step where the user pick the amount needed to transfer to balance


    method 2: bankWithdrawal // this.bankWithdrawal = true;
              we have 2 options:
                option 1: there are no bank accounts refer to this agence
                        step 1: add a bank account , save the Id of this bank account
                        step 2: pick the amount needed to transfer to the bank account
                
                option 2: there are one or more bank accounts refer to this agence
                        step 1: pick one of the bank accounts or user can choice add new bank account and return to option 1
                        step 2: pick the amount needed to transfer to the bank account
  */

  firstLoading : boolean = true;

  cashoutForm: FormGroup;
  addBankAccountInformationForm: FormGroup;
  dialogTitle = "Retrait bancaire";
  transferToBalance = false;
  bankWithdrawal = false;
  cashoutBalance : number =0;
  maxCashoutBalance : number =0;
  disableSubmitButton= true;
  montant = 0;

  currentState = 0;
  banksInformations: any;
  tnbanksInformations = tnBanksInfo;
  selectedBankOption: any; 
  selectedBankAccountOption: any; 
  bankSelected : boolean = false; //trun on true when bank selected
  bankAccountSelected : boolean = false; //trun on true when bank selected
  disableSubmitAddBankInforButton = false; 
  isXsScreen :boolean = false;

  bankInformationId : string = "";
  selectFromTheExsitingAccounts :boolean = false;
  prevouisState = 0;

  bankIdToDelete : any = "";

  bankProfileEditing = false; // turon to true after add new bank profile for update it if click presedent
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent> = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialogRef: MatDialogRef<CashoutDailogComponent>,
    private _cashoutService : CashoutService,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private _matDialog: MatDialog,
    private snackBar: MatSnackBar,

  ) { }

  totaltransfer = false;

  ngOnInit(): void {


    //for dsiplay 
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.isXsScreen = result.matches;
    });


    if (this.data.cashout_type == "bankWithdrawal"){
      this.dialogTitle = "Retrait bancaire";
      this.transferToBalance= false;
      this.bankWithdrawal= true;
    }else {
      this.dialogTitle = "Convertir en solde";
      this.transferToBalance = true;
      this.currentState = 3;
      this.bankWithdrawal = false;
    }



    // for transfer to balance 

    this._cashoutService.getBalanceFromStudents().subscribe({
      next: (data) => {
        this.cashoutBalance = data.balance_from_students;
        this.maxCashoutBalance = Math.floor(this.cashoutBalance);
        if (this.bankWithdrawal) {
          this.cashoutForm = this.fb.group({
            montant: [ '', [Validators.required, Validators.min(20) , Validators.max(this.maxCashoutBalance)]],
          });
        }
        else {
          this.cashoutForm = this.fb.group({
            montant: [ '', [Validators.required, Validators.min(1) , Validators.max(this.maxCashoutBalance)]],
          });
        }
        this.cashoutForm.valueChanges.subscribe(() => {          
          this.disableSubmitButton = false;
        });
    
      },
      error: (errors) => {
        console.log(errors);
      }
    });






    /// for bank withdrawl
    if (this.bankWithdrawal) {
      this._cashoutService.getBankInformations().subscribe({
        next: (data) => {
          this.banksInformations = data;
          if (this.banksInformations.length ==  0) {
            this.currentState = 1;
            this.selectFromTheExsitingAccounts = false;
          }
          else{
            this.currentState = 2;
            this.selectFromTheExsitingAccounts = true;
            if (this.banksInformations.length == 1) {
              this.selectedBankAccountOption =  this.banksInformations[0];
              this.bankInformationId = this.selectedBankAccountOption.id;
            }
          }
          console.log(this.selectFromTheExsitingAccounts);
          
          setTimeout(() => this.firstLoading = false, 300);
        },
        error: (errors) => {
          console.log(errors);
        }
      });
    }else 
      setTimeout(() => this.firstLoading = false, 300);

    
    this.addBankAccountInformationForm = this.fb.group({
      rib: ['', [Validators.required, this.ribLengthValidator(20)]],
      accountOwnerName: ['', [Validators.required]],
      bank: ['', [Validators.required]],
    });
    
  }


  ribLengthValidator(LengthRequired: number) {
    return (control) => {
      if (control.value && control.value.length != LengthRequired) {
        return { Length: { requiredLength: LengthRequired, actualLength: control.value.length } };
      }
      return null;
    };
  }

  getBankImageUrlByName(bankName: string): string {
    const bank = this.tnbanksInformations.find(bank => bank.name === bankName);
    return bank ? bank.image : ''; // Provide a default image URL if bank is not found
  }


  toggleDisabledInputMontant() {
    const montantControl = this.cashoutForm.get('montant');
    if (this.totaltransfer) {
      montantControl.disable();
    } else {
      montantControl.enable();
    }
  }


  changeTransferTotalite(event) {
    // Handle the checkbox change event here
    let checked = event.checked; 
    if (checked) {
      console.log("checked");
      console.log(Math.floor(this.cashoutBalance));
      this.cashoutForm.get('montant')?.setValue(Math.floor(this.cashoutBalance));
      console.log(Math.floor(this.cashoutBalance));
      this.totaltransfer = true;
      this.toggleDisabledInputMontant();
      this.disableSubmitButton= false;
      
      this.montant= Math.floor(this.cashoutBalance);
    } else {
      this.cashoutForm.get('montant')?.setValue(0);
      this.totaltransfer = false;
      this.disableSubmitButton= true;
      this.toggleDisabledInputMontant();
      this.montant= 0;
      
    }
  }



  // this function is for select in add new bank account information
  onBankSelectionChange(event: any) {
    this.selectedBankOption = event.value;
    this.bankSelected = true;
    this.addBankAccountInformationForm.get('bank').setValue(this.selectedBankOption?.name); // Set bank name in the form

  }

  // this function is for select one of the exsits banks account information
  onBankAccaountSelectionChange(event: any) {
    this.selectedBankAccountOption = event.value;
    this.bankAccountSelected = true;
    this.bankInformationId = this.selectedBankAccountOption.id

  }


  // for navigation
  goToStep(step: number) {
    this.currentState = step;
  }

  onCancel() {
    this._matDialogRef.close();
  }

  onSubmitAddBankAccountInformations() {
    if (this.bankProfileEditing) {
      this.updateBankAccountInformations();
    }else {
      this.addBankAccountInformations();

    }
   
  }

  addBankAccountInformations(){
    this.disableSubmitAddBankInforButton = true;
    this._cashoutService.addBankInforamtion(this.addBankAccountInformationForm.value).subscribe({
      next: data => {
        this.disableSubmitAddBankInforButton = false;
        this.selectedBankAccountOption = data[0];
        this.bankInformationId = data[0].id;
        console.log("this.selectedBankAccountOption", this.selectedBankAccountOption);
        this.prevouisState = this.currentState;
        this.bankProfileEditing = true;
        this.goToStep(3);
      },
      error: (err) => {
        console.log(err)
        this.disableSubmitAddBankInforButton = false;
        this.onCancel();
      }
    });
  }
  updateBankAccountInformations() {
    this.disableSubmitAddBankInforButton = true;
      this._cashoutService.updateBankInforamtion(this.bankInformationId, this.addBankAccountInformationForm.value).subscribe({
        next: data => {
          this.disableSubmitAddBankInforButton = false;
          this.selectedBankAccountOption = data[0];
          this.bankInformationId = data[0].id;
          console.log("this.selectedBankAccountOption", this.selectedBankAccountOption);
          this.prevouisState = this.currentState;
          this.bankProfileEditing = true;
          this.goToStep(3);
        },
        error: (err) => {
          console.log(err)
          this.disableSubmitAddBankInforButton = false;
          this.onCancel();
        }
      });
   
  }
  

  onSubmitTransferToBalance(){

    this.disableSubmitButton = true;
    this._cashoutService.transferToBalance(this.cashoutForm.value).subscribe({
      next: data => {
        this._matDialogRef.close("success");
      },
      error: (err) => {
        this._matDialogRef.close("error");
      }
    });

  }

  onSubmitTransferToBank(){

    this.disableSubmitButton = true;
    console.log(this.bankInformationId);
    this._cashoutService.transferToBank(this.cashoutForm.get('montant').value,this.bankInformationId).subscribe({
      next: data => {
        this._matDialogRef.close("success");
      },
      error: (err) => {
        this._matDialogRef.close("error");
      }
    });

  }

  onSubmit() {

    if (this.transferToBalance) {
      this.onSubmitTransferToBalance();
    }else {
      this.onSubmitTransferToBank();
    }
  }


  validateInteger(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    console.log(charCode);

    // Allow control keys such as backspace, delete, arrow keys, etc.
    if (event.ctrlKey || event.altKey || charCode === 8 || charCode === 9 || charCode === 13 || charCode === 27 ) {
          console.log(charCode);
      return;
    }

    // Check if the key pressed is a digit (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  
  navigateToPrevious(){
  console.log("navigateToPrevious", this.prevouisState);
  console.log("currentState", this.currentState);
  if(this.prevouisState != 0) {
    this.currentState =  this.prevouisState;
  }
  else {
    this.currentState =  this.currentState-1 

  }
  }
  navigateToNext(){
    this.prevouisState = this.currentState; 
    this.currentState =  this.currentState-1; 

  }

  deleteBankAccount(bank) {
    console.log("deleteBankAccount", bank.id);
    this.bankIdToDelete = bank.id;

    this.confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
        data : {
          onlyCloseModel: true,
        }
      }
  );
  this.confirmDialogRef.componentInstance.confirmMessage =
      `Veuillez confirmer la suppression du compte bancaire de ${bank.account_owner_name}?`;

  this.confirmDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
          if (result) {
            this._cashoutService.deleteBankInforamtion(this.bankIdToDelete).subscribe({
              next: data => {
                this.ngOnInit();
                this.snackBar.openFromComponent(SuccesSnakBarComponent, {
                    duration: 3000,
                });
              },
              error: (err) => {
                console.log(err);
              }
            });
          }
          this.confirmDialogRef = null;
      });
  }
}
