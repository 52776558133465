import { NgModule } from "@angular/core";

import { VerticalLayout1Module } from "app/layout/vertical/layout-1/layout-1.module";
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { NotificationSettingsDialogComponent } from './components/notification-settings-dialog/notification-settings-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";


@NgModule({
    imports: [
      VerticalLayout1Module,
      MatToolbarModule,
      MatIconModule,
      MatButtonModule,
      MatSlideToggleModule,
      MatCheckboxModule,
      ReactiveFormsModule,
      MatSlideToggleModule,
      CommonModule,
      MatGridListModule,
      MatDividerModule,
      MatProgressSpinnerModule
    ],
    exports: [VerticalLayout1Module],
    declarations: [
    
  
    NotificationSettingsDialogComponent
  ],
})
export class LayoutModule {}
