<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Vehicule</span>
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form autocomplete="off" name="VehiculeForm" [formGroup]="VehiculeForm" class="event-form w-100-p"
            fxLayout="column" fxFlex>
            <mat-form-field class="mr-sm-24" style="width: 100%; margin-right: 0; margin-left: auto">
                <mat-label>Categorié de voiture</mat-label>
                <mat-select formControlName="type" multiple required>
                    <mat-option *ngFor="let type of types" [value]=type.type>
                        {{ type.type }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100-p">
                <input autocomplete="off" (keyup.enter)="_matDialogRef.close(VehiculeForm.getRawValue())" matInput formControlName="nom" placeholder="nom" required />
            </mat-form-field>
            <div class="py-16" fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
            
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="add">
        <button mat-raised-button class="save-button mat-accent" aria-label="SAVE" [disabled]="VehiculeForm.invalid"
            (click)="_matDialogRef.close(VehiculeForm.getRawValue())">
            ajouter
        </button>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!add">

        <button mat-raised-button class="save-button mat-accent" aria-label="SAVE" #edit
            [disabled]="VehiculeForm.invalid" (click)="_matDialogRef.close(VehiculeForm.getRawValue())">
            Sauvegarder
        </button>
    </div>

</div>