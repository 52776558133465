import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErreurService } from 'app/main/authentification/erreur.service';
import { SuccesSnakBarComponent } from 'app/main/components/succes-snak-bar/succes-snak-bar.component';
import { CalendarService } from 'app/main/plus/calendar/calendar.service';

@Component({
  selector: 'app-scheduling-setting',
  templateUrl: './scheduling-setting.component.html',
  styleUrls: ['./scheduling-setting.component.scss']
})
export class SchedulingSettingComponent implements OnInit {

  scheduleSettings: any;
  settingsForm: FormGroup;

  lesson_duration_options = [
    45,
    50,
    55,
    60
  ]

  constructor(
    private _calendarService : CalendarService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public _matDialogRef: MatDialogRef<SchedulingSettingComponent>,
    private _errorService: ErreurService
  ) {
    _matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.fetchDate()
  }

  fetchDate(){
    this._calendarService.getScheduleSettings().subscribe({
      next:(res)=>{
        if(res.status == 'fail') return
        this.scheduleSettings = res;
        this.buildForm(res)
      },
      error:(error)=>{}
    })
  }

  buildForm(settings){
    this.settingsForm = this.fb.group({
      less_cond_duration: [settings.less_cond_duration, Validators.required],
      less_code_duration: [settings.less_code_duration, Validators.required],
      exam_code_duration: [settings.exam_code_duration, [Validators.required,Validators.min(30),Validators.max(60)]],
      exam_cond_duration: [settings.exam_cond_duration, [Validators.required,Validators.min(30),Validators.max(60)]],
      agency_id: settings.agency_id,
    });
  }

  onSubmit(){
    const scheduleSettings = this.settingsForm.getRawValue()
    console.log('scheduleSettings',scheduleSettings);
    this._calendarService.updateScheduleSettings(scheduleSettings)
    .subscribe({
      next: res=>{
        this.snackBar.openFromComponent(SuccesSnakBarComponent, {
          duration: 2000,
        });
        this._matDialogRef.close(scheduleSettings)
      },
      error: err=>{
        console.log('err',err);
        this.settingsForm.markAsPristine()
        this._errorService.popError(err,'Un erreur s\'est produit ! Essayer de nouveau.')
      }
    })
  }

}
