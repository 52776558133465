import { FuseNavigation } from "@fuse/types";

export const navigationmoniteur: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dash",
                title: "Acceuil",
                arabic: "(الاستقبال)",
                type: "item",
                icon: "dashboard",
                url: "/dashboard/analytics"
            },
            {
                id: "candidats",
                title: "Candidats",
                arabic: "(المترشحون)",
                type: "item",
                icon: "person",
                url: "/candidats"
            },
            {
                id: "finance",
                title: "Finance",
                type: "collapsable",
                arabic: "(الحسابات)",
                icon: "attach_money",
                children: [
                    {
                        id: "recettes",
                        title: "Recette",
                        arabic: "(المداخيل)",
                        type: "item",
                        url: "/finance/recettes"
                    },
                    {
                        id: "depenses",
                        arabic: "(المصاريف)",
                        title: "Dépenses",
                        type: "item",
                        url: "/finance/depenses/historique"
                    }
                ]
            },
            {
                id: "calendar",
                title: "Calendrier",
                arabic: "(جدول الأوقات)",
                type: "item",
                icon: "today",
                url: "/calendar"
            },
            {
                id: "cmt",
                title: "Mon Compte",
                type: "item",
                arabic: "(الحساب)",
                icon: "settings",
                url: "/administration/compte",
                exactMatch: true
            }
        ]
    }
];
