import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class UsefulService {
    uri = "/api/"; //'http://localhost:3000/api/' ;

    constructor(private http: HttpClient) {}

    getAccounts() {
        return this.http.get(`${this.uri}accounts`);
    }
}
