import { Component, Inject, OnInit } from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: "app-errordialog",
    templateUrl: "./errordialog.component.html",
    styleUrls: ["./errordialog.component.scss"]
})
export class ErrordialogComponent implements OnInit {
    public confirmMessage: string;
    balance_limit = false;

    constructor(public dialogRef: MatDialogRef<ErrordialogComponent>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data?: any,
        ) {

    }
    ngOnInit() {
       
        if(this.data?.error?.message == 'balance_limit') this.balance_limit = true;

        // this.confirmMessage = "Don't do it champ";
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    facturation(){
        this.dialogRef.close();
        this.router.navigate(['/administration/payment/fixed'])
    }
}
