<div id="type-dep-dialog"class="container">
    <div class="dialog-content-wrapper">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row
                fxFlex
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <span class="title dialog-title">Ajouter type dépense</span>
                <button
                    mat-icon-button
                    (click)="close()"
                    aria-label="Close dialog"
                >
                    <mat-icon
                        class="fail"
                        style="background: inherit !important;"
                        >close</mat-icon
                    >
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <form
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            class="addtypeDepense"
            [formGroup]="form"
        >
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Famillies</mat-label>
                <mat-select required formControlName="famille">
                    <ng-container *ngFor="let item of familles">
                        <mat-option [value]="item.val">
                            {{ item.label }}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="form.controls['famille'].hasError('required')"
                    >famille est obligatoire</mat-error
                >
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Type</mat-label>
                <input matInput required formControlName="label" />
                <mat-error *ngIf="form.controls['label'].hasError('required')"
                    >type est obligatoire</mat-error
                >
            </mat-form-field>
        </form>
        <div
            class="m-0"
            style="
                overflow-y: auto;
                overflow-x: visible;
                padding: 0 10px 0 24px;
                max-height: 200px;
            "
            fusePerfectScrollbar
            *ngIf="types.length > 0"
        >
            <div
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="start center"
                style="margin-right: 40px; margin-bottom: 5px;"
            >
                <strong fxFlex>
                    famille
                </strong>
                <strong fxFlex>type</strong>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngFor="let type of types">
                <div fxLayout="column" fxLayoutGap="5px">
                    <div
                        fxLayout="row"
                        fxLayoutGap="10px"
                        fxLayoutAlign="start center"
                    >
                        <strong fxFlex>
                            {{ familles[type.famille].label }}
                        </strong>
                        <strong fxFlex>{{ type.label }}</strong>
                        <button mat-icon-button (click)="delete(type.id)">
                            <svg
                                *ngIf="deletingId === type.id"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                style="
                                    margin: auto;
                                    background: rgba(0, 0, 0, 0);
                                    display: block;
                                "
                                width="24px"
                                height="24px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                            >
                                <circle
                                    cx="75"
                                    cy="50"
                                    fill="#68b3d8"
                                    r="4.81593"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.9166666666666666s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.9166666666666666s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="71.65063509461098"
                                    cy="62.5"
                                    fill="#68b3d8"
                                    r="4.51741"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.8333333333333334s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.8333333333333334s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="62.5"
                                    cy="71.65063509461096"
                                    fill="#68b3d8"
                                    r="3.85074"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.75s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.75s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="50"
                                    cy="75"
                                    fill="#68b3d8"
                                    r="3.18407"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.6666666666666666s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.6666666666666666s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="37.50000000000001"
                                    cy="71.65063509461098"
                                    fill="#68b3d8"
                                    r="3"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.5833333333333334s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.5833333333333334s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="28.34936490538903"
                                    cy="62.5"
                                    fill="#68b3d8"
                                    r="3"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.5s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.5s"
                                    ></animate>
                                </circle>
                                <circle cx="25" cy="50" fill="#68b3d8" r="3">
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.4166666666666667s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.4166666666666667s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="28.34936490538903"
                                    cy="37.50000000000001"
                                    fill="#68b3d8"
                                    r="3"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.3333333333333333s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.3333333333333333s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="37.499999999999986"
                                    cy="28.349364905389038"
                                    fill="#68b3d8"
                                    r="3"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.25s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.25s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="49.99999999999999"
                                    cy="25"
                                    fill="#68b3d8"
                                    r="3"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.16666666666666666s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.16666666666666666s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="62.5"
                                    cy="28.349364905389034"
                                    fill="#68b3d8"
                                    r="3.48259"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="-0.08333333333333333s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="-0.08333333333333333s"
                                    ></animate>
                                </circle>
                                <circle
                                    cx="71.65063509461096"
                                    cy="37.499999999999986"
                                    fill="#68b3d8"
                                    r="4.14926"
                                >
                                    <animate
                                        attributeName="r"
                                        values="3;3;5;3;3"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        begin="0s"
                                    ></animate>
                                    <animate
                                        attributeName="fill"
                                        values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                        repeatCount="indefinite"
                                        times="0;0.1;0.2;0.3;1"
                                        dur="1s"
                                        begin="0s"
                                    ></animate>
                                </circle>
                            </svg>
                            <mat-icon *ngIf="deletingId !== type.id"
                                >delete</mat-icon
                            >
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
        </div>
        <div class="w-100-p">
            <div
                class="m-0 p-16"
                fxLayout="row"
                fxLayoutAlign="end center"
                fxLayoutGap="10px"
            >
                <button
                    mat-stroked-button
                    (click)="close()"
                    class="save-button"
                    aria-label="SAVE"
                >
                    Annuler
                </button>
                <button
                    mat-raised-button
                    (click)="addType()"
                    class="save-button mat-accent"
                    aria-label="SAVE"
                    [disabled]="form.invalid || addingType"
                >
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <svg
                            *ngIf="addingType"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            style="
                                margin: auto;
                                background: rgba(0, 0, 0, 0);
                                display: block;
                            "
                            width="36px"
                            height="36px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                        >
                            <circle cx="75" cy="50" fill="#68b3d8" r="4.81593">
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.9166666666666666s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.9166666666666666s"
                                ></animate>
                            </circle>
                            <circle
                                cx="71.65063509461098"
                                cy="62.5"
                                fill="#68b3d8"
                                r="4.51741"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.8333333333333334s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.8333333333333334s"
                                ></animate>
                            </circle>
                            <circle
                                cx="62.5"
                                cy="71.65063509461096"
                                fill="#68b3d8"
                                r="3.85074"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.75s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.75s"
                                ></animate>
                            </circle>
                            <circle cx="50" cy="75" fill="#68b3d8" r="3.18407">
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.6666666666666666s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.6666666666666666s"
                                ></animate>
                            </circle>
                            <circle
                                cx="37.50000000000001"
                                cy="71.65063509461098"
                                fill="#68b3d8"
                                r="3"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.5833333333333334s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.5833333333333334s"
                                ></animate>
                            </circle>
                            <circle
                                cx="28.34936490538903"
                                cy="62.5"
                                fill="#68b3d8"
                                r="3"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.5s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.5s"
                                ></animate>
                            </circle>
                            <circle cx="25" cy="50" fill="#68b3d8" r="3">
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.4166666666666667s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.4166666666666667s"
                                ></animate>
                            </circle>
                            <circle
                                cx="28.34936490538903"
                                cy="37.50000000000001"
                                fill="#68b3d8"
                                r="3"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.3333333333333333s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.3333333333333333s"
                                ></animate>
                            </circle>
                            <circle
                                cx="37.499999999999986"
                                cy="28.349364905389038"
                                fill="#68b3d8"
                                r="3"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.25s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.25s"
                                ></animate>
                            </circle>
                            <circle
                                cx="49.99999999999999"
                                cy="25"
                                fill="#68b3d8"
                                r="3"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.16666666666666666s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.16666666666666666s"
                                ></animate>
                            </circle>
                            <circle
                                cx="62.5"
                                cy="28.349364905389034"
                                fill="#68b3d8"
                                r="3.48259"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="-0.08333333333333333s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="-0.08333333333333333s"
                                ></animate>
                            </circle>
                            <circle
                                cx="71.65063509461096"
                                cy="37.499999999999986"
                                fill="#68b3d8"
                                r="4.14926"
                            >
                                <animate
                                    attributeName="r"
                                    values="3;3;5;3;3"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    begin="0s"
                                ></animate>
                                <animate
                                    attributeName="fill"
                                    values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite"
                                    times="0;0.1;0.2;0.3;1"
                                    dur="1s"
                                    begin="0s"
                                ></animate>
                            </circle>
                        </svg>
                        <span fxFlex>Ajouter</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
