<div class="dialog-content-wrapper">
    <iframe
        class="pc"
        allow="autoplay"
        ngClass.xs="phone"
        [src]="url"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
    >
    </iframe>
    <div
        *ngIf="_data.HelpUrl"
        fxLayout="row"
        fxLayoutAlign="end center"
        style="background: white; padding: 10px;"
    >
        <a [routerLink]="_data.HelpUrl" (click)="close()">إضغط هنا </a>
        <strong>،لأكثر تفسير</strong>
    </div>
</div>
