import { GlobalService } from "app/global.service";
import { AuthentificationService } from "./authentification.service";
import { Injectable } from "@angular/core";
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Observable, of, switchMap } from "rxjs";
import { ofType } from "@ngrx/effects";

const blocked_statuses = [
    "blocked",
    "blocked_inactive",
    "suspended",
    "incomplete_expired",
]

const warning_statuses = [
    "limited",
    "warning",
    "grace",

]

const allowed_statuses = [
    "settled",
    "trialing",
    "incomplete",
]
                

@Injectable({
    providedIn: "root"
})
export class AuthentificationGuard implements CanActivate {
    constructor(
        private globalService: GlobalService,
        private service: AuthentificationService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | boolean {
        let url = window.location.pathname;
        console.log('URL',state.url)

        return this.service.check()
        .pipe(
            switchMap((authenticated)=>{
                console.log('authenticated',authenticated);
                if(!authenticated ){
                    this.service.logOut();
                    return of(false)
                }
                else{
                    let status = this.globalService.getUser().status;
                    console.log('status',status,state);
                    switch (status) {
                        case "limited":
                        case "warning":
                        case "grace":
                            if(state.url == '/auth/blocked') {
                                this.router.navigate(["/dashboards/analytics"]);
                                return of(false)
                            }

                            // Create the login to display the warning 
                            //or do what we need to do. 
                            return of(true);
                            break;
                        case "blocked":
                        case "blocked_inactive":
                        case "suspended":
                        case "incomplete_expired":
                        case "incomplete":
                            if(state.url != '/administration/contract' &&
                               state.url != '/auth/blocked' &&
                               state.url != '/administration/payment-choice' &&
                               !state.url.includes('/administration/payment/') 
                               ){
                                this.router.navigate(["/auth/blocked"]);
                                return of(false);
                            } 
                            else return of(true)
                            break;

                        case "trialing":
                        case "settled":

                            if(state.url == '/auth/blocked') {
                                this.router.navigate(["/dashboards/analytics"]);
                                return of(false)
                            }
                            return of(true)
                            break;
                        default:
                            return of(true)
                            break;
                    }
                }
                return of(true)
            }
            ))
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
       
        let url = window.location.pathname;
        console.log('URL',state.url)
        return this.service.check()
        .pipe(
            switchMap((authenticated)=>{
                if(!authenticated ){
                    this.service.logOut();
                    return of(false)
                }
                else{
                    let status = this.globalService.getUser().status;
                    switch (status) {
                        case "limited":
                        case "warning":
                        case "grace":
                            if (state.url == "/auth/blocked") {
                                this.router.navigate(["/dashboards/analytics"]);
                                return of(false);
                            }

                            // Create the login to displayt the worning
                            //or do what we need to do.
                            return of(true);
                            break;
                        case "blocked":
                        case "blocked_inactive":
                        case "suspended":
                        case "incomplete_expired":
                            if (state.url != '/administration/contract' &&
                            state.url != '/auth/blocked' &&
                            state.url != '/administration/payment-choice' &&
                            !state.url.includes('/administration/payment') 
                            ) {
                                this.router.navigate(["/auth/blocked"]);
                                return of(false);
                            } else return of(true);
                            break;

                        case "trialing":
                        case "settled":
                        case "incomplete":
                            if (state.url == "/auth/blocked") {
                                this.router.navigate(["/dashboards/analytics"]);
                                return of(false);
                            }
                            return of(true);
                            break;
                        default:
                            return of(true);
                            break;
                    }
                }
                return of(true)
            }
            ))
        }

        /* if (
            this.service.loggedIn() &&
            this.globalService.getUser() &&
            this.globalService.getUser().status != 'blocked' &&
            typeof this.globalService.getUser().role !== undefined
        ) {

            // this.router.navigate(["/dashboards/analytics"]);
            return true;
        }
        else if (
            this.service.loggedIn() &&
            this.globalService.getUser() &&
            this.globalService.getUser().status == 'blocked' &&
            state.url != '/administration/contract' 

        ) {
           // return this.router.parseUrl('____')
            this.router.navigate(["/auth/blocked"]);
            return false;
        } else if(
            this.service.loggedIn() &&
            this.globalService.getUser() &&
            this.globalService.getUser().status == 'blocked' &&
            state.url == '/administration/contract'
        ){
            
            return true;
        }
        else{
            this.service.logOut();
            return false;
        } */


    
}
