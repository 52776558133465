import { ErreurService } from "./../../../authentification/erreur.service";
import { Component, OnInit, Inject } from "@angular/core";
import { CandidatService } from "../../candidats/candidat/candidat.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SuccesSnakBarComponent } from "../../../components/succes-snak-bar/succes-snak-bar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ExamConduiteResultService } from "./exam-conduite-result.service";
@Component({
    selector: "app-exam-conduite-result",
    templateUrl: "./exam-conduite-result.component.html",
    styleUrls: ["./exam-conduite-result.component.scss"],
})
export class ExamConduiteResultComponent implements OnInit {
    reussite = true;
    candidatId: number;
    echecA = null;
    invalid = false;
    newFormat : boolean;
    jourJ = "01/01/2023"
    constructor(
        private _examConduiteResultService : ExamConduiteResultService,
        private errService: ErreurService,
        private dialog: MatDialog,
        public _matDialogRef: MatDialogRef<ExamConduiteResultComponent>,
        public candService: CandidatService,
        private snackBar: MatSnackBar,
        //private calService: CalendarService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        console.log('DATA :',this._data)
        console.log('DATA :',new Date(this.jourJ) , new Date(this._data.date))
        this.candidatId = _data.candidatId;
    }
    register() {
        //! if echec
        if (!this.reussite) {
            //&& this.nouvelleTentative) {
            if(!this.echecA && this._data.cond_type == 'parc') this.echecA = 'parc' 
            else if(!this.echecA && this._data.cond_type != 'parc') this.echecA = 'circulation' 
            this.candService
                .echecCondNouv(this.candidatId, this.echecA,this._data.cond_type)
                .subscribe({
                    next:(res) => {
                        if (res !== null) {
                            this._matDialogRef.close({
                                success: false,
                                echecA: this.echecA,
                            }); //
                            this.snackBar.openFromComponent(
                                SuccesSnakBarComponent,
                                {
                                    duration: 3000,
                                }
                            );
                        }
                    },
                    error:(err) => {
                        this.errService.popError(
                            err,
                            "Erreur s'est produit lors d'ajout. \n Essayez plus tard"
                        );
                    }
                });
        } 
        //! if success with new format call succ v2
        else if(this.newFormat) {
            this.echecA = null;
            this.candService.succCondV2(this.candidatId,this._data.cond_type).subscribe({
                next:(res) => {
                    if (res !== null) {
                        this._matDialogRef.close({ success: true }); //!We can ask him wether he want's to end the contract
                        this.snackBar.openFromComponent(
                            SuccesSnakBarComponent,
                            {
                                duration: 3000,
                            }
                        );
                    }
                },
                error:(err) => {
                    this.errService.popError(
                        err,
                        "Erreur s'est produit lors d'ajout. \n Essayez plus tard"
                    );
                }
            });
        }
        else {
            this.echecA = null;
            this.candService.succCond(this.candidatId).subscribe({
                next:(res) => {
                    if (res !== null) {
                        this._matDialogRef.close({ success: true }); //!We can ask him wether he want's to end the contract
                        this.snackBar.openFromComponent(
                            SuccesSnakBarComponent,
                            {
                                duration: 3000,
                            }
                        );
                    }
                },
                error:(err) => {
                    this.errService.popError(
                        err,
                        "Erreur s'est produit lors d'ajout. \n Essayez plus tard"
                    );
                }
            });
        }
    }

    ngOnInit() {
        //?push fake state to navigate to when button back is clicked
        const modalState = {
            modal : true,
            desc : 'fake state for our modal'
        };
        history.pushState(modalState, null);

        this._examConduiteResultService.getCandCategory(this._data.candidatId)
        .subscribe({
            next:category=>{
                this.newFormat =  (new Date(this.jourJ) <= new Date(this._data.date)) && (category == 'B' || category == 'D1') 
            },
            error: ()=>{}
        })
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            history.go(-1);
        }
    }
}
