import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { VideoComponent } from "./layout/components/video/video.component";
import { PaymentCDialogComponent } from "./main/plus/components/payment-cdialog/payment-cdialog.component";
import { DepenceFormComponent } from "./main/plus/finance/depences/depence-form/depence-form.component";
import { SetLocationComponent } from "./main/pop-ups/set-location/set-location.component";
import { ErrordialogComponent } from "./main/components/errordialog/errordialog.component";
import { CashoutDailogComponent } from "./main/components/cashout/cashout-dailog/cashout-dailog.component";
import { ChangermotpasseComponent } from "./main/plus/components/changermotpasse/changermotpasse.component";
import { AccountDialogComponent } from "./main/plus/administration/account-dialog/account-dialog.component";
import { SchedulingSettingComponent } from "./main/pop-ups/scheduling-setting/scheduling-setting.component";
import { MobileComponent } from "./main/plus/calendar/mobile/mobile.component";
import { ExamConduiteResultComponent } from "./main/plus/calendar/exam-conduite-result/exam-conduite-result.component";
import { ExamResultComponent } from "./main/plus/calendar/exam-result/exam-result.component";
import { PlanningPopupComponent } from "./main/pop-ups/planning-popup/planning-popup.component";
import { EventChoiceComponent } from "./main/plus/calendar/event-choice/event-choice.component";
import { EventFormComponent } from "./main/plus/calendar/event-form/event-form.component";
import { ImagePopupComponent } from "./main/pop-ups/image-popup/image-popup.component";
import { HistoryPopupComponent } from "./main/pop-ups/history-popup/history-popup.component";
import { PaiementPopupComponent } from "./main/pop-ups/paiement-popup/paiement-popup.component";
import { CandidatAddWarningComponent } from "./main/plus/candidats/candidat-add-warning/candidat-add-warning.component";
import { CsvDownloadPopupComponent } from "./main/pop-ups/csv-download-popup/csv-download-popup.component";
import { TypesPopupComponent } from "./main/plus/finance/depences/types-popup/types-popup.component";
import { CodeOnlineTrainingFormComponent } from "./main/plus/online-services/code-online-training-form/code-online-training-form.component";
import { RessourceFormComponent } from "./main/plus/ressources/ressource-form/ressource-form.component";
import { VehiculeComponent } from "./main/plus/ressources/vehicule/vehicule.component";
import { ImageComponent } from "./main/plus/result/image/image.component";
import { ExplanationComponent } from "./main/plus/result/explanation/explanation.component";


export const dialogComponentsMap = {
  'dialog-set-location': {
    component: SetLocationComponent,
    title: 'Localisation Pop-up | autoecoleplus.tn'
  },
  'dialog-payment': {
    component: PaymentCDialogComponent,
    title: 'Paiement Pop-up | autoecoleplus.tn'
  },
  'dialog-depence-from': {
    component: DepenceFormComponent,
    title: 'Dépense Pop-up | autoecoleplus.tn'
  },
  'dialog-video': {
    component: VideoComponent,
    title: 'Vidéo Pop-up | autoecoleplus.tn'
  },
  'dialog-error': {
    component: ErrordialogComponent,
    title: 'Erreur Pop-up | autoecoleplus.tn'
  },
  'dialog-cashout': {
    component: CashoutDailogComponent,
    title: 'Retrait Pop-up | autoecoleplus.tn'
  },
  'dialog-change-password': {
    component: ChangermotpasseComponent,
    title: 'Changement Mot de Passe Pop-up | autoecoleplus.tn'
  },
  'dialog-account-dialog': {
    component: AccountDialogComponent,
    title: 'Compte Pop-up | autoecoleplus.tn'
  },
  'dialog-scheduling-setting': {
    component: SchedulingSettingComponent,
    title: 'Paramètres Calendrier Pop-up | autoecoleplus.tn'
  },
  'dialog-mobile-component': {
    component: MobileComponent,
    title: 'Mobile | autoecoleplus.tn'
  },
  'dialog-exam-conduite-result': {
    component: ExamConduiteResultComponent,
    title: 'Résultat d\'Examen Conduite Pop-up | autoecoleplus.tn'
  },
  'dialog-exam-result': {
    component: ExamResultComponent,
    title: 'Résultat d\'Examen Pop-up | autoecoleplus.tn'
  },
  'dialog-planning-popup': {
    component: PlanningPopupComponent,
    title: 'Impression du Planning Pop-up | autoecoleplus.tn'
  },
  'dialog-event-choice': {
    component: EventChoiceComponent,
    title: 'Choix d\'Événement Pop-up | autoecoleplus.tn'
  },
  'dialog-event-form': {
    component: EventFormComponent,
    title: 'Formulaire d\'événement Pop-up | autoecoleplus.tn'
  },
  'dialog-image-popup': {
    component: ImagePopupComponent,
    title: 'Image Pop-up | autoecoleplus.tn'
  },
  'dialog-history-popup': {
    component: HistoryPopupComponent,
    title: 'Impression Historique Pop-up | autoecoleplus.tn'
  },
  'dialog-payment-popup': {
    component: PaiementPopupComponent,
    title: 'Paiement Pop-up | autoecoleplus.tn'
  },
  'dialog-candidat-popup': {
    component: CandidatAddWarningComponent,
    title: 'Avertissement Candidat Pop-up | autoecoleplus.tn'
  },
  'dialog-csv-download': {
    component: CsvDownloadPopupComponent,
    title: 'Téléchargement CSV Pop-up | autoecoleplus.tn'
  },
  'dialog-types-popup': {
    component: TypesPopupComponent,
    title: 'Types Dépense Pop-up | autoecoleplus.tn'
  },
  'dialog-code-online-training': {
    component: CodeOnlineTrainingFormComponent,
    title: 'Formation Code En Ligne Pop-up | autoecoleplus.tn'
  },
  'dialog-ressource-form': {
    component: RessourceFormComponent,
    title: 'Ressource Pop-up | autoecoleplus.tn'
  },
  'dialog-vehicule': {
    component: VehiculeComponent,
    title: 'Véhicule Pop-up | autoecoleplus.tn'
  },
  'dialog-image': {
    component: ImageComponent,
    title: 'Image Pop-up | autoecoleplus.tn'
  },
  'dialog-explanation': {
    component: ExplanationComponent,
    title: 'Explication Pop-up | autoecoleplus.tn'
  }
};