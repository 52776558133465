import { HttpClient } from "@angular/common/http";
import { FuseNavigation } from "./../../../../@fuse/types/fuse-navigation";
import { navigation } from "./../../../navigation/navigation";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class NavbarService {
    items: FuseNavigation[] = navigation[0].children;
    constructor(private http: HttpClient) {}
    deleteBadge(itemId) {
        delete this.items.find((value) => value.id === itemId)["badge"];
    }

    setBadgeValue(itemId, badge: { title?: string; bg?: string; fg?: string }) {
        let x = parseInt(badge.title);
        if (!isNaN(x) && x > 0) {
            this.items.find((value) => value.id === itemId)["badge"] = badge;
        } else {
            this.deleteBadge(itemId);
        }
    }

    deleteChildBadge(itemId, childId) {
        delete this.items
            .find((value) => value.id === itemId)
            .children.find((value) => value.id === childId)["badge"];
    }

    setChildBadgeValue(
        itemId,
        childId,
        badge: { title?: string; bg?: string; fg?: string }
    ) {
        let x = parseInt(badge.title);
        if (!isNaN(x) && x > 0) {
            this.items
                .find((value) => value.id === itemId)
                .children.find((value) => value.id === childId)[
                "badge"
            ] = badge;
        } else {
            this.deleteChildBadge(itemId, childId);
        }
    }

    getBadgeCount(itemId) {
        let x = parseInt(
            this.items.find((value) => value.id === itemId)["badge"].title
        );
        return isNaN(x) ? 0 : x;
    }

    getChildBadgeCount(itemId, childId) {
        let x = parseInt(
            this.items
                .find((value) => value.id === itemId)
                .children.find((value) => value.id === childId)["badge"].title
        );
        return isNaN(x) ? 0 : x;
    }

    getQuestionCount() {
        return this.http.get<any>(
            `${environment.uriG}/api/candidatcode/notanswerdquestions`
        );
    }
}
