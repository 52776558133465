import { CandidatService } from "./../../plus/candidats/candidat/candidat.service";
import { CalendarService } from "./../../plus/calendar/calendar.service";
import { PrintService } from "./../../../layout/vertical/layout-1/print.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
    selector: "app-history-popup",
    templateUrl: "./history-popup.component.html",
    styleUrls: ["./history-popup.component.scss"],
})
export class HistoryPopupComponent implements OnInit {
    result = {
        code: true,
        exam: true,
        conduit: true,
        service: true,
        paiement: true,
        service_online: true,
    };

    constructor(
        public dialogRef: MatDialogRef<HistoryPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _printService: PrintService,
        private _calendarService: CalendarService,
        private _CandidatService: CandidatService
    ) {
        this.dialogRef.disableClose = true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClickPrint(): void {
        this._calendarService
            .getCandidatEvents(this.data.candidatId)
            .subscribe((data) => {
                const code = [];
                const conduit = [];
                const exam = [];
                const service_online = [];
                const services = {
                    inscription: 0,
                    code: {
                        nbHeures: 0,
                        prixHeure: 0,
                        total: 0,
                    },
                    conduit: {
                        nbHeures: 0,
                        prixHeure: 0,
                        total: 0,
                    },
                    renouv: {
                        nb: 0,
                        prixUnite: 0,
                        total: 0,
                    },
                    exam: {
                        nb: 0,
                        prixUnite: 0,
                        total: 0,
                    },
                    servicePurchases: [],
                    total: {
                        total: 0,
                        paye: 0,
                        reste: 0,
                    },
                };
                (data as any[]).forEach((element) => {
                    switch (element.type) {
                        case "conduiteLess":
                            conduit.push(element);
                            break;
                        case "codeEx":
                            exam.push(element);
                            services.renouv.nb += 1;
                            break;
                        case "codeLess":
                            code.push(element);
                            break;
                        case "conduiteEx":
                            exam.push(element);
                            services.renouv.nb += 1;
                            services.exam.nb += 1;
                            services.exam.total += element.frai;
                            break;
                    }
                });

                services.renouv.nb =
                    services.renouv.nb > 0 ? services.renouv.nb - 1 : 0;
                if (services.exam.nb > 0)
                    services.exam.prixUnite =
                        services.exam.total / services.exam.nb;

                this._CandidatService
                    .getPayments(this.data.candidatId)
                    .subscribe((data) => {
                        let paiement = [];
                        (data as any[]).forEach((element) => {
                            paiement.push({
                                date: element.date,
                                montant: element.montant,
                                type: element.type,
                                paymentId: element.id,
                            });
                            services.total.paye += element.montant;
                        });
                        console.log("paiement ->", data)

                        this._CandidatService
                            .getOneCandidat(this.data.candidatId)
                            .subscribe((data) => {
                                
                                
                                services.inscription = data.fraisInscription;

                                services.renouv.total =
                                    data.totRenouvCond + data.totFraisCode;
                                if (services.renouv.nb > 0)
                                    services.renouv.prixUnite =
                                        services.renouv.total /
                                        services.renouv.nb;

                                services.code.total = data.totCo;
                                services.code.prixHeure = data.priHeuCo;
                                if (services.code.prixHeure > 0)
                                    services.code.nbHeures =
                                        services.code.total /
                                        services.code.prixHeure;

                                services.conduit.total = data.totCon;
                                services.conduit.prixHeure = data.priHeuCon;
                                if (services.conduit.prixHeure > 0)
                                    services.conduit.nbHeures =
                                        services.conduit.total /
                                        services.conduit.prixHeure;

                                services.total.total =
                                    services.inscription +
                                    services.code.total +
                                    services.conduit.total +
                                    services.exam.total +
                                    services.renouv.total;
                                services.total.reste =
                                    services.total.total - services.total.paye;
                                this._CandidatService.getServicePurchasesCandidat(this.data.candidatId).subscribe(
                                    (data) => {
                                        const service_purchase = data.map(sp => {
                                            const payment = paiement.find(p => p.id === sp.paymentId);
                                            console.log(payment);
                                            if (payment) {
                                              return {
                                                date: payment.date,
                                                type: sp.label,
                                                montant: payment.montant
                                              };
                                            }
                                            return null;
                                          }).filter(item => item !== null);
                                          console.log(service_purchase);
                                          services.servicePurchases = service_purchase;
                                          this._CandidatService
                                                .getCandidatPrintInfo(this.data.candidatId)
                                                .subscribe((data) => {
                                                    this.dialogRef.close({});
                                                    this._printService.print({
                                                        services: services,
                                                        info: data,
                                                        paiement: paiement,
                                                        code: code,
                                                        conduit: conduit,
                                                        exam: exam,
                                                        show: this.result,
                                                    });
                                    });
                                    });
                                
                            });
                    });
            });
    }
    onYesClickExport(): void {
        this._calendarService
            .getCandidatEvents(this.data.candidatId)
            .subscribe((data) => {
                let code = [];
                let conduit = [];
                let exam = [];
                let services = {
                    inscription: 0,
                    code: {
                        nbHeures: 0,
                        prixHeure: 0,
                        total: 0,
                    },
                    conduit: {
                        nbHeures: 0,
                        prixHeure: 0,
                        total: 0,
                    },
                    renouv: {
                        nb: 0,
                        prixUnite: 0,
                        total: 0,
                    },
                    exam: {
                        nb: 0,
                        prixUnite: 0,
                        total: 0,
                    },
                    total: {
                        total: 0,
                        paye: 0,
                        reste: 0,
                    },
                };
                (data as any[]).forEach((element) => {
                    switch (element.type) {
                        case "conduiteLess":
                            conduit.push(element);
                            break;
                        case "codeEx":
                            exam.push(element);
                            services.renouv.nb += 1;
                            break;
                        case "codeLess":
                            code.push(element);
                            break;
                        case "conduiteEx":
                            exam.push(element);
                            services.renouv.nb += 1;
                            services.exam.nb += 1;
                            services.exam.total += element.frai;
                            break;
                    }
                });

                services.renouv.nb =
                    services.renouv.nb > 0 ? services.renouv.nb - 1 : 0;
                if (services.exam.nb > 0)
                    services.exam.prixUnite =
                        services.exam.total / services.exam.nb;

                this._CandidatService
                    .getPayments(this.data.candidatId)
                    .subscribe((data) => {
                        let paiement = [];
                        (data as any[]).forEach((element) => {
                            paiement.push({
                                date: element.date,
                                montant: element.montant,
                                type: element.type,
                            });
                            services.total.paye += element.montant;
                        });
                        this._CandidatService
                            .getOneCandidat(this.data.candidatId)
                            .subscribe((data) => {
                                services.inscription = data.fraisInscription;

                                services.renouv.total =
                                    data.totRenouvCond + data.totFraisCode;
                                if (services.renouv.nb > 0)
                                    services.renouv.prixUnite =
                                        services.renouv.total /
                                        services.renouv.nb;

                                services.code.total = data.totCo;
                                services.code.prixHeure = data.priHeuCo;
                                if (services.code.prixHeure > 0)
                                    services.code.nbHeures =
                                        services.code.total /
                                        services.code.prixHeure;

                                services.conduit.total = data.totCon;
                                services.conduit.prixHeure = data.priHeuCon;
                                if (services.conduit.prixHeure > 0)
                                    services.conduit.nbHeures =
                                        services.conduit.total /
                                        services.conduit.prixHeure;

                                services.total.total =
                                    services.inscription +
                                    services.code.total +
                                    services.conduit.total +
                                    services.exam.total +
                                    services.renouv.total;
                                services.total.reste =
                                    services.total.total - services.total.paye;

                                    
                                this._CandidatService
                                    .getCandidatPrintInfo(this.data.candidatId)
                                    .subscribe((data) => {
                                        this.dialogRef.close({});
                                        this._printService.print({
                                            services: services,
                                            info: data,
                                            paiement: paiement,
                                            code: code,
                                            conduit: conduit,
                                            exam: exam,
                                            show: this.result,
                                        });
                                    });
                            });
                    });
            });
    }

    ngOnInit() { }
}
