<div id="exam-conduite-result" class="container" >
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row
        class="w-100-p"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <span class="title dialog-title">Resultat d'Examen </span>
            <button
            mat-icon-button
                (click)="_matDialogRef.close()"
                aria-label="Close dialog"
            >
                <mat-icon
                    class="fail "
                    style="background: inherit !important;"
                    >close</mat-icon
                >
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="px-24 pt-20 pb-0"> 
            <div  >
        <span>Resultat : </span>

        <section class="example-section container">

            <mat-radio-group [(ngModel)]="reussite">

                <mat-radio-button class='container' class="example-margin" [value]="true">
                    <div class='align mt-8 mb-8'>
                        <mat-icon style="color: green">check_circle</mat-icon> Réussite
                    </div>
                </mat-radio-button>

                <mat-radio-button class='container' class="example-margin" [value]="false">
                    <div class='align mt-8 mb-8'>
                        <mat-icon class='fail'>close</mat-icon> Echec
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </section>
    </div>
    <div *ngIf="!reussite && !newFormat">
        <span>Échec:</span>
        <section class="example-section container">
            <mat-radio-group [(ngModel)]="echecA">
                <mat-radio-button class='container' class="example-margin" [value]="'circulation'">
                    Cirulation
                </mat-radio-button>
                <mat-radio-button class='container' class="example-margin" [value]="'parc'">
                    Manoeuvre
                </mat-radio-button>
            </mat-radio-group>
        </section>
    </div>

</div>


    <div fxLayoutAlign="end center" fxLayout="row" class="w-100-p p-16" fxLayoutGap="4px">
        <button mat-stroked-button (click)="_matDialogRef.close()">Annuler</button>
        <button mat-flat-button color="primary" color="accent" class="mat-accent" [disabled]="echecA == null && !reussite && !newFormat "
            (click)="register()">Enregistrer</button>

    </div>

</div>