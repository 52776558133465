<div id="exam-notification-dialog" class="container">


    <!-- NO DATA -->
        <mat-toolbar class="toolbar m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title" ngClass.xs="titlefontxs">
                    Nouvelles listes
                </span>
                <div>
                    <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="content" 
        style="overflow-y: auto; overflow-x: visible"
            fusePerfectScrollbar>
            <ng-container *ngIf="notifications$ | async as notifs">
                &nbsp;

                <button style="padding-top: 8px; transform: scale(1.5);" mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon [matBadge]="notifs.length" matBadgeColor="accent" class="notification shake">notifications_on</mat-icon>
                </button>

                <div class="text">
                    <div class="notifcations-content">
                        <div *ngFor="let notification of notifs">
                            <div class="head">
                                <span>
                                        {{notification.center_name}} {{notification.exam_type}}-{{notification.category}} 
                                </span>
                                <div>
                                    <span>
                                        &nbsp; {{" "+notification.date}}
                                    </span>
                                    <span *ngFor="let hour of notification.hours;let first=first">
                                        <ng-container *ngIf="!first"> et </ng-container>
                                        {{hour}}h
                                    </span>
                                </div>
                            </div>
                            <div class="candidates" fxLayout="column">
                                <mat-chip color="accent" class="alert-chip"  *ngIf="notification.last_state == 'closed'">
                                    <span >Plus de place sur cette liste</span>
                                </mat-chip>

                                <span *ngFor="let candidat of notification.candidates">
                                    {{candidat.fullname}}
                                </span>
                                <span *ngIf="notification?.agency_exam_list?.note">
                                    Note: {{notification?.agency_exam_list?.note}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
        </div>

        <mat-divider></mat-divider>
        <div class="action-container" dir="rtl">
<!--     
            <button mat-flat-button class="mat-accent attt-button exams-center-button" 
            (click)="goToCenterDeExamsList()">
                Mes Centres d'examens
            </button> -->

            <a href="https://www.attt.com.tn/" target="_blank" type="submit" mat-flat-button class="mat-accent attt-button" 
            (click)="true">
                ATTT.com.tn
            </a>
            
            
            <button mat-stroked-button 
                (click)="goToCenterSettings()">
                Mes Centres d'examens
            </button>
        </div>
        
</div>