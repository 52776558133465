import { types } from "./../administration/agency/agency.component";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { filter, map } from "rxjs";
import { merge, forkJoin, of } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class EventLoadingService {
    uri = environment.uriG + ""; //"http://localhost:3000";
    constructor(
        private http: HttpClient
        ) { }


    getEvent(id) {
        return this.http.get<any>(`${this.uri}/api/calendar/one/${id}`);
    }

    getCandidatCodeEx() {
        return this.http.get<any[]>(`${this.uri}/api/candidats/min`).pipe(
            map((elems) => {
                let arr = [];
                //? arr array qui contient les candidat allégible pour planifier un ex code
                for (let i = 0; i < elems.length; i++) {
                    if (elems[i].phase === "code" && !elems[i].dateExCo) {
                        elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
                        arr.push(elems[i]);
                    }
                }
                return arr;
            })
        );
    }

    getCandidatCodeLess() {
        return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
            map((elems) => {
                let arr = [];
                //? arr array qui contient les candidat allégible pour planifier un ex code
                for (let i = 0; i < elems.length; i++) {
                    if (elems[i].phase === "code") {
                        elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
                        arr.push(elems[i]);
                    }
                }
                return arr;
            })
        );
    }
    getCandidatConduiteEx() {
        return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
            map((elems) => {
                let arr = [];
                //? arr array qui contient les candidat allégible pour planifier un ex code
                for (let i = 0; i < elems.length; i++) {
                if (elems[i].phase === "conduite" 
                //! is not in phase circuit and already has exam
                && (((elems[i].cond_type == 'circuit' || !elems[i].cond_type) && !elems[i].dateExCon) 
                //! Parc and doesnt have date for parc
                    || (elems[i].cond_type == 'parc' && !elems[i].dateExParc)) ) {
                        elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
                        arr.push(elems[i]);
                    }
                }
                return arr;
            })
        );
    }

    getOneCandidat(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/${id}`).pipe(
            map((elem) => {
                if (elem.archived) {
                    elem.username = "Candidat supprimé";
                    return [elem];
                } else {
                    elem.username = elem.prenom + " " + elem.nom;
                    return [elem];
                }
            })
        );
    }

    getOneCandidatMin(id) {
        return this.http.get<any>(`${this.uri}/api/candidats/min/${id}`).pipe(
            map((elem) => {
                if (elem.archived) {
                    elem.username = "Candidat supprimé";
                    return [elem];
                } else {
                    elem.username = elem.prenom + " " + elem.nom;
                    return [elem];
                }
            })
        );
    }

    getCandidat() {
        return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
            map((elems) => {
                elems.forEach((elem) => {
                    elem.username = elem.prenom + " " + elem.nom;
                });
                return elems;
            })
        );
    }

    getVehicules() {
        return this.http.get<any>(`${this.uri}/api/vehicles`);
    }
    getCenters() {
        return this.http.get<any>(`${this.uri}/api/agences/centers`);
    }
    getCentersCode() {
        return this.http.get<any>(`${this.uri}/api/agences/centers/code`);
    }
    getCentersConduite() {
        return this.http.get<any>(`${this.uri}/api/agences/centers/conduite`);
    }
    getTarif() {
        return this.http.get<any>(`${this.uri}/api/agences/tarif`);
    }

    getAllActiveAgents(){
        return this.http.get<any>(`${this.uri}/api/employees/min`).pipe(
            map((elems) => {
                console.log('elems',elems);
                elems.forEach(
                    (elem) => (elem.username = elem.prenom + " " + elem.nom)
                );
                return elems.filter(elem => !elem.archived && elem.active);
            })
        );
    }

    getAgents() {
        return this.http.get<any>(`${this.uri}/api/employees/min`).pipe(
            map((elems) => {
                
                elems = elems.filter((elem) => {
                    return (
                        elem.role != "Agent bureau" 
                    ) /* {
                        let index = elems.indexOf(elem);
                        elems.splice(index, 1);
                    } */
                });

                /* console.log("Initial elems :", elems);
                elems.forEach(elem => {
                   // console.log("elem bef:", elem);
                    elem.username = elem.prenom + " " + elem.nom;
                   // console.log("elem after:", elem);
                    if (
                        elem.role !== "moniteur" &&
                        elem.role !== "proprietaire"
                    ) {
                        let index = elems.indexOf(elem);
                        elems.splice(index, 1);
                    }
                });*/
                return elems;
            }),
            map((elems) => {
                elems.forEach(
                    (elem) => (elem.username = elem.prenom + " " + elem.nom)
                );
                return elems;
            })
        );
    }

    /*     getAgents0Vehicles1Centers2(isExam) {
           // console.log(isExam, "hello")
            if (isExam)
                return this.getAgents0Vehicles1Centers2ConduiteEx()
            else
                return this.getAgents0Vehicles1Centers2ConduiteLess()
        } */

    //? used in edit exam conduite
    getAgents0Vehicles1Centers2ConduiteEx() {
        return forkJoin([
            this.getAgents(),
            this.getVehicules(),
            this.getCentersConduite(),
        ]);

    }

    //? used in edit less conduite
    getAgents0Vehicles1Centers2ConduiteLess() {
        return forkJoin([
            this.getAgents(),
            this.getVehicules(),
            this.getDefaultBlockedTime('exam_cond_duration')        
        ]);
    }

    //? used in plan examen conduite
    getAgents0Vehicles1Cands2Centers3Tarif4BlockedMinutes5ConduiteEx() {

        return forkJoin([
            this.getAgents(),
            this.getVehicules(),
            this.getCandidatConduiteEx(),
            this.getCentersConduite(),
            this.getTarif(),
            this.getDefaultBlockedTime('exam_cond_duration')        
        ]
        );

    }

    //? Used in plan Less Conduite
    getAgents0Vehicles1Cands2BlockedMinutes3ConduiteLess() {

        return forkJoin([
            this.getAgents(),
            this.getVehicules(),
            this.getCandidat(),
            this.getDefaultBlockedTime('less_cond_duration')        
        ]
        );

    }

    getDefaultBlockedTime(type) {
        return this.http.get<any>(`${this.uri}/api/calendar/schedule-settings`)
            .pipe(
                map(e=>{
                    return e[type] 
            }));
    }
}
