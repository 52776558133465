import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LocationService } from './location.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-set-location',
  templateUrl: './set-location.component.html',
  styleUrls: ['./set-location.component.scss'],
  animations: [
    trigger('componentTransition', [
      state('step1', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('step2', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      transition('step1 => step2', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition('step2 => step1', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class SetLocationComponent implements OnInit {
  locationForm: FormGroup;
  map = null;
  mapClickListener = null;


  errors= false;
  markers: marker = {
      lat: null,
      lng: null,
      label: null,
      draggable: null
  } ;

  agmMap: any;

  //? Coordinates in Tunisia
  latitude=35.312166;
  longitude=9.561555499999997;
  errorMessage;
  zoom = 7;
  disableSubmitButton = false;


  currentState = 1;
  isXsScreen = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialogRef: MatDialogRef<SetLocationComponent>,
    private fb: FormBuilder,
    private _ngZone: NgZone,
    private _locationService: LocationService,
    private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
          this.isXsScreen = result.matches;
        });
    this.markers.lat = data.lat;
    this.markers.lng = data.lng;

  

  }


  
  ngOnInit(): void {

    this.locationForm = this.fb.group({
      address_fr: ['', Validators.required],
      address_ar: ['', Validators.required],
      lng: ['', Validators.required],
      lat: ['', Validators.required]
    });
  }

  goToNext() {
    if (this.currentState === 1) {
      this.currentState = 2;
    }
  }

  goToPrevious() {
    if (this.currentState === 2) {
      this.currentState = 1;
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this._ngZone.run(() => {
        this.markers={
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            draggable: true
          };
        // Here we can get correct event
        this.locationForm.get('lat').setValue(e.latLng.lat());     
        this.locationForm.get('lng').setValue(e.latLng.lng())});

    });
  }

  
  getPosition(): Promise<{ lng: number, lat: number }> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser.'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({ lng: position.coords.longitude, lat: position.coords.latitude });
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                reject(new Error('Veuillez activer les services de localisation dans les paramètres de votre navigateur et réessayer, ou sélectionnez votre position manuellement.'));
                break;
              case error.POSITION_UNAVAILABLE:
                reject(new Error('Les informations de localisation sont indisponibles. Sélectionnez votre position manuellement.'));
                break;
              case error.TIMEOUT:
                reject(new Error('La demande de géolocalisation a expiré. Sélectionnez votre position manuellement.'));
                break;
              default:
                reject(new Error('Une erreur inconnue s\'est produite. Sélectionnez votre position manuellement.'));
                break;
            }
          }
        );
      }
    });
  }

  
  markerDragEnd(marker: any, $event: any) {
    console.log($event);
    const newLat = $event.latLng.lat();
    const newLng = $event.latLng.lng();
    this.markers={
      lat: newLat,
      lng: newLng,
      draggable: true
    };
    this.locationForm.get('lat').setValue(newLat);     
    this.locationForm.get('lng').setValue(newLng);

  }


  getLocation(): void {
    this.getPosition().then(position => {
      this.latitude = position.lat;
      this.longitude = position.lng;
      this.markers={
        lat: position.lat,
        lng: position.lng,
        draggable: true
      };
      this.errors = false;
      this.zoom = 15;
    }).catch(err => {
      this.errors = true;

      this.errorMessage = err.message;
    });
  }

  onCancel() {
    this._matDialogRef.close();
  }

  onSubmit(){
    this.disableSubmitButton = true;

      this._locationService.setAgenceLocation(this.locationForm.value).subscribe({
        next: data => {
          this.disableSubmitButton = false;

          if(data.msg == 'success') {
            this._matDialogRef.close(true);
          }
        },
        error: (err) => {
          console.log(err);
          this.disableSubmitButton = false;
        }
      });
     
  }


}


interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}