import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopNotificationService {
  defaultConfig = {
    show: false,
    message: null,
    action: null
  }
  topNotConfig : BehaviorSubject<any>


  constructor() { 
    this.topNotConfig = new BehaviorSubject(this.defaultConfig)
  }

  set config(config: any){
    console.log('NEW CONFIG',config)
    this.topNotConfig.next(config)
  }

  get config():Observable<any>{
    return this.topNotConfig.asObservable()
  }

  generateConfig(user){
    
    switch (user?.status) {
      case "limited":
        this.config = {
          show: true,
          message: user.warning ?? 'Votre acces est limité à cause de non paiement ! Veuillez acheter votre abonnement!',
          action: (user?.role == "proprietaire" || user?.role == "administrateur") ? 'Facturation' : null  
        }
        break;
      case "warning":{
        let end = user.trial_end ?? user.end;
        
        let day = end ? " avant le " + moment(end).format('LL') : ''
        this.config = {
          show: true,
          message: user.warning ?? "Veuillez payer votre abonnement" //+ day
           + ' pour éviter la suspension de votre compte !',//user.trial_end || user.end,
          action: (user?.role == "proprietaire" || user?.role == "administrateur") ? 'Facturation' : null  
        }
        break;}
        case "grace":{

        let end = user.trial_end ?? user.end;
        end = moment(end).format()
          this.config = {
            show: true,
            message: user.warning ?? "Votre abonnement a expire! Veuillez effectuer votre paiement"// avant le "+moment(user.grace_end).format('LL')
            + " pour éviter la suspension de votre compte",//user.trial_end || user.end,
            action: (user?.role == "proprietaire" || user?.role == "administrateur") ? 'Facturation' : null  
          }
          break;
        }
        case "blocked":
        break;
        case "blocked_inactive":
        break;
        case "suspended":
        case "incomplete_expired":
        case "incomplete":
          break;

      case "trialing":
        {
          
        let diff = (new Date(user.trial_end).getTime() - new Date().getTime()) / (24*60*60*1000)
        // * Warning before three days of the trial expiry date
        if(diff < 3){
          this.config = {
            show: true,
            message: user.warning ?? "Votre période d'essai expire bientôt. Veuillez effectuer votre paiement"// avant le "+ moment(user.trial_end).format('LL') 
            + " pour éviter la suspension de votre compte.",
            action: (user?.role == "proprietaire" || user?.role == "administrateur") ? 'Facturation' : null  
          }
        }}
        break;
      case "settled":
        {
          //! settled and still is in trial, dont show notification.
          if(user.trial_end) return
        let diff = (new Date(user.end).getTime() -new Date().getTime()) / (24*60*60*1000)
        if(diff < 3){
          this.config = {
            show: true,
            message: user.warning ?? "Votre abonnement expire bientôt. Veuillez effectuer votre paiement" //avant le "+ moment(user.end).format('LL') 
            + " pour éviter la suspension de votre compte.",
            action: (user?.role == "proprietaire" || user?.role == "administrateur") ? 'Facturation' : null  
          }
        }}
        break;
      /* 
        case "incomplete":
        {let diff = (new Date().getTime() - new Date(user.end).getTime()) / (24*60*60*1000)
        if(diff < 3){
          this.config = {
            show: true,
            message: user.warning ?? "Votre paiement est en cours de traitement.",
            action: null
          }
        }}
          break; */

      default:
          break;
  }
  }

  setToDefault(){
    this.config = this.defaultConfig;
  }
}
