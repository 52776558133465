import { Component, OnInit, Input } from "@angular/core";
import { month } from "../paiement-print/paiement-print.component";

@Component({
    selector: "app-print",
    templateUrl: "./print.component.html",
    styleUrls: ["./print.component.scss"],
})
export class PrintComponent implements OnInit {
    @Input("data") data;

    constructor() {}

    ngOnInit() {
    }

    getDateNow() {
        let date = new Date();
        return (
            date.getDate() +
            " " +
            month[date.getMonth()] +
            " " +
            date.getFullYear() +
            " " +
            date.toTimeString().split(' ')[0]
        );
    }

    getDate(date) {
        let d = new Date(date);
        return d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear();
    }

    getHours(date) {
        let d = new Date(date);
        return this.pad(d.getHours(), 2) + ":" + this.pad(d.getMinutes(), 2);
    }

    getResult(success) {
        if (success) {
            return "succès";
        } else {
            return "ajourné(e)";
        }
    }

    getDetail(item) {
        if (item.type === "codeEx") {
            return item.resultat;
        } else {
            if (item.success) {
                return "N/A";
            } else {
                if (item.echecParc) return "parc";
                else return "circuit";
            }
        }
    }

    getTotalDuration(table: []) {
        let t = 0;
        table.forEach((item: any) => {
            t += item.duration;
        });
        return t;
    }

    getExamType(type) {
        if (type === "codeEx") {
            return "code";
        } else {
            return "conduite";
        }
    }

    pad(num, size) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    getPaimenetTotal() {
        let t = 0;
        (this.data.paiement as []).forEach((element: any) => {
            t += element.montant;
        });
        return t;
    }
}
