import { PrintService } from "./print.service";
import { GlobalService } from "app/global.service";
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs";

import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import { trigger, transition, style, animate } from "@angular/animations";
import { TopNotificationService } from "app/layout/components/top-notification/top-notification.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { LayoutService } from "app/layout/layout.service";
import { EventFlowService } from "app/main/plus/calendar/event-flow.service";
import { Router } from "@angular/router";

@Component({
    selector: "vertical-layout-1",
    templateUrl: "./layout-1.component.html",
    styleUrls: ["./layout-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideInOut', [
          transition(':enter', [
            style({transform: 'translateY(-100%)'}),
            animate('300ms ease-in', style({transform: 'translateY(0%)'}))
          ]),
          transition(':leave', [
            animate('300ms ease-in', style({transform: 'translateY(-100%)'}))
          ])
        ])
      ]
})
export class VerticalLayout1Component implements OnInit, OnDestroy {

    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;

    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    innerWidth: number;
    fuseConfig: any;

    topNotConfig : any;
    navigation: any;
    notifactions: [];
    role: any;
    devicetype: any;
    printer: "par" | "aip" | "ppl" | "pp" | "p" = null;
    printerData;

    editingEvent = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
     
    constructor(
        private globalService: GlobalService,
        private _fuseConfigService: FuseConfigService,
        private _printService: PrintService,
        private _topNotService: TopNotificationService,
        private _layoutService: LayoutService,
        private eventFlow: EventFlowService,
        private router:Router,
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    setNotToDefault(){
        this._topNotService.setToDefault()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    

    scrollTo(offset){
        this._fusePerfectScrollbar.scrollToY(offset,500)
    }

    /**
     * On init
     */
    ngOnInit(): void {

        /* if (this.globalService.getUser()) {
            this.role = this.globalService.getUser().role;
        } else this.role = "noRole"; */
        // Subscribe to config changes

        //this.setNotToDefault();

        this.eventFlow.eventDialogIsOpen
            .pipe(
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((isOpen) => {
                let isCalendar = this.router.url.indexOf('calendar') != -1;
                if (!isCalendar) return
                console.log('EV FLOW',);
                if(isOpen){
                    this.editingEvent = true;
                }
                else{
                    this.editingEvent = false;
                } 
            });

        this._topNotService.config.subscribe(config=>{
            this.topNotConfig=config
        })


        this._layoutService.scrollTo.subscribe({
            next:(offset)=>this.scrollTo(offset)
        })

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this._printService._print
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.printer = "p";
                this.printerData = data;
                setTimeout(() => {
                    window.print();
                }, 1000);
            });

        this._printService._printAgencyRecu
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.printer = "par";
                this.printerData = data;
                setTimeout(() => {
                    window.print();
                }, 1000);
            });

        this._printService._printAgencyInvoice
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.printer = "aip";
                this.printerData = data;
                setTimeout(() => {
                    window.print();
                }, 1000);
            });

        this._printService._printPaiement
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.printer = "pp";
                this.printerData = data;
                setTimeout(() => {
                    window.print();
                }, 1000);
            });

        this._printService._PrintPlanning
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.printer = "ppl";
                this.printerData = data;
                setTimeout(() => {
                    window.print();
                }, 1000);
            });


        this.devicetype = this.globalService.detectdevice()
        //console.log(this.devicetype)
    }

    logui(event) {
        //event.stopPropagation()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true)
        this._unsubscribeAll.complete();
    }

}
