import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventFormComponent } from "./event-form.component";
import { FuseConfirmDialogModule } from "@fuse/components";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
//import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DirectivesModule } from "app/helper/directives/directives.module";

@NgModule({
    imports: [
        MatDividerModule,
        MatCheckboxModule,
        MatMenuModule,
        NgxMaterialTimepickerModule,
        CommonModule,
        FuseSharedModule,
     // NgbModule,
     //  NgbDatepickerModule,
        FuseConfirmDialogModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxMatSelectSearchModule,
        DirectivesModule
    ],

    declarations: [EventFormComponent],
})
export class EventFormModule {}
