<div id="exam-code-result" class="m-20">
    <h1 matDialogTitle>Resultat examen code</h1>
    <div mat-dialog-content>
        <div class="w-100-p" style="padding-right: 24px;" fxLayout="row">
        <span style="font-weight:bold">Note : </span>

        <input
            autocomplete="off"
            (change)="onNote($event)"
            [(ngModel)]="note"
            style="
                border: solid back 1px;
                position: relative;
                margin-left: 44%;
                width: 40px;
                border: solid 1px;
                padding: 2px 2px;
                border-radius: 6px;
            "
            [max]="(typeDePermis == 'Type C+E' || typeDePermis == 'Type D') ? 35 : ((typeDePermis == 'Type AA') ? 25 : 30)"
            min="0"
            matInput
            type="number"
        />
        <span style="padding-left: 12px; font-weight: bold;">
            / {{(typeDePermis == 'Type C+E' || typeDePermis == 'Type D') ? 35 : ((typeDePermis == 'Type AA') ? 25 : 30)}}
        </span>
        </div>
        <div
            fxLayout="row"
            class="w-100-p mt-12"
            fxLayoutAlign="center center"
            style="margin-left: -30px;"
        >
            <div
                class="w-100-p"
                fxLayout="row"
                fxLayoutAlign="space-around center"
            >
                <mat-radio-group
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    [(ngModel)]="reussite"
                    [ngModelOptions]="{ standalone: true }"
                >
                    <!--         <div>
                        <div fxLayoutAlign="start center">
                            <mat-icon style="color: green">check_circle</mat-icon>
                        </div>
                    </div>-->
                    <mat-radio-button [value]="options[0].value">
                        <div fxLayoutAlign="center center">
                            <mat-icon style="color: green;"
                                >check_circle</mat-icon
                            >
                            {{ options[0].option }}
                        </div>
                    </mat-radio-button>
                    <!--   <div>
                        <div fxLayoutAlign="start center">
                            <mat-icon class="fail">close</mat-icon>
                        </div>
                    </div>-->
                    <mat-radio-button
                        style="margin-left: 15%;"
                        [value]="options[1].value"
                    >
                        <div fxLayoutAlign="center center">
                            <mat-icon class="fail mr-4">close</mat-icon>
                            {{ options[1].option }}
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div mat-dialog-actions class="pt-24" style="display: flex;
        flex-direction: row;
        justify-content: flex-end;">
            <button mat-stroked-button (click)="_matDialogRef.close()">Annuler</button>
            <button
                mat-raised-button
                class="mat-accent mr-16"
                (click)="register()"
            >
                Enregistrer
            </button>
        </div>
    </div>
</div>
