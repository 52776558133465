import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
  selector: 'app-erreur-internet',
  templateUrl: './erreur-internet.component.html',
  styleUrls: ['./erreur-internet.component.scss']
})
export class ErreurInternetComponent implements OnInit {

  constructor(
    private splashScreen: FuseSplashScreenService,
    private _fuseConfigService: FuseConfigService, private router: Router
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }
  blink = true;


  ngOnInit() {
      this.splashScreen.hide()
    // if(navigator.onLine) swindow.location.href = "/dashboards/analytics" 
//    this.router.navigate(["/dashboards/analytics"]);
/*     setTimeout(()=>{
    },100) */
  }
  
    recharge() {
      window.location.href = "/dashboards/analytics"
      /* if (navigator.onLine) {
        this.router.navigate(["/dashboards/analytics"]);
      } else {
        this.router.navigate(["/nointernet"]);
      } */
    }

}
