import { RealTimeService } from "./../../../real-time.service";
import { CandidatService } from "./../../../main/plus/candidats/candidat/candidat.service";
import { GlobalService } from "app/global.service";
//import { forEach } from "@angular/router/src/utils/collection";
import { LayoutService } from "./../../layout.service";
import { AuthentificationService } from "./../../../main/authentification/authentification.service";
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { Subject, take } from "rxjs";
import { takeUntil } from "rxjs";
//import * as _ from "lodash";

import {find} from 'lodash-es'

import { HttpClient } from "@angular/common/http";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigation } from "app/navigation/navigation";
import { SharedNavigationService } from "@fuse/services/shared-navigation.service";
import { ViewRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PaymentCDialogComponent } from "app/main/plus/components/payment-cdialog/payment-cdialog.component";
import { DepenceFormComponent } from "app/main/plus/finance/depences/depence-form/depence-form.component";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import { EventFlowService } from "app/main/plus/calendar/event-flow.service";
import { DepencesService } from "app/main/plus/finance/depences/depences.service";
import { ErreurService } from "app/main/authentification/erreur.service";
import { TeamService } from "@fuse/services/team.service";
import { ReloadDialogService } from "@fuse/services/reload-dialog.service";
import { environment } from "environments/environment";
import { Title } from "@angular/platform-browser";
import { NotificationSettingsDialogComponent } from "../notification-settings-dialog/notification-settings-dialog.component";
import { AllowNotificationDialogComponent } from "app/main/plus/components/allow-notification-dialog/allow-notification-dialog.component";
@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    urlImage: any;
    nom: any;
    user:any;
    notNumber: any = 0;
    badgeHidden = true;
    interval :any;
    role: any;
    sub_type: string;
    

    // this array will key tracking the questions id in the notification
    // it will be used to get a correct number in the notification icon
    questionsNotificationsID: number[] = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    //For the title changing when receiving a notification
    private titleSwitchInterval: any;
    private newTitle = 'New notification';
    private originalTitle = 'Application de gestion auto ecole tunisie | autoecoleplus.tn';
    private isOriginalTitle = true;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     */

    badge: number;
    uri = environment.uriG + "/api/employees"; 
    uri2 = environment.uriG + "/api/agences";

    getImage(nom) {
        this.http;
        return this.http.get(`${this.uri}/download/${nom}`, {
            responseType: "arraybuffer",
        });
    }

    constructor(
        private _reloadService: ReloadDialogService,
        private teamService: TeamService,
        private route: ActivatedRoute,
        private serviceDepense: DepencesService,
        private errService: ErreurService,

        private eventFlow: EventFlowService,
        private router: Router,
        private _matDialog: MatDialog,
        private globalService: GlobalService,
        private ref: ChangeDetectorRef,
        private layoutService: LayoutService,
        private CandidatService: CandidatService,
        private http: HttpClient,
        private _sharedService: SharedNavigationService,
        public authService: AuthentificationService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _realTimeService: RealTimeService,
        private titleService: Title,
        private service: AuthentificationService,
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    detectChanges() {
        // Programmatically run change detection to fix issue in Safari
        setTimeout(() => {
            if (
                this.ref !== null &&
                this.ref !== undefined &&
                !(this.ref as ViewRef).destroyed
            ) {
                this.ref.detectChanges();
            }
        }, 250);
    }

    subToUserChange(){
        this.globalService.currentUser.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next:(u)=>{
                if(u){

                if((u?.image != this.user?.image) || !this.urlImage){
                    if (u.image != null) {
                        this.getImage(this.user.image).subscribe(
                            {
                                next:(file) => {
                                    var bytes = new Uint8Array(file);
                                    this.urlImage =
                                        "data:image/png;base64," + encode(bytes);
                                },
                                error:(err)=>{
                                    this.urlImage =
                                    "../../../../../assets/images/avatars/agent.png";
                                }
                    });
                            } else {
                                this.urlImage =
                                    "../../../../../assets/images/avatars/agent.png";
                            }
                }
                this.role = u.role;
                this.sub_type = u.sub_type
                this.nom = u.nom + " " + u.prenom;
            }
            this.user = u;
            }
            
        })
    }
    
    loading() {
        // if(this.globalService.getUser()){
        if(this.globalService.getUser()){
            this.user = this.globalService.getUser();
            this.role = this.globalService.getUser().role;
            this.nom = this.globalService.getUser().nom + " " + this.globalService.getUser().prenom;
            this.sub_type = this.globalService.getUser().sub_type;
        }
       this.subToUserChange();

       
        this.layoutService.fetchUnopenedExamCenterNotifications().subscribe((response)=> {
            (response.notifications as any[]).forEach((element) => {
                this._realTimeService.addExamNotificationToExamDialog(element)
            });
        });


        this.layoutService.fetchUndeliveredAndUnreadNotificationCount().subscribe((response)=> {
            this.notNumber = response.count;
            if (this.notNumber > 0) {
                this.badgeHidden = false;
                this.detectChanges();
            }
        })
    }

    hide(){
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
    
    openNotificationSettingsDialog(){
        this.dialogRef = this._matDialog.open(NotificationSettingsDialogComponent,{
            panelClass: ["common-dialog-style"]
        })
    }

    ngOnInit(): void {
        const currentUrl = this.router.url; // Get the current URL
        if (currentUrl !== '/nointernet' &&
            currentUrl !== '/auth/blocked' &&
            this.service.loggedIn()) {

            this.requestNotificationPermission()
        }


        moment.locale("fr");
        // this.openNotificationSettingsDialog()
        if (this.authService.loggedIn() && this.globalService.getUser()) {
            this.loading();
        }

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = find(this.languages, {
            id: "en",
        });

        this.updateSubscription();
    }
    dialogRef: any;

    //* These functions are responsible of the title changement periodically.
    //* The location is not right, needs to be somewhere else (To chnage).

    updateDocumentTitle(newTitle: string): void {
        // Clear any existing interval
        if (this.titleSwitchInterval) {
            clearInterval(this.titleSwitchInterval);
        }

        // Set the new title and start the periodic switching
        // this.newTitle = newTitle;
        this.titleService.setTitle(newTitle);

        this.titleSwitchInterval = setInterval(() => {
            this.toggleTitle(newTitle);
          }, 2000); // Change the title every 2 seconds
      }
    
      private toggleTitle(newTitle: string) {
        if (this.isOriginalTitle) {
          this.titleService.setTitle(newTitle);
        } else {
          this.titleService.setTitle(this.originalTitle);
        }
        this.isOriginalTitle = !this.isOriginalTitle;
      }

    /**
     * ? Subscribe to Questions Change
     * ? Subscribe to Notifications Change
     */
    updateSubscription() {
        this._realTimeService
            .getRealTimeQuestion()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((question) => {
                this.questionsNotificationsID.push(question.id);
                // this.notNumber += 1;
                // this.newTitle = `(${this.notNumber}) ${this.newTitle}`;
                if (!question.delivered){
                    this.updateDocumentTitle(`(${this.notNumber}) ${this.newTitle}`);
                    console.log("question.delivered:",question.delivered)
                } else {
                    // Clear the interval and reset the title if the question is delivered
                    if (this.titleSwitchInterval) {
                        clearInterval(this.titleSwitchInterval);
                    }
                    this.titleService.setTitle(this.originalTitle); // Reset to the original title
                }        
                if (this.notNumber > 0) this.badgeHidden = false;
                this.detectChanges();
            });
        this._realTimeService
            .getRealTimeFollowUps()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((question) => {
                if(this.questionsNotificationsID.findIndex(id => question.id === id) === -1){
                    // this.notNumber += 1;
                    // this.newTitle = `(${this.notNumber}) ${this.newTitle}`; 
                    if (!question.delivered){
                        this.updateDocumentTitle(`(${this.notNumber}) ${this.newTitle}`);
                        console.log("question.delivered",question.delivered)
                    }
                    else {
                        // Clear the interval and reset the title if the question is delivered
                        if (this.titleSwitchInterval) {
                            clearInterval(this.titleSwitchInterval);
                        }
                        this.titleService.setTitle(this.originalTitle); // Reset to the original title
                    }     
                    this.questionsNotificationsID.push(question.id);
                }
                if (this.notNumber > 0) this.badgeHidden = false;
                this.detectChanges();
            });

        this._realTimeService
            .getRealTimeNotification()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notification) => {
                this.notNumber += 1;
                // this.newTitle = `(${this.notNumber}) ${this.newTitle}`;
                if (!notification.delivered){
                    this.updateDocumentTitle(`(${this.notNumber}) ${this.newTitle}`);
                    console.log("notification.delivered: ",notification.delivered);
                }else {
                    // Clear the interval and reset the title if the question is delivered
                    if (this.titleSwitchInterval) {
                        clearInterval(this.titleSwitchInterval);
                    }
                    this.titleService.setTitle(this.originalTitle); // Reset to the original title
                }
                if (this.notNumber > 0) this.badgeHidden = false;
                this.detectChanges();
            });
    }

    addPayment() {
        this.dialogRef = this._matDialog.open(PaymentCDialogComponent, {
            panelClass: ["common-dialog-style","payment-d-style"],
            data: {
                fromDash: true,
            },
        });
        this.dialogRef.afterClosed().pipe(take(1)).subscribe((response) => {
            this._reloadService.pushChanges("payment");
        });
    }
    choose() {
        let d = new Date();
        d.setHours(8,0,0,0)
        this.eventFlow.openChoiceForm({ date: d });
    }
    // call the service and add the dep

    addDepense() {
        let emp = this.globalService.getUser();
        let arr = JSON.stringify(emp);
        this.teamService.getTeam().subscribe((res) => {
            let agents = res;
            for (var i = 0; i < agents.length; i++) {
                if (!agents[i].active || agents[i].archived) {
                    agents.splice(i, 1);
                    i--;
                }
                if (agents[i].id == emp.id) {
                    var b = agents[0];
                    agents[0] = agents[i];
                    agents[i] = b;
                }
            }
            for (var i = 0; i < agents.length; i++) {
                agents[i].username = agents[i].nom + " " + agents[i].prenom;
            }

            this.dialogRef = this._matDialog.open(DepenceFormComponent, {
                panelClass: ["common-dialog-style","depense-d-style"],
                data: {
                    action: "new",
                    agents: agents,
                },
            });

            this.dialogRef.afterClosed().pipe(take(1)).subscribe((response) => {
                console.log('resp in historique',response.date)
                console.log('resp in historique',response)

                if (response) {
                    response.date = moment(response.date).local().format()//.format("L");
                    switch (response.famille) {
                        case 0:
                            response.famille = "Véhicules";
                            break;

                        case 1:
                            response.famille = "Candidats";
                            break;

                        case 2:
                            response.famille = "Personnelles";
                            break;

                        case 3:
                            response.famille = "Locaux";
                            break;

                        case 4:
                            response.famille = "Autre";
                            break;
                    }
                    this.addDepenseCall(response);
                }
            });
        });
    }
    addDepenseCall(depense) {
        this.serviceDepense.addDepense(depense).subscribe(
            () => {
                this._reloadService.pushChanges("depense");

                //this.refresh.next(true);
            },
            (err) => {
                this.errService.popError(
                    err,
                    "Erreur lors de l'ajout. Essayer plus tard"
                );
            }
        );
    }
    addCandidat() {
        this.router.navigate(["/candidats/new"]);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true)
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        if (!this._fuseSidebarService.getSidebar(key).opened) {
            this.layoutService.MarkQuestionsAsReaded().subscribe((res) => {
            });
        }
        this._fuseSidebarService.getSidebar(key).toggleOpen();
        // susbscribe to toggleOpene quickPanel for close if from the intern of the panel 
        this.layoutService.quickPanelToggleOpen.pipe(take(1)).subscribe((res) => {
            if(res[0]) {
                this._fuseSidebarService.getSidebar(key).close();
            }
        })
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    refresh(){
        // Get the full URL from the browser's address bar
        const fullUrl = window.location.href;

        // Create a URL object from the full URL
        const url = new URL(fullUrl);

        // Get the pathname and search (query parameters) from the URL object
        const path = url.pathname; // This is '/candidats'
        const query = url.search; // This is '?examType=Code,Conduite,Parc'

        // Combine pathname and search to get the desired result
        const resultUrl = `${path}${query}`;

        // First navigate to a dummy route (or the same route with different params), then back to the original
        this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => {
            // navigate to the actual URL
            this.router.navigateByUrl(resultUrl);
        });
    }

    redirectTo(uri) {
        this.router.navigateByUrl('/damb', {skipLocationChange: true}).then(() =>{
        this.router.navigate([uri])});
      }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
    }

    opened() {
        if (this.badgeHidden === false) {
            setTimeout(() => this.notNumber = 0, 500);
            this.questionsNotificationsID.length = 0;
            this.layoutService
                .notificationDelivered()
                .subscribe((res) => {
                    this.badgeHidden = true;
                    if (this.titleSwitchInterval) {
                        clearInterval(this.titleSwitchInterval);
                    }
                    this.titleService.setTitle(this.originalTitle);
                });
                /* clearInterval(this.interval); */
        }
    }
    scrollUp(){
        this.layoutService.triggerScrollToTop();
    }


    // Check for Notification permissions and request if not granted
    requestNotificationPermission(): void {
        if (!('Notification' in window)) {
            console.log("This browser doesn't support notifications.");
            return;
        }

        const notificationPreference = localStorage.getItem('notificationPreference');
        if (notificationPreference === 'dismissed') {
            // Skip showing the prompt
            // the user already affirmed that he doesn't want to receive notifications
            return;
        }

        const lastReminder = localStorage.getItem('lastReminderToAllowNotifs');
        const reminderInterval = 24 * 60 * 60 * 1000; // 24 hours

        // make sure we only remind the user to allow notifications
        // once a day
        // to avoid spamming him
        if (lastReminder && Date.now() - Number(lastReminder) < reminderInterval) {
            return;
        }

        // Show reminder prompt
        localStorage.setItem('lastReminderToAllowNotifs', Date.now().toString());

        if (Notification.permission !== 'granted') {
            let data = {}
            if(Notification.permission === 'denied') {
                data = {
                    notificationBlockedFromBrowser: true
                }
            }

            this.dialogRef = this._matDialog.open(AllowNotificationDialogComponent, {
                panelClass: ["common-dialog-style"],
                data: data,
                width:'800px'
            });
            

        }
    }

}
function encode(input) {
    var keyStr =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output +=
            keyStr.charAt(enc1) +
            keyStr.charAt(enc2) +
            keyStr.charAt(enc3) +
            keyStr.charAt(enc4);
    }
    return output;
}
