import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExamResultComponent } from "./exam-result.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
    imports: [
        FuseSharedModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule
    ],
    declarations: [ExamResultComponent],
})
export class ExamResultModule { }
