import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExamResultComponent } from "../exam-result/exam-result.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FormsModule } from "@angular/forms";
import { ExamConduiteResultComponent } from "./exam-conduite-result.component";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
    imports: [
        MatInputModule,
        FormsModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule,
        FlexLayoutModule
    ],
    declarations: [ExamConduiteResultComponent],
})
export class ExamContuiteResultModule {}
