<div class="navbar-top mater">

    <div>
        <img style="margin-left: 0px; margin-top: 20px; widows: 150px;height: 46px; z-index: 9999;"
            src="./assets/landing/minilogo2.svg" alt="autoecoleplus.tn">

    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" >

    <div class="user mater" fxLayout="column">

        <div class="h3 username"> {{nom}}</div>
        <div class="h5 email hint-text mt-8">{{adress}}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.background">
            <img class="avatar" alt="votre image" src="{{urlImage}}">
        </div>

    </div>

    <div class="navbar-content" fusePerfectScrollbar  [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
        <fuse-navigation layout="vertical"></fuse-navigation>
    </div>

</div>