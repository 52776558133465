import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculeComponent } from './vehicule.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



@NgModule({
  declarations: [VehiculeComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatMenuModule,
    FuseSharedModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        NgxMatSelectSearchModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule
  ]

})
export class VehiculeModule { }
