import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
//import { AgmCoreModule } from "@agm/core";
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        /* AgmCoreModule.forRoot({
            apiKey: "AIzaSyACKhJe2-6RH7c1GvlG_0JruRIOl0nMIAE",
          }), */
        
    ],
    exports  : [
       // AgmCoreModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        
    ]
})
export class FuseSharedModule
{
}
