import { Component, Injectable, Input, Output, EventEmitter, Directive } from "@angular/core";
import { Subject } from "rxjs";

@Directive()
@Injectable({
    providedIn: "root"
})
export class SharedNavigationService {

    loginSubject: Subject<any> = new Subject<any>();

    @Output() role: EventEmitter<any> = new EventEmitter();
    constructor() {}

    change(r) {
        this.role.emit(r);
        this.loginSubject.next(1)
        
    }

    getEmittedValue() {
        return this.role;
    }

    loginNotification(){
        return this.loginSubject.asObservable()
    }

    getEmittedValuNotifications() {}
}
