import { RealTimeService } from "./real-time.service";
import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { of, ReplaySubject, switchMap } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { environment } from "environments/environment";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class GlobalService {
    i = 0;
    uri = environment.uriG;
    private user: any = null;
    private guide: any = null;
    private agents = [];
    private instructorsNumber = 1;
    private vehicules = [];
    private vehiculesNumber = 1;
    private tarif = {};
    private agency = { fraisInscription: 60 };

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private socketService: RealTimeService
    ) {}

    private _currentUser = new ReplaySubject<any>(1);

    public getAgency() {
        this.http
            .get<any>(`${this.uri}/api/agences/agence`)
            .subscribe((agency) => (this.agency = agency));
    }

    get Agency() {
        return this.agency;
    }
    public getAgents() {
        this.http
            .get<any>(`${this.uri}/api/employees`)

            .subscribe((agents) => {
                this.agents = agents;
            });
    }

    public getTarif() {
        this.http
            .get<any>(`${this.uri}/api/agences/tarif`)
            .subscribe((tarif) => (this.tarif = tarif));
    }

    public getVehicules() {
        this.http
            .get<any>(`${this.uri}/api/vehicles`)
            .subscribe((vehicules) => (this.vehicules = vehicules));
    }

    public getUser() {
        return this.user;
    }

    public getGuide() {
        return this.guide;
    }

    get currentTarif() {
        return this.tarif;
    }

    get currentAgents() {
        return this.agents;
    }

    get currentVehicules() {
        return this.vehicules;
    }

    loadAgency() {
        this.getAgents();
        this.getTarif();
        this.getVehicules();
        this.getAgency();
    }


    load(from) {
        let authService = this.injector.get(AuthentificationService);
        return new Promise((resolve, reject) => {
            authService.signInUsingToken()
                .subscribe({
                    next: (response:boolean | string)=>{
                        if(response == 'no-internet'){
                            this.router.navigate(["/nointernet"])
                            resolve(true);
                        }
                        else if(response){
                            this.configUser(this.user);
                            resolve(true);
                        }
                        else{
                        authService.logOut();
                            resolve(true);
                        }
                    },
                    error: (error)=>{
                        if((error.status === 0 && error.error instanceof ProgressEvent)
                        || error.status === 504){
                            this.router.navigate(["nointernet"])
                            resolve(true);
                        }
                        authService.logOut();
                        resolve(true);
                    }
                })
        }); 
    }

    configUser(user,redirect_url?){
        let authService = this.injector.get(AuthentificationService);
        if (user) {
            this.user = user;
            this.guide = this.user.guide;
            this.socketService.connectSocket(
                this.user.id,
                this.user.agenceId,
                this.user.role
            );
            this.i++;
            
            this.setCurrentUser(this.user);
            this.socketService.subscribeToNotifications(this.user.id)
            if(redirect_url) {
                this.router.navigate([redirect_url])}
        } else {
            authService.logOut();
        }
    }

    updateGuide() {
        this.http
            .put(`${this.uri}/api/employees/guide`, {
                id: this.user.id,
                guide: this.guide,
            })
            .subscribe({
                next: (response) => {},
                error: (err) => {}}
            );
    }




    setCurrentUser(user) {
        this.user = user;
        this._currentUser.next(user);
    }



    get currentUser() {
        return this._currentUser.asObservable();
    }

    detectdevice() {
        return this.deviceService.getDeviceInfo().os;
    }
}
