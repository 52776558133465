<div id="cashout-dialog" class="cashout-dialog">
    <div *ngIf="true" class="dialog-content-wrapper">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
                <span>
                    {{dialogTitle}}
                </span>

                <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon class="fail" style="background: inherit !important">close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <!-- ? CONTENT -->
        <div fusePerfectScrollbar class="dialog-content">
            <div class="loading"  *ngIf="firstLoading">
                <mat-spinner [diameter]="50" ></mat-spinner>
            </div>
            <div  *ngIf="!firstLoading" >
                <div *ngIf="currentState == 1" class="p-24 m-0 content step1" style="overflow-y: auto; overflow-x: visible" >
                    
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" class="w-100-p">
                        <p class="info">
                            <span class="big-text">
                                Retrait bancaire a partir de solde d'achats candidats.
                            </span>

                        </p>

                        <mat-icon class="mt-12">info</mat-icon>

                    </div>

        
                    <form *ngIf="addBankAccountInformationForm" [formGroup]="addBankAccountInformationForm" (submit)="onSubmitAddBankAccountInformations()" >

                        <div fxLayout="row" fxLayoutWrap class="pt-24 w-100-p">

                            <div fxFlex="100">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen">Select votre bank</label>
                                <mat-form-field appearance="outline">
                                    <mat-label *ngIf="isXsScreen" >Select votre bank</mat-label>
                                    <mat-select [value]="selectedBankOption" (selectionChange)="onBankSelectionChange($event)">
                                        <mat-select-trigger>
                                        <div *ngIf="!selectedBankOption" class="image-text-container">
                                            <p>Select votre bank</p>
                                        </div>
                                        <div *ngIf="selectedBankOption" class="image-text-container">
                                            <img class="bank-logo" mat-icon *ngIf="selectedBankOption?.image" [src]="selectedBankOption.image" alt="Selected Bank Logo">
                                            <p>{{selectedBankOption?.name}}</p>
                                        </div>
                                        </mat-select-trigger>
                                    
                                        <mat-option *ngFor="let option of tnbanksInformations" [value]="option">
                                        <div  class="image-text-container">
                                            <img class="bank-logo" mat-icon [src]="option.image" alt="Selected Bank Logo">
                                            <p>{{option?.name}}</p>
                                        </div>
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                                
                        </div>

                        </div>
                        <div fxLayout="row" fxLayoutWrap class="w-100-p">

                            <div fxFlex="100">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen" >Nom du propriétaire</label>
                                    <mat-form-field appearance="outline">
                                        <mat-label  *ngIf="isXsScreen" >Nom du titulaire </mat-label>
                                        <input matInput formControlName="accountOwnerName"  placeholder="Enter le nom du propriétaire">
                                        <mat-error *ngIf="addBankAccountInformationForm.get('accountOwnerName').hasError('required')">
                                            Le nom du titulaire du compte est obligatoire.
                                        </mat-error>

                                    </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutWrap class=" w-100-p">

                            <div fxFlex="100">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen" >RIB</label>
                                    <mat-form-field appearance="outline">
                                        <mat-label  *ngIf="isXsScreen" >RIB</mat-label>
                                        <input matInput  (keypress)="validateInteger($event)" formControlName="rib"  placeholder="Enter RIB">
                                        <mat-error *ngIf="addBankAccountInformationForm.get('rib').hasError('required')">
                                            RIB obligatoire
                                        </mat-error>
                                        <mat-error *ngIf="addBankAccountInformationForm.get('rib').hasError('Length')">
                                            Assurez-vous que votre RIB comporte 20 caractères.
                                        </mat-error>
                                    </mat-form-field>
                            </div>
                        </div>

                    </form>

                </div>
                <div *ngIf="currentState == 2" class="p-24 m-0 content step2" style="overflow-y: auto; overflow-x: visible" >
                    
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" class="w-100-p">
                        <p class="info">
                            <span class="big-text">
                                Choisissez un compte bancaire ou postal existant ou créez-en un nouveau.
                            </span><br>

                            <b> N.B :</b> Montant minimum de retrait est 20 DT.

                        </p>

                        <mat-icon class="mt-12">info</mat-icon>

                    </div>
                    <div fxLayout="row" *ngIf="bankWithdrawal && cashoutBalance < 20">
                    <p  class="warning-message"> Vous ne pouvez pas effectuer un retrait bancaire.<br>Solde insuffisant ! </p>
                    </div>

                    <div class="select-bank-account-wrapper" *ngIf="!(bankWithdrawal && cashoutBalance < 20)">
                    <label class="input-label obligatoire"  *ngIf="!isXsScreen">Selectionnez votre compte </label>
                        <mat-form-field appearance="outline">
                            <mat-label *ngIf="isXsScreen" >Selectionnez votre compte </mat-label>
                            <mat-select [value]="selectedBankAccountOption" (selectionChange)="onBankAccaountSelectionChange($event)">
                                <mat-select-trigger>
                                <div *ngIf="!selectedBankAccountOption" class="image-text-container">
                                    <p>Selectionnez votre compte bancaire ou postal</p>
                                </div>
                                <div *ngIf="selectedBankAccountOption" class="image-text-container">
                                    <img class="bank-logo" mat-icon [src]="getBankImageUrlByName(selectedBankAccountOption.bank)" alt="Selected Bank Logo">
                                    <p>
                                        <span>
                                            {{selectedBankAccountOption?.account_owner_name}} 
                                        </span>
                                        <br>
                                        <span class="mt-8">
                                            RIB:  <small>{{selectedBankAccountOption.rib}}</small>
                                        </span>
                                    </p>
                                    
                                </div>
                                </mat-select-trigger>
                            
                                <mat-option *ngFor="let option of banksInformations" class="banks-informations-option" [value]="option">
                                <div  class="image-text-container">
                                    <img class="bank-logo" mat-icon [src]="getBankImageUrlByName(option.bank)" alt="Selected Bank Logo">
                                    <p>{{option?.account_owner_name}} <br> RIB:  <small>{{option.rib}}</small></p>
                                    <div  fxFlex fxLayoutAlign="end center">
                                        <button class="delete-btn" (click)="deleteBankAccount(option)" >
                                            <mat-icon>delete_forever</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    <div class="or-divider">
                        <span class="or-text">OU</span>
                    </div>
                    <button mat-raised-button class="add-account-button mat-accent" (click)="navigateToNext(1)" >
                        Ajouter un nouveau compte 
                    </button>
                </div>    

    
                </div>
                <div *ngIf="currentState == 3" class="p-24 m-0 content step3" style="overflow-y: auto; overflow-x: visible">
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" class="w-100-p">
                        <p class="info">
                            <span *ngIf="transferToBalance" class="big-text">
                                Convertir votre 
                                <b>
                                    solde achats candidats 
                                </b> 
                                en
                                <b>
                                    solde.
                                </b>
                            </span>

                            <span *ngIf="bankWithdrawal" class="big-text">
                                Retrait bancaire ou postal.
                                <br>
                                <b> N.B :</b> Le montant minimum est 20 DT.
                            </span>

                        </p>

                        <mat-icon class="mt-12">info</mat-icon>

                    </div>
                    <div fxLayout="row">
                        <span class="medium-text">Votre solde achats candidats est de 
                            <b>
                                {{ cashoutBalance | number :'1.3-3'}} DT
                            </b> 
                        </span>

                    </div>
                    <div fxLayout="row" *ngIf="transferToBalance && cashoutBalance < 1">
                        <p  class="warning-message">Vous ne pouvez pas effectuer une conversion de solde.<br>Solde insuffisant ! </p>
                    </div>
                    

                    
                    <div class="w-100-p containeer-boxs" *ngIf="bankWithdrawal" fxLayout="row" fxLayoutAlign="start center">

                        <!-- ?Séance code -->
                        <div  fxLayout="column" fxLayoutAlign="start center" class="content-card historyTables w-100-p"  ngClass.xs="w-100-p">
                            <div class="w-100-p h2 p-16 image-text-container" fxLayoutAlign="start start">
                                <p>
                                {{selectedBankAccountOption.account_owner_name}}<br>
                                RIB: <br>
                                <small>{{selectedBankAccountOption.rib}}</small></p>
                            </div>
                        </div>
                        
                        <div  fxLayout="column" fxLayoutAlign="center end" class="content-card historyTables pr-20 w-100-p" ngClass.xs="w-100-p">
                            <img class="bank-big-logo" mat-icon [src]="getBankImageUrlByName(selectedBankAccountOption.bank)" alt="Selected Bank Logo">
                            

                        </div>

                    </div>
                    

                    <form [formGroup]="cashoutForm"  *ngIf="!(bankWithdrawal && cashoutBalance < 20) && !(transferToBalance && cashoutBalance < 1)" (submit)="onSubmit()">

                        <div fxLayout="row" fxLayoutWrap class="p-24 pl-0 pb-0 w-100-p">

                            <div fxFlex="40" fxFlex.xs="80">
                                <label class="input-label obligatoire"  *ngIf="!isXsScreen" >Montant</label>
                                <mat-form-field appearance="outline">
                                <mat-label  *ngIf="isXsScreen" >Montant</mat-label>
                                <input type="number" matInput  (keypress)="validateInteger($event)" formControlName="montant" [value]="montant" placeholder="Enter montant">
                                <span matSuffix>DT</span>
                                <mat-hint *ngIf="bankWithdrawal">Au moins 20 DT</mat-hint>
                                <mat-hint *ngIf="transferToBalance">Au moins 1 DT</mat-hint>
                                <mat-error *ngIf="cashoutForm.get('montant').hasError('required')">
                                    Montant obligatoire
                                </mat-error>
                                <mat-error *ngIf="cashoutForm.get('montant').hasError('min') && transferToBalance">
                                    Le montant doit être d'au moins 1 DT
                                </mat-error>
                                <mat-error *ngIf="cashoutForm.get('montant').hasError('min') && bankWithdrawal">
                                    Le montant doit être d'au moins 20 DT
                                </mat-error>
                                <mat-error *ngIf="cashoutForm.get('montant').hasError('max')">
                                    Le montant ne doit pas dépasser {{ maxCashoutBalance }} DT
                                </mat-error>
                            </mat-form-field>
                        </div>

                        </div>
                        <div fxLayout="row" fxLayoutWrap class=" p-24 pl-0 pt-0 w-100-p mt-8">
                            <mat-checkbox [checked]="totaltransfer" (change)="changeTransferTotalite($event)">

                                <span *ngIf="bankWithdrawal" class="tajawalfont">
                                    Retirer la totalité du solde achats candidats.
                                </span>
                                <span *ngIf="transferToBalance" class="tajawalfont">
                                    Convertir la totalité du solde achats candidats.
                                </span>

                            </mat-checkbox>
                            </div>

                    </form>

                </div>

            </div>
        </div>

        <!-- ? ACTIONS -->

        <mat-divider class="mb-12"></mat-divider>

        <div class="m-0 pt-4 pb-8 px-20" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button *ngIf="edit" mat-icon-button (click)="
                                    _matDialogRef.close(['delete', this._data.event.id])
                                    " aria-label="SAVE"
                                   >
                    <mat-icon style="color: red">delete_outline</mat-icon>
                </button>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="4px" class="navigation">
                <div fxLayout="row" fxLayoutAlign="end center" *ngIf="currentState == 3" fxLayoutGap="4px">
                    <button (click)="onCancel()" mat-stroked-button *ngIf="transferToBalance">
                        Annuler
                    </button>
                    <button (click)="navigateToPrevious()" mat-stroked-button *ngIf="!transferToBalance">
                        <mat-icon>navigate_before</mat-icon>
                        Précédent
                    </button>
                    <button mat-stroked-button [ngClass]="{'save-button  mat-stroked-button': !disableSubmitButton && !cashoutForm.invalid , 'mat-raised-button' : disableSubmitButton || cashoutForm.invalid}"  [disabled]="disableSubmitButton || cashoutForm.invalid" aria-label="NEXT" (click)="onSubmit()">
                        Envoyer 
                    </button>

                </div>
                <div fxLayout="row" fxLayoutAlign="end center" *ngIf="currentState == 2" fxLayoutGap="4px">
                    <button (click)="navigateToPrevious()" *ngIf="!selectFromTheExsitingAccounts" mat-stroked-button>
                        <mat-icon>navigate_before</mat-icon>
                        Précédent
                    </button>
                    <button (click)="onCancel()" *ngIf="selectFromTheExsitingAccounts" mat-stroked-button>
                        Annuler
                    </button>
                    <button mat-stroked-button aria-label="SAVE" [ngClass]="{'save-button  mat-stroked-button': selectedBankAccountOption?.id , 'mat-raised-button' : !selectedBankAccountOption?.id}"  [disabled]="!selectedBankAccountOption?.id" (click)="goToStep(3)" *ngIf="!(bankWithdrawal && cashoutBalance <20 )">
                        Suivant
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" *ngIf="currentState == 1" fxLayoutGap="4px">
                    <button (click)="navigateToPrevious()" *ngIf="selectFromTheExsitingAccounts" mat-stroked-button>
                        <mat-icon>navigate_before</mat-icon>
                        Précédent
                    </button>
                    <button (click)="onCancel()" *ngIf="!selectFromTheExsitingAccounts" mat-stroked-button>
                        Annuler
                    </button>
                    <button mat-stroked-button [ngClass]="{'save-button  mat-stroked-button': !addBankAccountInformationForm.invalid , 'mat-raised-button' : addBankAccountInformationForm.invalid}" aria-label="SAVE" (click)="onSubmitAddBankAccountInformations()" [disabled]="addBankAccountInformationForm.invalid">
                        Suivant
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>