import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { SharedNavigationService } from '@fuse/services/shared-navigation.service';
import { AuthentificationService } from 'app/main/authentification/authentification.service';
import { LayoutService } from 'app/layout/layout.service';
import { RealTimeService } from 'app/real-time.service';


@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  animations: [
    trigger('popUpAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'scale(0.9)' }))
      ])
    ])
  ]
})
export class PopUpComponent implements OnInit, OnDestroy {

  notification: any;
  notifications: any[] = [];
  isPopUpVisible: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  layoutService;

  constructor(
    private router: Router,
    private injector: Injector,
    private _fuseSidebarService: FuseSidebarService,
    private _sharedService: SharedNavigationService,
    private authService: AuthentificationService,
    private socketService: RealTimeService,

  ) { }

  ngOnInit(): void {


    this.socketService
        .getRealTimeNotification()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next:(notification) => {
            this.notification = notification;
            this.isPopUpVisible = false // shoudl retruen it to true if we want to activate it again 
            ;
          }
        });


    this.socketService
        .getRealTimeQuestion()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (question) => {
            this.notification = question
            this.isPopUpVisible = false // shoudl retruen it to true if we want to activate it again 
            ;}
        });
    
    this.socketService
        .getRealTimeQuestion()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (question) => {
            question["type"] = "question";
            this.notifications.unshift(question);
            // this.notification = this.notification[0];
            this.notification = question
            this.isPopUpVisible = false // shoudl retruen it to true if we want to activate it again 
            ;}
        });

    
    this.socketService
        .getRealTimeFollowUps()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (question) => {
            question["type"] = "question";
            for(let i = 0; i < this.notifications.length; ++i) {
                if(question.id == this.notifications[i].id){
                    
                    if(i !== 0) {
                        this.notifications.unshift(question); // Make the notification appears first
                        this.notifications.splice(i + 1, 1); // Delete the old notification
                    } else {
                        this.notifications[i] = question;
                    }
                }
            }
          // this.notification = this.notification[0];
          this.notification = question
          this.isPopUpVisible = false; // should return it to true if wa want to active this feature
           
        }
    });
    // Subscribe to the events
  //   if (this.authService.loggedIn()) {
  //     this.loading();
  //   }

  //   this._sharedService.loginNotification().subscribe((role) => {
  //       if (role !== "superadmin") this.loading();
  //   });
  //   this.updateSubscription();
  // }

  // loading() {
  //   this.layoutService = this.injector.get(LayoutService);

  //   this.layoutService.getNotifications().subscribe((qres: any) => {
  //       this.notifications = [];
  //       (qres.notification as any[]).forEach((element) => {
            
  //           if(element['contentTable'] === 'follow-up'){
  //               element["type"] = "question";
  //           } else {
  //               element["type"] = "notification";
  //           }
            
  //           this.notifications.push(element);
  //       });
        
  //           (qres.questions as any[]).forEach((element) => {
  //           element["type"] = "question";
  //           this.notifications.push(element);
  //       });
  //       // Sort notifications by updated_at in descending order
  //       this.notifications.sort((a, b) => {
  //           const dateA = new Date(a.updated_at);
  //           const dateB = new Date(b.updated_at);
  //           return dateB.getTime() - dateA.getTime(); // Most recent first
  //       });

  //       this.notification = this.notifications[0];
  //   });
  // }

  // updateSubscription() {
  //   this.socketService
  //       .getRealTimeQuestion()
  //       .pipe(takeUntil(this._unsubscribeAll))
  //       .subscribe((question) => {
  //           question["type"] = "question";
  //           this.notifications.unshift(question);
  //           this.notification = this.notification[0];
  //           this.isPopUpVisible = true;

  //       });
    // this.socketService
    //     .getRealTimeFollowUps()
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe({
    //       next: (question) => {
    //         question["type"] = "question";
    //         for(let i = 0; i < this.notifications.length; ++i) {
    //             if(question.id == this.notifications[i].id){
                    
    //                 if(i !== 0) {
    //                     this.notifications.unshift(question); // Make the notification appears first
    //                     this.notifications.splice(i + 1, 1); // Delete the old notification
    //                 } else {
    //                     this.notifications[i] = question;
    //                 }
    //             }
    //         }
    //       // this.notification = this.notification[0];
    //       this.notification = question
    //       this.isPopUpVisible = true;
           
    //     }
    // });


///////// the end of woeked code    

    
}

  closePopUp() {
    this.isPopUpVisible = false;
  }

  navigateToNotification(item: any) {
    this.isPopUpVisible = false;
    this.notificationOpened(item);
    if (item.contentTable == "follow-up"){
      this.goToQuestionPage(item);
    } else {
      this.goToNotificationPage(item);
    }

  }

  notificationOpened(notification) {
    if(notification.opened === false){
      notification.opened = true;    
    }
  }

  goToNotificationPage(notification: any) {
    console.log("notification: ",{ notification });
    console.log("notification state is 1: ",notification.opened);
    this.notificationOpened(notification);
    // console.log("notification state is 2: ",notification.opened);
    const { contentId, contentTable } = notification;
    // this.markRed(notification.id);
    const navigate = (params: any, url: string): {urls: string[], params?: {} } =>  {
        // console.log("params: ", params);
        params = JSON.stringify([params]) //This is used to secure the URL
        // console.log("params: ", params);
        const listOfURLS = {
            reservation: {
                urls: ['/reservations'],
                params: { reservation: params }
            },

            event: {
                urls: ['/calendar'],
                params: { event: params } 
            },
            paiement: {
                urls: ['/finance/recettes'],
                params: { paiement: params }
            },
            depense: {
                urls: ['/finance/depenses/historique'],
                params: { depense: params }
            },
            Candidat: {
                urls: ['/candidats'],
                params: { candidat: params }
            }
        }
        return listOfURLS[url] || { urls: ['/'] };
    }
    
    if(contentId !== undefined || contentTable != undefined) {
        const navigationOptions = navigate(contentId, contentTable);
        console.log(navigationOptions);
        this.router.navigate(
            navigationOptions.urls,
            { queryParams: navigationOptions.params }
        );
    } else {
        
        this.router.navigate(
            navigate('', contentTable).urls
        )
    }
    
    // this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();

  }

  goToQuestionPage(notification: any) {
    console.log({ notification });
    console.log("notification state is 1: ",notification.opened);
    this.notificationOpened(notification);
    console.log("notification state is 2: ",notification.opened);
    // this.markRed(notification.id);
    if(notification.contentId !== undefined){
        const params = JSON.stringify([notification.contentId]) //This is used to secure the URL
        this.router.navigate(
            ['/code/questions'],
            { queryParams: { question: notification.contentId } }
        );
    } else {
        this.router.navigate(['/code/questions']);
    }

    // this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
