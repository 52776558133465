<div class="print">
    <table id="table-head" class="table">
        <tr>
            <td class="bold">
                Planning : De {{ data.period.begin | date: "dd/MM" }} à
                {{ data.period.end | date: "dd/MM" }}
            </td>
        </tr>
        <tr *ngIf="data.types.length !== 0">
            <td class="bold">
                Type :
                <ng-container *ngFor="let item of data.types; let last = last">
                    <ng-container *ngIf="!last">
                        {{ getType(item) }} /
                    </ng-container>
                    <ng-container *ngIf="last">
                        {{ getType(item) }}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
        <tr *ngIf="data.mon">
            <td class="bold">Moniteur : {{ data.mon }}</td>
        </tr>
        <tr *ngIf="data.cand">
            <td class="bold">Candidat : {{ data.cand }}</td>
        </tr>
    </table>

    <table id="table-planning" class="table">
        <tr>
            <th>Candidat</th>
            <th>Moniteur</th>
            <th>Type</th>
            <th>Horaire</th>
        </tr>
        <tr>
            <td colspan="4"><div class="divider head-divider"></div></td>
        </tr>
        <ng-container *ngFor="let item of data.events">
            <tr>
                <td class="center">{{ item.canPrenom }} {{ item.canNom }} 
                        <br *ngIf="item.canTel">
                    <span *ngIf="item.canTel">
                        ( {{item.canTel}} )
                    </span></td>
                <td class="center">
                    {{ item.agPrenom }}
                </td>
                <td class="center">{{ getType(item.type) }}</td>
                <td class="right pr-32">
                    <span>
                        {{ item.debut | date: "dd/MM  hh:mm a" }}
                    </span>
                    <br>
                    <span class="right pr-4">
                        {{item.fin | date: "hh:mm a"}}
                    </span>
                    
                </td>
            </tr>
            <tr>
                <td colspan="4"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
    </table>
</div>
