import { CandidatService } from "./../../../candidats/candidat/candidat.service";
import { Subject, take } from "rxjs";
import { takeUntil } from "rxjs";
import { TypesPopupComponent } from "./../types-popup/types-popup.component";
import { depenseFamilles } from "./../../../../../global";
import { DepencesService } from "./../depences.service";
import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormControl,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { GlobalService } from "app/global.service";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "app/main/components/succes-snak-bar/succes-snak-bar.component";
import { ErreurService } from "app/main/authentification/erreur.service";
import { ReloadDialogService } from "@fuse/services/reload-dialog.service";

@Component({
    selector: "app-depence-form",
    templateUrl: "./depence-form.component.html",
    styleUrls: ["./depence-form.component.scss"],
})
export class DepenceFormComponent implements OnInit, OnDestroy {
    editfamille: any;
    depenseForm: UntypedFormGroup;
    vehicles = [];
    candidats = [];
    ressources = [];
    agents = [];
    types = [];
    showVehicle = false;
    showCandidat = false;
    showAgents = false;
    showResources = false;
    action: any;
    NotModified: any;
    role: any;
    today = new Date();
    confirmDialogRef: any;

    _unsubscribeAll: Subject<any>;

    familles = depenseFamilles;
    typesNotSelected = true;

    addingDepense = false;
    constructor(
        private _reloadDialogService: ReloadDialogService,
        private errService: ErreurService,
        private snackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private dialog: MatDialog,
        public service: DepencesService,
        public CandidatService: CandidatService,
        public fb: UntypedFormBuilder,
        private globalService: GlobalService,
        public _matDialogRef: MatDialogRef<DepenceFormComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        _matDialogRef.disableClose = true;
    }
    ngOnInit() {

        //?push fake state to navigate to when button back is clicked
        const modalState = {
            modal : true,
            desc : 'fake state for our modal'
        };
        history.pushState(modalState, null);
        
        moment.locale("fr");
        this._unsubscribeAll = new Subject<any>();
        this.role = this.globalService.getUser().role;
        if (this.role == "moniteur") {
            this.familles = [];
            depenseFamilles.forEach((element) => {
                this.familles.push(element);
            });
            this.familles.splice(2, 1);
        }
        this.action = this._data.action;
        this.agents = this._data.agents;
        if (this._data.action === "new") {
            this.service.getAllVehicules().subscribe((res) => {
                this.vehicles = res;
            });
            this.service.getAllRessources().subscribe((res) => {
                this.ressources = res;
            });
            this.CandidatService.getCandidats().subscribe((res) => {
                this.candidats = res;
            });
            this.initForm();
        } else {
            this.ressources = this._data.ressources;
            this.vehicles = this._data.vehicles;
            this.candidats = this._data.candidates
            this.editfamille = JSON.parse(JSON.stringify(this._data.row));
            if (this._data.row.famille == "Véhicules") {
                this.editfamille.famille = 0;
                this.showVehicle = true;
            } else if (this._data.row.famille == "Candidats") {
                this.editfamille.famille = 1;
                this.showCandidat = true;
            } else if (this._data.row.famille == "Personnelles") {
                this.editfamille.famille = 2;
                this.showAgents = true;
            } else if (this._data.row.famille == "Locaux") {
                this.editfamille.famille = 3;
                this.showResources = true;
            } else if (this._data.row.famille == "Autre") {
                this.editfamille.famille = 4;
            }
            this.initeditForm();
            if (this._data.row.ressource == "Supprimer") {
                if (this._data.row.famille == "Locaux") {
                    this.ressources = [{ id: 1, label: "Ressource supprimé" }];
                    this.depenseForm.controls["ressourceId"].setValue(1);
                    this.NotModified = true;
                    this.depenseForm.disable();
                }
                if (this._data.row.famille == "Véhicules") {
                    this.vehicles = [{ id: 1, nom: "Vehicule supprimé" }];
                    this.depenseForm.controls["vehiculeId"].setValue(1);
                    this.NotModified = true;
                    this.depenseForm.disable();
                }
                if (this._data.row.famille == "Personnelles") {
                    this.agents = [{ id: 1, username: "Agent supprimé" }];
                    this.depenseForm.controls["effectPar"].setValue(1);
                    this.NotModified = true;
                    this.depenseForm.disable();
                }
                if (this._data.row.famille == "Candidats") {
                    this.candidats = [{ id: 1, nom: "Candidat supprimé" }];
                    this.depenseForm.controls["candidatId"].setValue(1);
                    this.NotModified = true;
                    this.depenseForm.disable();
                }
            }

            if (this._data.row.effectParUserName === "Agent supprimé") {
                this.NotModified = true;
                this.depenseForm.disable();
            }
            this.depenseForm.get("type").enable();
            this.service
                .getSpeTypes(this.editfamille.famille)
                .subscribe((res) => {
                    (res as []).forEach((element) => {
                        this.types.push(element);
                    });
                });
        }

        this.service
            .typeDepadded()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((type) => {
                let value = parseInt(type.famille, 10);
                this.depenseForm.controls["famille"].setValue(value);
                this.familleChange(value, type.label);
            });

        this.service
            .typeDepdeleted()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((type) => {
                this.depenseForm.controls["famille"].setValue(null);
                this.depenseForm.controls["type"].setValue(null);
                this.showVehicle = false;
                this.showCandidat = false;
                this.showAgents = false;
                this.showResources = false;
            });
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            history.go(-1);
        }

        this._unsubscribeAll.next(true)
        this._unsubscribeAll.complete();
    }

    initForm() {
        this.depenseForm = this.fb.group({
            montant: [null, [Validators.required, Validators.min(0)]],//, Validators.pattern(/^[0-9]+(\.[0-9]{3})?$/)]],
            famille: [null, [Validators.required]],
            effectPar: [null],
            type: [{ value: null, disabled: true }],
            ressourceId: [null],
            vehiculeId: [null],
            candidatId: [null],
            //time: [null],
            date: [new Date()], //!set disabled
            comment: [null],

            //ressourceId: [null]
            //color: [this.event.color],
            //titre: [null]
            //comment: [null], //! we need to add a note later
        });
    }
    initeditForm() {
        
        /* if(el.nativeElement.value.includes('.')) {
            const arr = el.nativeElement.value.split('.');
            let x = arr[0] + '.' + (arr[1]+'000').slice(0,2);
           console.log('x',x);
           } */

           /* this._data.row.montant.includes('.') 
           ? this._data.row.montant.split('.')[0] + '.' + (this._data.row.montant.split('.')[1]+'000').slice(0,2) 
           : this._data.row.montant */
       // console.log("Famille :",this.familles[this.editfamille.famille])

       // console.log("Famille :",this.familles[this.editfamille.famille])
        this.types = this.familles[this.editfamille.famille].defaultTypes;
        this.depenseForm = this.fb.group({
            montant: [
                this._data.row.montant,
                //!this._data.row.montant.isInteger ? this._data.row.montant.toFixed(3): this._data.row.montant,
                [Validators.required, Validators.min(1)],
            ],
            famille: [this.editfamille.famille],
            effectPar: [this._data.row.idAgent],
            type: [this._data.row.type],
            ressourceId: [this._data.row.ressourceId],
            vehiculeId: [this._data.row.vehiculeId],
            candidatId: [this._data.row.candidatId],
            //time: [null],
            date: [moment(this._data.row.date)], //!set disabled
            id: [this._data.row.id],
            comment: [this._data.row.comment],
            //ressourceId: [null]
            //color: [this.event.color],
            //titre: [null]
            //comment: [null], //! we need to add a note later
        });

        if(this.role != 'proprietaire' && this.role != 'administrateur' && (this._data.action == 'edit')) this.depenseForm.disable()


    }

    logui(val){
        console.log('val',val);
    }

    validateAmount(event){
        console.log('EV + key', event.target.value + event.key);
        console.log('EV', event.target.value);
        console.log('EV', event);
        let t = event.target.value + event.key;
       // this.depenseForm.controls['montant'].setValue(1)
        console.log('this.depenseForm.controls',this.depenseForm.controls['montant'].value + event.key);
        console.log('this.depenseForm.controls',this.depenseForm.controls['montant'].value);

        // parseFloat(x.toString().split('.')[0] + (x.toString().split('.')[1] ? '.' + x.toString().split('.')[1].substr(2) : ''));

       // event.target.value = 666;
        /* event.target.value = t.indexOf('.') >= 0 ? 
                                t.substr(0,t.indexOf('.')) + t.substr(t.indexOf('.'),3)
                                : t; */
    }

    familleChange(value, type?) {
        this.depenseForm.get("type").enable();
        this.service.getSpeTypes(value).subscribe((res) => {
            this.types = [];

            this.familles
                .find((element) => value === element.val)
                .defaultTypes.forEach((element) => {
                    this.types.push({
                        label: element.label,
                    });
                });
            (res as any[]).forEach((element) => {
                this.types.push({
                    label: element.label,
                });
            });
            // this.types = res;

            if (type !== undefined) {
                let v = this.types.find((x) => {
                    return x.label === type;
                });
                if (v) this.depenseForm.controls["type"].setValue(v.label);
            }
        });

        this.depenseForm.controls["type"].setValue(null);
        this.depenseForm.controls["type"].markAsUntouched();

        this.changeForm(value);

        //(this.types = res)});
    }

    changeForm(value) {
        if (value == 1) {
            this.showAgents = false;
            this.showResources = false;
            this.showVehicle = false;
            this.showCandidat = true;
            this.depenseForm.controls["candidatId"].setValidators([
                //Validators.required,
            ]);
            this.depenseForm.controls["candidatId"].markAsUntouched();

            this.depenseForm.controls["ressourceId"].setValidators([]);
            this.depenseForm.controls["vehiculeId"].setValidators([]);
            this.depenseForm.controls["effectPar"].setValidators([]);

            this.depenseForm.controls["effectPar"].setValue(null);
            this.depenseForm.controls["ressourceId"].setValue(null);
            this.depenseForm.controls["vehiculeId"].setValue(null);
            this.depenseForm.controls["candidatId"].setValue(null);

            if (this.candidats.length === 1) {
                this.depenseForm
                    .get("candidatId")
                    .setValue(this.candidats[0].id);
            }

            // if (this.agents.length === 1) {
            //     this.depenseForm.get("effectPar").setValue(this.agents[0].id);
            // }
            // this.depenseForm.controls["ressourceId"].setValue(null);
            // this.depenseForm.controls["vehiculeId"].setValue(null);
        } else if (value == 0) {
            this.showVehicle = true;
            this.showAgents = false;
            this.showResources = false;
            this.showCandidat = false;
            this.depenseForm.controls["vehiculeId"].setValidators([
                Validators.required,
            ]);
            this.depenseForm.controls["vehiculeId"].markAsUntouched();

            this.depenseForm.controls["effectPar"].clearValidators();
            this.depenseForm.controls["candidatId"].clearValidators();
            this.depenseForm.controls["ressourceId"].clearValidators();

            this.depenseForm.controls["effectPar"].setValue(null);
            this.depenseForm.controls["ressourceId"].setValue(null);
            this.depenseForm.controls["vehiculeId"].setValue(null);
            this.depenseForm.controls["candidatId"].setValue(null);
            if (this.vehicles.length === 1) {
                this.depenseForm.controls["vehiculeId"].setValue(
                    this.vehicles[0].id
                );
            }
        } else if (value == 2) {
            this.showAgents = true;
            this.showResources = false;
            this.showVehicle = false;
            this.showCandidat = false;
            this.depenseForm.controls["effectPar"].setValidators([
                Validators.required,
            ]);
            this.depenseForm.controls["effectPar"].markAsUntouched();

            this.depenseForm.controls["candidatId"].clearValidators();
            this.depenseForm.controls["ressourceId"].clearValidators();
            this.depenseForm.controls["vehiculeId"].clearValidators();

            this.depenseForm.controls["effectPar"].setValue(null);
            this.depenseForm.controls["ressourceId"].setValue(null);
            this.depenseForm.controls["vehiculeId"].setValue(null);
            this.depenseForm.controls["candidatId"].setValue(null);
            if (this.agents.length === 1) {
                this.depenseForm.controls["effectPar"].setValue(
                    this.agents[0].id
                );
            }
        } else if (value == 3) {
            this.showVehicle = false;
            this.showAgents = false;
            this.showResources = true;
            this.showCandidat = false;
            this.depenseForm.controls["ressourceId"].setValidators([
                Validators.required,
            ]);
            this.depenseForm.controls["ressourceId"].markAsUntouched();

            this.depenseForm.controls["vehiculeId"].clearValidators();
            this.depenseForm.controls["candidatId"].clearValidators();
            this.depenseForm.controls["effectPar"].clearValidators();

            this.depenseForm.controls["effectPar"].setValue(null);
            this.depenseForm.controls["ressourceId"].setValue(null);
            this.depenseForm.controls["vehiculeId"].setValue(null);
            this.depenseForm.controls["candidatId"].setValue(null);
            if (this.ressources.length === 1) {
                this.depenseForm.controls["ressourceId"].setValue(
                    this.ressources[0].id
                );
            }
        } else {
            this.showVehicle = false;
            this.showAgents = false;
            this.showResources = false;
            this.showCandidat = false;
            this.depenseForm.controls["ressourceId"].clearValidators();
            this.depenseForm.controls["vehiculeId"].clearValidators();
            this.depenseForm.controls["candidatId"].clearValidators();
            this.depenseForm.controls["effectPar"].clearValidators();

            this.depenseForm.controls["ressourceId"].markAsUntouched();
            this.depenseForm.controls["vehiculeId"].markAsUntouched();
            this.depenseForm.controls["candidatId"].markAsUntouched();
            this.depenseForm.controls["effectPar"].markAsUntouched();

            this.depenseForm.controls["effectPar"].setValue(null);
            this.depenseForm.controls["ressourceId"].setValue(null);
            this.depenseForm.controls["vehiculeId"].setValue(null);
            this.depenseForm.controls["candidatId"].setValue(null);
        }
    }
    onFamilleChange() {
        this.depenseForm.get("famille").valueChanges.subscribe((famille) => {
            this.depenseForm.get("effectPar").disable();
        });
    }
    raw() { }

    addType() {
        const dialogRef = this.dialog.open(TypesPopupComponent, {
            panelClass:["common-dialog-style"],
            width: "300px",
            data: {},
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            
         });
    }

    onDelete(id) {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Voulez-vous supprimer?";

        this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(
            (result) => {
                if (result) {
                    this.service.deleteDepense(id).subscribe((res) => {
                        this._reloadDialogService.pushChanges('depense')
                        this.snackBar.openFromComponent(
                            SuccesSnakBarComponent,
                            {
                                duration: 3000,
                            }
                        );
                        this._matDialogRef.close(this.depenseForm.getRawValue());
                        //! add a snack bar
                    });
                }
                this.confirmDialogRef = null;
            },
            (err) => {
                this.errService.popError(
                    err,
                    "Un probléme s'est produit lors de suppression. \n Essayer de nouveau."
                );
            }
        );
    }

}
